const tweens1 = {
    titulo: "Tweens 1 (Anual)",
    aulas: [
      { numero: 1, descricao: "Abertura, boas vindas, apresentações. Apresentação do material e metodologia Cipex. Permitir que os alunos olhem o book e workbook. Explicar como funcionam as avaliações e o uso do material extra. Mostrar vídeos de países do mundo que falam inglês e que tenham inglês como segunda língua.", unidade: "", pagina: "" },
      { numero: 2, descricao: "Páginas 01, 02 - Classroom Language utilizando situações reais, contextualizando o uso das frases e perguntas. Conferir se os alunos ligam as figuras ao significado. Páginas 03, 04, 05 e 06 - Conversation 'Introducing yourself'. Extras: Nomes exóticos em inglês; 10 nomes mais comuns nos USA; Diferença entre nickname e name; What's the meaning of your name? Sugestão: https://www.names.org/.", unidade: "", pagina: "" },
      { numero: 3, descricao: "Página 07 - Iniciar a aula mostrando diferentes greetings ao redor do mundo; Introduzir os números até 90 e jogar bingo até 90. Extra: What's your nickname? Páginas 08, 09 e 10 - Seguir praticando os números; Apresentar o texto; Explicar my name’s x I’m; Mostrar as diferentes formas de dizer os números de telefone e WhatsApp e mídias sociais.", unidade: "", pagina: "" },
      { numero: 4, descricao: "Página 11 - Set the scene and introduce the new dialogue; Conversation 'Introducing a friend'; Apresentar he, she and this is. Dica: usar o grammar box se necessário, caso contrário, evitar o uso para não confundir com excesso de informação. Páginas 12 e 13 - Apresentar vocabulário novo + family members. Suggestion: inclua Family members para famílias que passaram por divorcio/separação.", unidade: "", pagina: "" },
      { numero: 5, descricao: "Página 14 e 15 - Contextualizar o model; Forma interrogativa do to be; Apresentar professions e incluir 'it analyst' e as novas profissões ligadas à tecnologia; Homework para pesquisa em casa. Curiosity: 20 profissões mais estressantes do mundo. Páginas 16, 17 e 18 - Fazer os exercícios e apresentar o world bank. Material extra na plataforma, customizado para os exercícios e transfer. Para o uso do material extra, é necessário treinamento.", unidade: "", pagina: "" },
      { numero: 6, descricao: "Páginas 16, 17, 18, 19 e 20 - Iniciar a aula apresentando países pouco conhecidos e visitados, bem como realizar um transfer para revisão, apresentando as nacionalidades. Curiosidade: porque as bandeiras da Bélgica e Alemanha têm as mesmas cores? Material extra na plataforma: memory game countries and flags. Página 21 - Review interrogative form verb to be; Dialogue 'Introducing yourself'.", unidade: "", pagina: "" },
      { numero: 7, descricao: "Páginas 22, 23, 24 e 25 - Antes dos exercícios, apresentar a diferença entre: his e her. Páginas 26, 27 e 28 - Set the scene explaining the model; Apresentar Reading A: alunos devem responder oralmente e após escrever as respostas. Revisar a escrita dos alunos e dar abertura às respostas.", unidade: "", pagina: "" },
      { numero: 8, descricao: "Apresentar o Reading B com o mesmo procedimento acima. Culture tip song; Iniciar workbook e explicar como fazer os exercícios.", unidade: "", pagina: "" },
      { numero: 9, descricao: "Corrigir o workbook. Aplicar o projeto tweens 01 unit 01. Treinamento obrigatório Aplicar o teste da unit 01. Páginas 31 e 32 - Going to school; Dialogue and explore vocabulary: 'Are you ready for school?'; Set the scene; Sinônimos de 'How about you?'. Material extra na plataforma: parts of the day.", unidade: "", pagina: "" },
      { numero: 10, descricao: "Páginas 33, 34, 35, 36, 37, 38, 39 e 40 - Apresentar World Bank; Do exercises and transfer. Material extra na plataforma: emoticons. Páginas - 41, 42, 43 e 44 - Focar na forma interrogativa: Are you...?", unidade: "", pagina: "" },
      { numero: 11, descricao: "Páginas - 45 e 46. Set the scene: Are you ready for? Suggestion: incluir a realidade dos alunos e desafios próximos a serem enfrentados. Página 47 - Set the scene; Present the dialogue 'Identifying objects'; Focar na diferença entre this x that; Revisar my x his x her. Extra: descarte e reciclagem de celulares. Como proceder? Há esse controle ambiental ou pontos de recolhimento e descarte na sua cidade? Pode ser uma atividade lucrativa?", unidade: "", pagina: "" },
      { numero: 12, descricao: "Páginas 48, 49, 50 e 51 - Do the exercises and transfer; Set the scene; Present the dialogue 'Leandro and his family look at pictures'. Suggestions: the most popular student sports in the world. https://www.suffolkgazette.com/ten-most-popular-student-sports/. Página 52 - Grammar box personal information, review and show the vídeo about personal information. Dica: separe o grammar box em 2 momentos: 1. Personal information. 2. who’s x what’s. https://www.youtube.com/watch?v=cpRA8rjtass", unidade: "", pagina: "" },
      { numero: 13, descricao: "Páginas 53, 54, 55 - Do the exercises and transfer. Set the scene; Present Reading A and B: check vocabulary, understanding and Reading. Students answer orally. Then write. Suggestion: as perguntas podem ser no formato de ditado.", unidade: "", pagina: "" },
      { numero: 14, descricao: "Grammar box; Read the poem; Iniciar o workbook com os alunos. Material extra na plataforma. Aplicar o teste.", unidade: "", pagina: "" },
      { numero: 15, descricao: "Página 59 - Project: Diferente houses around the world. https://mymodernmet.com/different-types-of-houses-around-the-world/KEY Ask them to show pictures of their houses. Página 60 - Set the scene; Introduce dialogue; Explain there is and there are; Ask them to describe their houses or apartments.", unidade: "", pagina: "" },
      { numero: 16, descricao: "Páginas 61, 62 - Parts of the house. Página 63 - Explain the interrogative form - Is there? are there? Material extra na plataforma. Página 64 - Explain the negative form - There isn’t x there aren't; Compare one house in the world and yours; Tell about similarities and differences; There is /there isn’t; there are/there aren't. Extra: South Korea, living in Goshiwon. Material extra na plataforma.", unidade: "", pagina: "" },
      { numero: 17, descricao: "Páginas 65 e 66 - Bingo com revisão de números até 90. Página 68 - Set the scene; Introduce the dialogue; Explain 'How old?'. Extra: Why do Chinese people celebrate two birthdays every year? https://confuciusinstitute.site.hw.ac.uk/2020/12/17/why-do-chinese-people-have-two-birthdays-every-year/", unidade: "", pagina: "" },
      { numero: 18, descricao: "Página 69 - Grammar box; introduce Verb to be; Explain the question form. Material extra plataforma: about adjectives to support grammar box. Game suggestions: páginas 71 e 72. ● The teacher or the students google for example POOR ou RICH. Show the picture to a classmate and ask: Are Rita and Alice poor? _____________ Páginas 73, 74, 75 e 76 - Do the exercises.", unidade: "", pagina: "" },
      { numero: 19, descricao: "Página 77 - Set the scene; Introduce the dialogue; Explain: e-pal. Update the concept of the word. Página 78 - Do the exercises. Sugestão: Reutilizar o material sobre nacionalidades e bandeiras compartilhado em Canva na unidade 01.", unidade: "", pagina: "" },
      { numero: 20, descricao: "Páginas 79, 80 e 81 - Set the scene; Introduce the dialogue; Review this x that; Explain`s possessive case; Review this and that; possessive case. Suggestion: put different objects around the classroom and the school. Present the reading A and reading B.", unidade: "", pagina: "" },
      { numero: 21, descricao: "Página 84 - Culture tip: Independence day Material extra plataforma e no instagram CIPEX BRASIL. Start the workbook and take the test unit 03.", unidade: "", pagina: "" },
      { numero: 22, descricao: "Páginas 86 e 87 - Set the scene: favorite things; Introduce the dialogue; Explain these are x those are. Extra: objetos que a tecnologia extinguiu x gadgets. https://incrivel.club/admiracao-curiosidades/16-objetos-que-eram-comuns-em-2010-mas-em-2020-praticamente-desapareceram-1098360/ Páginas 88, 89 e 90 - Do the exercises; Review grammar box. Suggestion: atividades com mobilidade e distância dos objetos.", unidade: "", pagina: "" },
      { numero: 23, descricao: "Páginas 91, 92 e 93 - Apresentar Family members e trazer os nomes para famílias de pais separados e em outra união; fazer a Family Tree. Extra: Via redes sociais podem mandar stories ou montagens com as famílias para compartilhar nas redes do Cipex. Páginas 94, 95 e 96 - Do the exercises.", unidade: "", pagina: "" },
      { numero: 24, descricao: "Página 97 - About my Family; different ways to say your name. Página 100 - Set the scene; Introduce the dialogue; Explain 'What’s she like?' Game: what 's my bff like? They prepare the activity using their best friends' pictures. Material extra plataforma.", unidade: "", pagina: "" },
      { numero: 25, descricao: "Página 101 - Do the exercises and introduce the dialogue: Playing a game Suggestion: pesquisar origem do tennis. Material extra na plataforma. Páginas 103, 104 e 105.", unidade: "", pagina: "" },
      { numero: 26, descricao: "Páginas 106, 107, 108 e 109 Sugestão: Vídeos com quizzes com do e does. Páginas 110, 111, 112 e 113 - Do the exercises and introduce the Reading. Material extra na plataforma: feelings and emotions", unidade: "", pagina: "" },
      { numero: 27, descricao: "Página 114 - Culture tip; start the workbook. Correct the workbook; test unit 04.", unidade: "", pagina: "" },
      { numero: 28, descricao: "Páginas 115 e 116 - Introduce unit and start the dialogue as usual. Páginas 117 e 118 - Do the exercises. Suggestion: qual a origem da sua comida favorita? pizza, hot dog, etc... Material extra na plataforma: diferente ice creams around the world.", unidade: "", pagina: "" },
      { numero: 29, descricao: "Páginas 119 e 120 - Explain simple present tense conjugation; do the exercises. Páginas 121 e 122 - Do the exercises and introduces the negative form.", unidade: "", pagina: "" },
      { numero: 30, descricao: "Páginas 123, 124 e 125 - Do the exercises. Páginas 126, 127 e 128 - Introduce the dialogue and do the exercises as usual. Material extra na plataforma: origem do volleyball.", unidade: "", pagina: "" },
      { numero: 31, descricao: "Página 129 - Introduce the Reading. Página 130 - Introduce the dialogue and do the exercises.", unidade: "", pagina: "" },
      { numero: 32, descricao: "Páginas 131, 132 e 133 - Do the exercises.", unidade: "", pagina: "" },
      { numero: 33, descricao: "Página 134 - People at the beach research: divide the students in groups and sort out the activities, like: most famous beaches around the world; most famous beaches in Brazil, Dubai. What surprised you?", unidade: "", pagina: "" },
      { numero: 34, descricao: "Página 135 - Introduce the dialogue as usual do the practice.", unidade: "", pagina: "" },
      { numero: 35, descricao: "Página 136 - Keep doing practice.", unidade: "", pagina: "" },
      { numero: 36, descricao: "Página 137 - Introduce Reading.", unidade: "", pagina: "" },
      { numero: 37, descricao: "Culture tip research: quantas línguas existem no mundo? Quantas línguas foram extintas? Como são os alfabetos do japonês, coreano e mandarin? Challenge: Escreva seu nome em uma destas línguas.", unidade: "", pagina: "" },
      { numero: 38, descricao: "Página 139 - Iniciar o workbook.", unidade: "", pagina: "" },
      { numero: 39, descricao: "Finalizar o workbook e correção.", unidade: "", pagina: "" },
      { numero: 40, descricao: "Aplicar o teste da unidade 05.", unidade: "", pagina: "" }
    ]
  };
  
  export { tweens1 };
  