const tweens3 = {
  titulo: "Tweens 3 (Anual)",
  aulas: [
    { numero: 1, descricao: "Abertura, boas vindas e apresentações. Apresentação do material e metodologia Cipex. Permitir que os alunos olhem o book e o workbook. Explicar como funcionam as avaliações e o uso do material extra. Páginas 01 e 02 - Set the scene; Present the dialogue. Extra: Different houses around the world. https://mymodernmet.com/different-types-of-houses-around-the-world/ tokyo: tiniest and luxury apartment https://www.youtube.com/watch?v=jZ7Bu_SDJgM Material extra na plataforma: Goshiwon in South Korea.", unidade: "", pagina: "" },
    { numero: 2, descricao: "Páginas 03, 04, 05 e 06 - Explain 'Going to future'; Do the exercises; Transfer 'Rafael Nadal'. Páginas 07 e 08 - Explain again 'Going to future' and 'Gonna'; Do exercises.", unidade: "", pagina: "" },
    { numero: 3, descricao: "Páginas 08, 09 e 10 - Iniciar com practice; Explicar affirmative and negative form of going to future and short answers. Páginas 10, 11 e 12 - Explain interrogative form going to future; Do the exercises. Sugestão: usar o gramar box.", unidade: "", pagina: "" },
    { numero: 4, descricao: "Página 13 - Present Reading; Do the exercises. Página 14 - Set the scene; Present the dialogue farewell parties; Explain the context.", unidade: "", pagina: "" },
    { numero: 5, descricao: "Páginas 15, 16, 17 e 18 - practice going to the future and 'wh' words. Página 19 - Present the dialogue: A welcoming party; Explain the context; Do the exercises.", unidade: "", pagina: "" },
    { numero: 6, descricao: "Página 20 - Challenge the student to ask and answer about the story above (sample). Páginas 21, 22, 23 e 24 - Do the exercises, using WH words and going to the future.", unidade: "", pagina: "" },
    { numero: 7, descricao: "Páginas 25 e 26 - Explain: yes, no, questions with going to the future; Do the exercises. Páginas 27, 28, 29 e 30 - Introduce adverbs of frequency; Do the exercises. Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 8, descricao: "Páginas 31, 32 e 33 - Enfatizar a diferença de uso dos advérbios de frequência com o verb to be x action verbs. Dica: Há bons quizzes no youtube explorando a diferença. Páginas 34 e 35 - Introduce the Reading; Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 9, descricao: "Página 35 - Continue com o reading enfatizando ask and answer; Culture tip. Having fun: opcional e review dos numbers. Iniciar o workbook; Corrigir antes do teste.", unidade: "", pagina: "" },
    { numero: 10, descricao: "Aplicar o teste. Material extra na plataforma: Do you eat healthy?; Farming in South Africa, Ecotourism, Ostrich Farms, Pink/vertical farms in Brazil. Projeto leva 02 aulas, pode ser usado quando o professor achar adequado. Para o uso do material extra, é obrigatório treinamento. Páginas 38 e 39 - Present the dialogue 'A visit to the doctor'; Review: who, when, what, where and how. Suggestion: quantas faculdades de medicina existem no Brasil? Qual a melhor avaliada? Qual a pior avaliada? Quais as melhores faculdades de medicina do mundo? Médicos brasileiros: quem são os médicos brasileiros reconhecidos no mundo?", unidade: "", pagina: "" },
    { numero: 11, descricao: "Páginas 41, 42 e 43 - Review: in, on, at; Do the exercises. Páginas 44 e 45 - Use o modelo do exercício como set the scene.", unidade: "", pagina: "" },
    { numero: 12, descricao: "Página 46 - Present the dialogue. Atenção: Defasagem sobre rent a movie. Cabe uma breve explicação sobre como eram os hábitos antes de celulares, séries, apps, streaming e TVs por assinatura. Sem isso, o diálogo fica obsoleto e os alunos percebem a defasagem. Use isso a favor da cultura e informação. Pode ser sugerido como atividade de pesquisa. Página 47 - Present the parts of the body; Research games and use YouTube vídeos. Jogue força com frases e expressões ligadas com saúde. Criatividade é bem vinda.", unidade: "", pagina: "" },
    { numero: 13, descricao: "Páginas 47, 48, 49 e 50 - Do the exercises. Página 51 - Do the exercises; Transfer: 01 aluno é o médico e outro é o paciente, providenciar o nome de doenças comuns sugeridas pelos alunos ou usar o vocabulário do livro.", unidade: "", pagina: "" },
    { numero: 14, descricao: "Página 52 - Present the Reading as usual. Página 53 - Do the questions and answers as usual; Challenge the students to correctly use do/does and the verb to be in the questions.", unidade: "", pagina: "" },
    { numero: 15, descricao: "Página 54 - Present the expressions in real situations. Mini dialogues are a good idea. Example: A) It's raining outside. B) Really? Where is my umbrella? Página 55 - Present the dialogue as usual; Do the exercises.", unidade: "", pagina: "" },
    { numero: 16, descricao: "Páginas 56 e 57 - Do the exercises, motivate them to use the expressions. Páginas 58 e 59 - Destaque 'how often'; Review: do and does; Remember the 's' third singular verb.", unidade: "", pagina: "" },
    { numero: 17, descricao: "Páginas 60, 61 e 62 - Grammar box; Explain the difference between adjectives and adverbs; Practice: healthy habits. Página 63 - Present the dialogue as usual; Extension: are Fernando’s parentes right or wrong? Suggestion: desafie os alunos a encontrar algum serviço com profissionais que ajudem em situações problemáticas vividas pelos estudantes e que protejam a identidade dos mesmos no Brasil. Caso não, o que eles sugerem como alunos? Extra: Rules to follow in schools around the world. https://www.japaoemfoco.com/", unidade: "", pagina: "" },
    { numero: 18, descricao: "Página 64 - Do the exercises as usual. Página 65 - Do the Reading as usual; Culture tip. Do workbook.", unidade: "", pagina: "" },
    { numero: 19, descricao: "Corrigir o workbook e aplicar o teste.", unidade: "", pagina: "" },
    { numero: 20, descricao: "Páginas 66, 67, 68 e 69 - Set the scene; Start talking about the famous monument in Rio de Janeiro. Christ on Corcovado. Ask them what they know about it; Introduce the conversation as usual. https://www.iheartbrazil.com/christ-the-redeemer-statue/ Páginas 70 e 71 - Explain simple past of verb to be. Suggestion: show the verb to be in the present and then make the link with the simple past. Grammar box on page 72 can be used and helps a lot.", unidade: "", pagina: "" },
    { numero: 21, descricao: "Páginas 72 e 73 - Use the model to set the scene; Introduce the interrogative form of to be in the past. Be clear about the model of answers they must give. Páginas 74 e 75 - Practice. Do the exercises as usual. Use the model to set the scene. Show how to ask the question and how to answer. First in the negative form. After in the affirmative form. Show them how important it is to know how to ask, answer and say no.", unidade: "", pagina: "" },
    { numero: 22, descricao: "Páginas 76, 77 e 78 - Use the grammar box to start the class; Use the model of exercise to set the scene and do the exercises as usual. Página 79 - Introduce the dialogue about the trip to Fortaleza; Do the dialogue as usual. Talk about Copacabana in Rio de Janeiro and why it’s so famous. https://theculturetrip.com/south-america/brazil/articles/a-history-of-copacabana-beach/", unidade: "", pagina: "" },
    { numero: 23, descricao: "Páginas 80, 81, 82 e 83 - Use the model to explain short answers in the affirmative; Explain negative short answers; Do the exercises as usual. Use the grammar box if it's necessary. Páginas 84 e 85 - Use the grammar box to review questions and short answers; Do the exercises as usual. https://www.youtube.com/watch?v=-SmWRPbXiio&t=36s", unidade: "", pagina: "" },
    { numero: 24, descricao: "Páginas 86, 87 e 88 - Introduce the dialogue as usual; Remind: where and start the exercise. Páginas 89 e 90 - Practice; Review: who; Use the model and explain the exercise and do it as usual.", unidade: "", pagina: "" },
    { numero: 25, descricao: "Páginas 91 e 92 - Review: when; Explain the exercise and do it. Páginas - 93, 94 e 95 - Explain how to use adjectives in both ways. Do a lot of exercises. It's not easy for some of them. Be careful with it’s x they’re.", unidade: "", pagina: "" },
    { numero: 26, descricao: "Páginas 93, 94 e 95 - Grammar box. Material extra na plataforma. Páginas 96, 97 e 98 - Do the interview as usual. Ask them to write when you finish the exercise; Do the Reading as usual. They know a lot about Rio now.", unidade: "", pagina: "" },
    { numero: 27, descricao: "Páginas 99, 100 e 101 - Culture tip; Do it as usual. Start the workbook.", unidade: "", pagina: "" },
    { numero: 28, descricao: "Aplicar o teste.", unidade: "", pagina: "" },
    { numero: 29, descricao: "Página 102 e 103 - Set the scene. Introduce the dialogue as usual. Extra: Teenagers around the world. Objetivo: Mostrar como os adolescentes de outro continente se divertem nos fins de semana. Suggestions: pesquisar atividades típicas de lazer para adolescentes em diferentes culturas. - India Flying kites. https://utsav.gov.in/view-event/kite-festival-2#:~:text=Kite%20Festival%20is%20a%20popular,as%20Uttarayan%20in%20Western%20India. - 20 top destinations for teens during vacation. https://www.goabroad.com/articles/highschool-study-abroad/trips-for-teens Páginas 104 e 105 - Explicar o acréscimo do ED no verbo para entendimento do passado dos verbos regulares em inglês; Destacar a presença do DID nas perguntas como uma ferramenta de identificação de passado; Pedir a tradução para evitar que eles sigam traduzindo as perguntas no presente, mesmo sabendo que a pergunta está no passado; Do the exercises.", unidade: "", pagina: "" },
    { numero: 30, descricao: "Páginas 106, 107 e 108 - Explicar as short answers e reforçar o passado dos verbos irregulares com ED explorando o gramar box. Material extra na plataforma. Páginas 109, 110 e 111 - Do exercises as usual with focus on spelling rules. Não usar o grammar box da página 111, pois já apresenta um verbo irregular.", unidade: "", pagina: "" },
    { numero: 31, descricao: "Páginas 112 e 113 - Set the scene. Usar o modelo para explicar o exercício detalhadamente. Página 114 - Introduce the dialogue. Dica: “go to the club”, explorar a mudança de comportamento das pessoas. Fazer um tipo de reflexão sobre hábitos passados da sociedade que hoje não temos mais. Onde acontecem as festas de casamento, bailes, aniversários e cerimônias oficiais e atividades de lazer?", unidade: "", pagina: "" },
    { numero: 32, descricao: "Página 115 - Introduzir os verbos irregulares. Precisa ficar claro a existências dos 02 tipos de verbos para o aluno. Vale colocar um exemplo com um verbo regular. Após, usa-se o modelo do exercícios para explicar os verbos irregulares. Dica: Simple past games no youtube. https://www.youtube.com/watch?v=AajXKiTeNlw https://www.youtube.com/watch?v=OX5Mi2-GpUE Página 115 Repetir atividades com “irregular verbs simple past”.", unidade: "", pagina: "" },
    { numero: 33, descricao: "Página 116 - Tradução dos 03 parágrafos e pedir comparativos sobre atividades habituais do passado e que não repetimos hoje. Página 117 - Do the Reading as usual. Explore “Pantanal in Brazil” using the sites and pictures available on google.", unidade: "", pagina: "" },
    { numero: 34, descricao: "Página 118 - Ask the students to answer the questions. Challenge them to ask and answer the questions by themselves. Plan B: dictation Página 119 - Do the exercises as usual. Explore the grammar box to review.", unidade: "", pagina: "" },
    { numero: 35, descricao: "Páginas 120 e 121 - Do the exercises. Páginas - 122 e 123 - Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 36, descricao: "Página 124 - Present the dialogue. Show “Bonito” using google pictures and site. Páginas 125 e 126 - Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 37, descricao: "Pre-prepare something for the 4th of july. There's plenty on line and it's good to show the importance of the Holiday. Present the dialogue as usual. Páginas 128, 129 e 130 - Present the box with the irregular verbs. Explain the model clearly.", unidade: "", pagina: "" },
    { numero: 38, descricao: "Páginas 131, 132 e 133 - Do the Reading as usual. Show pictures of “Honey Island Beach”. Explain the culture tip. Start the workbook. Correct the workbook and take the test.", unidade: "", pagina: "" },
    { numero: 39, descricao: "Páginas 135 e 136 - Introduce the topics to be taught on p. 135 and the dialogue on p. 136. Set the scene. Introduce the dialogue as usual. Which is the hardest school system in the world? Páginas 137, 138, 139 e 140 - Review the simple past of verb to be. Provide examples. Now, explaining “past continuous or progressive”. Use the grammar box on p. 139 to help you.", unidade: "", pagina: "" },
    { numero: 40, descricao: "Páginas 141 e 142 - Material extra na plataforma. Páginas 143 e 144 - Set the scene: where is Caiobá? Do the Reading and exercises as usual. https://www.guiadoturismobrasil.com/cidade/PR/802/caioba Páginas 145 e 146 - Use the model to explain the exercises. Do them as usual. Páginas 147, 148 e 149 - Use the grammar box to explain interrogative form of past progressive. Set the scene using the model of the exercise to explain what the students should do. Páginas 150, 151 e 152 - Use the grammar box to explain the negative form of past progressive if it's necessary. Practice on p.150. Use the model to explain the short answers. P.152 grammar box. Redo it to practice.", unidade: "", pagina: "" },
    { numero: 41, descricao: "Páginas 153 e 154 - Keep practicing short answers in the past progressive tense. Páginas 155 e 156 - Grammar box. Explain the spelling rules. Do the exercises as usual. Página 157 - Introduce the dialogue. Set the scene with this question: Are you a procrastinator? https://buzzfeed.com.br/post/21-sinais-de-que-voce-e-definitivamente-um-procrastinador", unidade: "", pagina: "" },
    { numero: 42, descricao: "Páginas 158 e 159 - Use the model to set the scene. Introduce the examples with “when”. Do the exercises as usual. Páginas 160 e 161 - Introduce the Reading. Make them to translate and read. Do the exercise as usual. Use www.linguee.com.br to help them.", unidade: "", pagina: "" },
    { numero: 43, descricao: "Página 162 - Start workbook. Material extra na plataforma. Test.", unidade: "", pagina: "" }
  ]
};

export { tweens3 };
