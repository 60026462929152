const book1Semestral = {
  titulo: "Book 1 (Semestral)",
  aulas: [
    { numero: 1, descricao: "Abertura, boas vindas, apresentações. Apresentação do material e metodologia Cipex.", unidade: "Unid 1", pagina: 7 },
    { numero: 2, descricao: "Unid 1 – pág 15 (fazer transfer usando o Word Bank)", unidade: "Unid 1", pagina: 15 },
    { numero: 3, descricao: "Unid 1 – pág 22 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 1", pagina: 22 },
    { numero: 4, descricao: "Wkbk 1 – teste 1 (corrigir o teste). Unid 2 pág 30", unidade: "Wkbk 1", pagina: 0 },
    { numero: 5, descricao: "Unid 2 – pág 39 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 2", pagina: 39 },
    { numero: 6, descricao: "Wkbk 2 – teste 2 (corrigir o teste). Unid 3 pág 47", unidade: "Wkbk 2", pagina: 0 },
    { numero: 7, descricao: "Unid 3 – pág 58 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 3", pagina: 58 },
    { numero: 8, descricao: "Wkbk 3 – teste 3 (corrigir o teste). Unid 4 pág 66", unidade: "Wkbk 3", pagina: 0 },
    { numero: 9, descricao: "Unid 4 – pág 76 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 4", pagina: 76 },
    { numero: 10, descricao: "Wkbk 4 – teste 4 (corrigir o teste). Unid 5 pág 81", unidade: "Wkbk 4", pagina: 0 },
    { numero: 11, descricao: "Unid 5 – pág 90", unidade: "Unid 5", pagina: 90 },
    { numero: 12, descricao: "Unid 5 – pág 96 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 5", pagina: 96 },
    { numero: 13, descricao: "Wkbk 5 – teste 5 (corrigir o teste). Unid 6 pág 103", unidade: "Wkbk 5", pagina: 0 },
    { numero: 14, descricao: "Unid 6 – pág 112 (sugestão de atividade para a próxima aula: fazer a árvore da família)", unidade: "Unid 6", pagina: 112 },
    { numero: 15, descricao: "Unid 6 – pág 116 (fazer revisão de estruturas e vocabulário última pág) - atividade árvore", unidade: "Unid 6", pagina: 116 },
    { numero: 16, descricao: "Wkbk 6 – teste 6 (corrigir o teste). Unid 7 pág 125", unidade: "Wkbk 6", pagina: 0 },
    { numero: 17, descricao: "Unid 7 – pág 134 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 7", pagina: 134 },
    { numero: 18, descricao: "Wkbk 7 – teste 7 (corrigir o teste). Unid 8 pág 141", unidade: "Wkbk 7", pagina: 0 },
    { numero: 19, descricao: "Unid 8 – pág 151 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 8", pagina: 151 },
    { numero: 20, descricao: "Wkbk 8 – teste 8 (corrigir o teste). Unid 9 pág 160", unidade: "Wkbk 8", pagina: 0 },
    { numero: 21, descricao: "Unid 9 – pág 170 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 9", pagina: 170 },
    { numero: 22, descricao: "Wkbk 9 – teste 9 (corrigir o teste). Unid 10 pág 178", unidade: "Wkbk 9", pagina: 0 },
    { numero: 23, descricao: "Unid 10 – pág 187", unidade: "Unid 10", pagina: 187 },
    { numero: 24, descricao: "Unid 10 – pág 192 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 10", pagina: 192 },
    { numero: 25, descricao: "Wkbk 10 – teste 10 (corrigir o teste). Considerações finais", unidade: "Wkbk 10", pagina: 0 }
  ]
};

export { book1Semestral };
