const book4 = {
  titulo: "Book 4 (Anual)",
  aulas: [
    { numero: 1, descricao: "Abertura, boas vindas, apresentações. (Apresentação do material e metodologia CIPEX, se necessário. Ex.: alunos vindos de outras escolas, começando no CIPEX direto no livro 4)", unidade: "Unid 1", pagina: 7 },
    { numero: 2, descricao: "Unid 1 – pág 13", unidade: "Unid 1", pagina: 13 },
    { numero: 3, descricao: "Unid 1 – pág 18 (guiar os alunos no que deve ser feito no workbook p/ próxima aula)", unidade: "Unid 1", pagina: 18 },
    { numero: 4, descricao: "Wkbk 1 – teste 1 – corrigir o teste com os alunos, comentários", unidade: "Wkbk 1", pagina: 0 },
    { numero: 5, descricao: "Unid 2 – pág 24", unidade: "Unid 2", pagina: 24 },
    { numero: 6, descricao: "Unid 2 – pág 32", unidade: "Unid 2", pagina: 32 },
    { numero: 7, descricao: "Unid 2 – pág 36 (guiar o wkbk p/ próxima aula)", unidade: "Unid 2", pagina: 36 },
    { numero: 8, descricao: "Wkbk 2 – teste 2 - corrigir o teste com os alunos, comentários", unidade: "Wkbk 2", pagina: 0 },
    { numero: 9, descricao: "Unid 3 – pág 41", unidade: "Unid 3", pagina: 41 },
    { numero: 10, descricao: "Unid 3 – pág 47", unidade: "Unid 3", pagina: 47 },
    { numero: 11, descricao: "Unid 3 – pág 51 (guiar o wkbk p/ próxima aula)", unidade: "Unid 3", pagina: 51 },
    { numero: 12, descricao: "Wkbk 3 – teste 3 - corrigir o teste com os alunos, comentários", unidade: "Wkbk 3", pagina: 0 },
    { numero: 13, descricao: "Unid 4 – pág 55", unidade: "Unid 4", pagina: 55 },
    { numero: 14, descricao: "Unid 4 – pág 61 (antes do exercício de condicionais)", unidade: "Unid 4", pagina: 61 },
    { numero: 15, descricao: "Unid 4 – pág 67 (guiar o wkbk p/ próxima aula)", unidade: "Unid 4", pagina: 67 },
    { numero: 16, descricao: "Wkbk 4 – teste 4 - corrigir o teste com os alunos, comentários", unidade: "Wkbk 4", pagina: 0 },
    { numero: 17, descricao: "Unid 5 – pág 71", unidade: "Unid 5", pagina: 71 },
    { numero: 18, descricao: "Unid 5 – pág 77", unidade: "Unid 5", pagina: 77 },
    { numero: 19, descricao: "Unid 5 – pág 83 (guiar o wkbk p/ próxima aula)", unidade: "Unid 5", pagina: 83 },
    { numero: 20, descricao: "Wkbk 5 – teste 5 - corrigir o teste com os alunos, comentários", unidade: "Wkbk 5", pagina: 0 },
    { numero: 21, descricao: "Unid 6 – pág 90", unidade: "Unid 6", pagina: 90 },
    { numero: 22, descricao: "Unid 6 – pág 98 (antes do exercício que contém perguntas)", unidade: "Unid 6", pagina: 98 },
    { numero: 23, descricao: "Unid 6 – pág 103 (guiar o wkbk p/ próxima aula)", unidade: "Unid 6", pagina: 103 },
    { numero: 24, descricao: "Wkbk 6 – teste 6 - corrigir o teste com os alunos, comentários", unidade: "Wkbk 6", pagina: 0 },
    { numero: 25, descricao: "Unid 7 – pág 109", unidade: "Unid 7", pagina: 109 },
    { numero: 26, descricao: "Unid 7 – pág 115", unidade: "Unid 7", pagina: 115 },
    { numero: 27, descricao: "Unid 7 – pág 119 (guiar o wkbk p/ próxima aula)", unidade: "Unid 7", pagina: 119 },
    { numero: 28, descricao: "Wkbk 7 – teste 7 - corrigir o teste com os alunos, comentários", unidade: "Wkbk 7", pagina: 0 },
    { numero: 29, descricao: "Unid 8 – pág 126", unidade: "Unid 8", pagina: 126 },
    { numero: 30, descricao: "Unid 8 – pág 134", unidade: "Unid 8", pagina: 134 },
    { numero: 31, descricao: "Unid 8 – pág 141 (guiar o wkbk p/ próxima aula)", unidade: "Unid 8", pagina: 141 },
    { numero: 32, descricao: "Wkbk 8 – teste 8 - corrigir o teste com os alunos, comentários", unidade: "Wkbk 8", pagina: 0 },
    { numero: 33, descricao: "Unid 9 – pág 146", unidade: "Unid 9", pagina: 146 },
    { numero: 34, descricao: "Unid 9 – pág 153", unidade: "Unid 9", pagina: 153 },
    { numero: 35, descricao: "Unid 9 – pág 157 (guiar o wkbk p/ próxima aula)", unidade: "Unid 9", pagina: 157 },
    { numero: 36, descricao: "Wkbk 9 – teste 9 - corrigir o teste com os alunos, comentários", unidade: "Wkbk 9", pagina: 0 },
    { numero: 37, descricao: "Unid 10 – pág 163 (antes da conversation)", unidade: "Unid 10", pagina: 163 },
    { numero: 38, descricao: "Unid 10 – pág 166", unidade: "Unid 10", pagina: 166 },
    { numero: 39, descricao: "Unid 10 – pág 170 (guiar o wkbk p/ próxima aula)", unidade: "Unid 10", pagina: 170 },
    { numero: 40, descricao: "Wkbk 10 – teste 10. Corrigir o teste com os alunos. Considerações finais", unidade: "Wkbk 10", pagina: 0 }
  ]
};

export { book4 };
