import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config';
import './CadastroModal.css'; // Importe o arquivo CSS
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Row, Col, Button } from "react-bootstrap";

const CadastroEscolaModal = ({ closeModal, isEdit, escolaDataToEdit }) => {
    const [escolaData, setEscolaData] = useState({
        cp_ec_nome: '',
        cp_ec_responsavel: '',
        cp_ec_endereco_rua: '',
        cp_ec_endereco_numero: '',
        cp_ec_endereco_cidade: '',
        cp_ec_endereco_bairro: '',
        cp_ec_endereco_estado: '',
        cp_ec_data_cadastro: new Date().toLocaleDateString('pt-BR') // Data atual no formato local
    });

    useEffect(() => {
        if (isEdit && escolaDataToEdit) {
            setEscolaData({
                ...escolaDataToEdit,
                cp_ec_data_cadastro: new Date(escolaDataToEdit.cp_ec_data_cadastro).toISOString().slice(0, 10)
            });
        }
    }, [isEdit, escolaDataToEdit]);


    const [usuariosResponsaveis, setUsuariosResponsaveis] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        fetchResponsaveis();
    }, []);

    const fetchResponsaveis = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/users-escolas?cp_tipo_user=2`);
            if (response.data && response.data.length > 0) {
                setUsuariosResponsaveis(response.data);
            }
        } catch (error) {
            console.error('Erro ao buscar os responsáveis:', error);
            toast.error("Erro ao buscar os responsáveis")
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEscolaData(prevEscolaData => ({
            ...prevEscolaData,
            [name]: value
        }));
    };

    const estados = [
        'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS',
        'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC',
        'SP', 'SE', 'TO'
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (isEdit) {
                await axios.put(`${API_BASE_URL}/edit-escola/${escolaData.cp_ec_id}`, escolaData);
            } else {
                await axios.post(`${API_BASE_URL}/register-escola`, escolaData);
            }

            toast.success('Escolada cadastrada com sucesso');
            closeModal();
        } catch (error) {
            toast.error("Erro ao realizar a solicitação")
        }
    };

    return (
        <div className="modal-edit">
            <ToastContainer />
            <h2>Cadastro de Escolas</h2>
            <form onSubmit={handleSubmit} className="form-container-cad">
                <Row>
                    <Col md={4}>
                        <label htmlFor="cp_ec_nome">Nome:</label>
                        <input
                            type="text"
                            id="cp_ec_nome"
                            name="cp_ec_nome"
                            value={escolaData.cp_ec_nome}
                            onChange={handleChange}
                            className="input-field"
                            placeholder="Nome"
                            required
                        />
                    </Col>
                    <Col md={4}>
                        <label htmlFor="cp_ec_data_cadastro">Data de Cadastro:</label>
                        <input
                            type="date"
                            id="cp_ec_data_cadastro"
                            name="cp_ec_data_cadastro"
                            value={escolaData.cp_ec_data_cadastro}
                            onChange={handleChange}
                            className="input-field"
                            required
                        />
                    </Col>

                    <Col md={4}>
                        <label htmlFor="cp_ec_responsavel">Responsável:</label>
                        <select
                            id="cp_ec_responsavel"
                            name="cp_ec_responsavel"
                            value={escolaData.cp_ec_responsavel}
                            onChange={handleChange}
                            className="input-field"
                            required
                        >
                            <option value="">Selecione o responsável</option>
                            {usuariosResponsaveis.map((usuario) => (
                                <option key={usuario.id} value={usuario.cp_nome}>
                                    {usuario.cp_nome}
                                </option>
                            ))}
                        </select>
                    </Col>

                    <Col md={4}>
                        <label htmlFor="cp_ec_endereco_cidade">Cidade:</label>
                        <input
                            type="text"
                            id="cp_ec_endereco_cidade"
                            name="cp_ec_endereco_cidade"
                            value={escolaData.cp_ec_endereco_cidade}
                            onChange={handleChange}
                            className="input-field"
                            placeholder="Cidade"
                            required
                        />
                    </Col>

                    <Col md={4}>
                        <label htmlFor="cp_ec_endereco_bairro">Bairro:</label>
                        <input
                            type="text"
                            id="cp_ec_endereco_bairro"
                            name="cp_ec_endereco_bairro"
                            value={escolaData.cp_ec_endereco_bairro}
                            onChange={handleChange}
                            className="input-field"
                            placeholder="Bairro"
                            required
                        />
                    </Col>

                    <Col md={4}>
                        <label htmlFor="cp_ec_endereco_estado">Estado:</label>
                        <select
                            id="cp_ec_endereco_estado"
                            name="cp_ec_endereco_estado"
                            value={escolaData.cp_ec_endereco_estado}
                            onChange={handleChange}
                            className="input-field"
                            required
                        >
                            <option value="">Selecione o estado</option>
                            {estados.map((estado, index) => (
                                <option key={index} value={estado}>
                                    {estado}
                                </option>
                            ))}
                        </select>
                    </Col>
                    <Col md={4}>
                        <label htmlFor="cp_ec_endereco_rua">Rua:</label>
                        <input
                            type="text"
                            id="cp_ec_endereco_rua"
                            name="cp_ec_endereco_rua"
                            value={escolaData.cp_ec_endereco_rua}
                            onChange={handleChange}
                            className="input-field"
                            placeholder="Rua"
                            required
                        />
                    </Col>

                    <Col md={4}>
                        <label htmlFor="cp_ec_endereco_numero">Número:</label>
                        <input
                            type="text"
                            id="cp_ec_endereco_numero"
                            name="cp_ec_endereco_numero"
                            value={escolaData.cp_ec_endereco_numero}
                            onChange={handleChange}
                            className="input-field"
                            placeholder="Número"
                            required
                        />
                    </Col>



                    <Col md={12}>
                        <button className="submit-button" type="submit">{isEdit ? 'Salvar Alterações' : 'Cadastrar Escola'}</button>
                    </Col>
                </Row>
            </form>

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
        </div>
    );
};

export default CadastroEscolaModal;