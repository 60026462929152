import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  Modal,
  Alert,
  Card,
  Accordion,
  Badge,
} from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { API_BASE_URL } from "./config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import SaladeAulaAluno from "./SaladeAulaAluno.js";
import { FaEdit, FaTrashAlt, FaCheck, FaLink, FaYoutube, FaFilePdf } from 'react-icons/fa';

// Importando os planos de aula
import { book1 } from "./planosDeAulas/book1";
import { book2 } from "./planosDeAulas/book2";
import { book3 } from "./planosDeAulas/book3";
import { book4 } from "./planosDeAulas/book4";
import { book5 } from "./planosDeAulas/book5";
import { book1Semestral } from "./planosDeAulas/book1Semestral";
import { book2Semestral } from "./planosDeAulas/book2Semestral";
import { book3Semestral } from "./planosDeAulas/book3Semestral";
import { book4Semestral } from "./planosDeAulas/book4Semestral";
import { book5Semestral } from "./planosDeAulas/book5Semestral";

// Importando os planos de aula Tweens
import { tweens1 } from "./planosDeAulas/tweens1";
import { tweens2 } from "./planosDeAulas/tweens2";
import { tweens3 } from "./planosDeAulas/tweens3";
import { tweens4 } from "./planosDeAulas/tweens4";

const SalaDeAula = () => {
  const [turmas, setTurmas] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [selectedTurma, setSelectedTurma] = useState(null);
  const [selectedCursoId, setSelectedCursoId] = useState(null);
  const [selectedAulaTipo, setSelectedAulaTipo] = useState("");
  const [aulasDisponiveis, setAulasDisponiveis] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditResumoModal, setShowEditResumoModal] = useState(false);
  const [selectedAluno, setSelectedAluno] = useState(null);
  const [selectedAula, setSelectedAula] = useState("");
  const [materiais, setMateriais] = useState([]);
  const [resumoEditando, setResumoEditando] = useState(null);
  const [editSelectedAula, setEditSelectedAula] = useState("");
  const [editSelectedAulaTipo, setEditSelectedAulaTipo] = useState("");
  const [editSelectedCursoId, setEditSelectedCursoId] = useState(null);
  const [editAulasDisponiveis, setEditAulasDisponiveis] = useState([]);
  const [link, setLink] = useState("");
  const [linkYoutube, setLinkYoutube] = useState("");
  const [formValues, setFormValues] = useState({
    data: "",
    hora: "",
    status: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [historicoChamadas, setHistoricoChamadas] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedChamada, setSelectedChamada] = useState(null);
  const [editFormValues, setEditFormValues] = useState({
    data: "",
    hora: "",
    status: "",
  });
  const [resumo, setResumo] = useState("");
  const [resumos, setResumos] = useState([]);
  const [material, setMaterial] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [userType, setUserType] = useState(null);
  const [showPDF, setShowPDF] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  // const [userName, setUserName] =

  const handleShowPDF = (url) => {
    setPdfUrl(url);
    setShowPDF(true);
  };

  const handleClose = () => {
    setShowPDF(false);
    setPdfUrl("");
  };

  useEffect(() => {
    const userTypeFromStorage = localStorage.getItem("userType");
    setUserType(userTypeFromStorage);
  }, []);

  useEffect(() => {
    const schoolId = localStorage.getItem("schoolId");
    const userName = localStorage.getItem("userName");

    axios
      .get(`${API_BASE_URL}/turmasComAlunos`)
      .then((response) => {
        const filteredTurmas = response.data.filter(
          (turma) =>
            turma.cp_tr_id_escola === parseInt(schoolId) &&
            turma.nomeDoProfessor === userName
        );
        setTurmas(filteredTurmas);
      })
      .catch((error) => console.error("Erro ao buscar turmas:", error));
  }, []);

  const handleResumoChange = (event) => {
    setResumo(event.target.value);
  };

  const handleMaterialChange = (event) => {
    setMaterial(event.target.files[0]);
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleLinkYoutubeChange = (event) => {
    setLinkYoutube(event.target.value);
  };

  const handleAccordionToggle = (data) => {
    setSelectedDate(data);

    const formattedDate = formatarDataParaAccondeon(data);

    axios
      .get(`${API_BASE_URL}/resumos/${formattedDate}/${selectedTurma}`)
      .then((response) => {
        setResumos(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar resumos:", error);
        toast.error("Erro ao buscar resumos.");
      });
  };

  const handleAulaChange = (event) => {
    setSelectedAula(event.target.value);
  };

  const handleSelectTurma = (event) => {
    const turmaId = event.target.value;
    setSelectedTurma(turmaId);

    axios
      .get(`${API_BASE_URL}/turmas/${turmaId}/alunos`)
      .then((response) => {
        setAlunos(response.data);
        setErrorMessage("");
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setAlunos([]);
          toast.error("Nenhum aluno encontrado para esta turma.");
        } else {
          console.error("Erro ao buscar alunos:", error);
          toast.error("Erro ao buscar alunos. Tente novamente mais tarde.");
        }
      });

    axios
      .get(`${API_BASE_URL}/chamadas/turma/${turmaId}`)
      .then((response) => setHistoricoChamadas(response.data))
      .catch((error) =>
        console.error("Erro ao buscar histórico de chamadas:", error)
      );

    const turmaSelecionada = turmas.find(
      (turma) => turma.cp_tr_id === parseInt(turmaId)
    );
    if (turmaSelecionada) {
      setSelectedCursoId(turmaSelecionada.cp_tr_curso_id);
    }
  };

  const handleEditCursoChange = (event) => {
    const tipoCurso = event.target.value;
    setEditSelectedAulaTipo(tipoCurso);

    let aulas = [];

    switch (editSelectedCursoId) {
      case 85:
        aulas = tipoCurso === "anual" ? book1.aulas : book1Semestral.aulas;
        break;
      case 87:
        aulas = tipoCurso === "anual" ? book2.aulas : book2Semestral.aulas;
        break;
      case 88:
        aulas = tipoCurso === "anual" ? book3.aulas : book3Semestral.aulas;
        break;
      case 89:
        aulas = tipoCurso === "anual" ? book4.aulas : book4Semestral.aulas;
        break;
      case 90:
        aulas = tipoCurso === "anual" ? book5.aulas : book5Semestral.aulas;
        break;
      case 115:
        aulas = tweens1.aulas;
        break;
      case 116:
        aulas = tweens2.aulas;
        break;
      case 117:
        aulas = tweens3.aulas;
        break;
      case 118:
        aulas = tweens4.aulas;
        break;
      default:
        aulas = [];
    }

    setEditAulasDisponiveis(aulas);
  };

  const options = [
    { label: "Anual", value: "anual" },
    { label: "Semestral", value: "semestral" },
  ];


  const handleOpenModal = (aluno) => {
    const now = new Date();
    const currentDate = now.toISOString().slice(0, 10);
    const currentTime = now.toTimeString().slice(0, 5);

    setSelectedAluno(aluno);
    setFormValues({
      data: currentDate,
      hora: currentTime,
      status: aluno.status || "",
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAluno(null);
    setFormValues({ data: "", hora: "", status: "" });
  };

  const handleSave = () => {
    if (formValues.status === "") {
      toast.error("O status não pode estar vazio.");
      return;
    }

    const lastChamada =
      historicoChamadas.length > 0
        ? historicoChamadas[historicoChamadas.length - 1]
        : null;
    if (lastChamada) {
      const lastChamadaTime = new Date(
        `${lastChamada.cp_ch_data}T${lastChamada.cp_ch_hora}`
      );
      const currentChamadaTime = new Date(
        `${formValues.data}T${formValues.hora}`
      );
      const timeDiff =
        Math.abs(currentChamadaTime - lastChamadaTime) / (1000 * 60 * 60);

      if (timeDiff < 3) {
        toast.error(
          "O intervalo entre chamadas deve ser de no mínimo 3 horas."
        );
        return;
      }
    }

    const updatedAlunos = alunos.map((aluno) =>
      aluno.cp_id === selectedAluno.cp_id ? { ...aluno, ...formValues } : aluno
    );
    setAlunos(updatedAlunos);

    axios
      .post(`${API_BASE_URL}/chamadas`, {
        turmaId: selectedTurma,
        alunoId: selectedAluno.cp_id,
        data: formValues.data,
        hora: formValues.hora,
        status: formValues.status,
      })
      .then((response) => {
        toast.success("Chamada salva com sucesso.");

        setTimeout(() => {
          axios
            .get(`${API_BASE_URL}/chamadas/turma/${selectedTurma}`)
            .then((response) => {
              setHistoricoChamadas(response.data);
            })
            .catch((error) =>
              console.error("Erro ao buscar histórico de chamadas:", error)
            );
        }, 1500); // Espera 1,5 segundos antes de buscar o histórico de chamadas
      })
      .catch((error) => console.error("Erro ao salvar chamada:", error));

    handleCloseModal();
  };

  const handleSaveEdicao = () => {
    const formattedData = {
      ...editFormValues,
      aula: editSelectedAula, // Salva o número da aula
    };

    axios.put(`${API_BASE_URL}/resumos/${selectedChamada.cp_ch_id}`, formattedData)
      .then(() => {
        toast.success("Resumo editado com sucesso.");
        setShowEditModal(false);
        // Atualiza a lista de resumos
        // ...
      })
      .catch((error) => console.error("Erro ao editar resumo:", error));
  };
  ;


  const handleEditChamada = (chamadaId) => {
    console.log("ID da chamada selecionada:", chamadaId);
    const chamadaSelecionada = historicoChamadas.find(
      (chamada) => chamada.cp_ch_id === chamadaId
    );
    if (chamadaSelecionada) {
      const { cp_ch_data, cp_ch_hora, cp_ch_status } = chamadaSelecionada;
      setEditFormValues({
        data: cp_ch_data,
        hora: cp_ch_hora,
        status: cp_ch_status,
      });
      setSelectedChamada(chamadaSelecionada);
      setShowEditModal(true);
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
  };
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedChamada(null);
    setEditFormValues({ data: "", hora: "", status: "" });
  };

  const handleDeleteChamada = (chamadaId) => {
    console.log("Deleting chamada with ID:", chamadaId);

    axios
      .delete(`${API_BASE_URL}/chamadas/${chamadaId}`)
      .then((response) => {
        axios
          .get(`${API_BASE_URL}/chamadas/turma/${selectedTurma}`)
          .then((response) => {
            setHistoricoChamadas(response.data);
            toast.success("Chamada deletada com sucesso.");
          })
          .catch((error) =>
            console.error("Erro ao buscar histórico de chamadas:", error)
          );
      })
      .catch((error) => console.error("Erro ao deletar chamada:", error));

    setShowEditModal(false); // Fechar o modal após a deleção, se estiver aberto
  };

  const formatarData = (dataISO) => {
    const data = new Date(dataISO);
    const dia = data.getUTCDate().toString().padStart(2, "0");
    const mes = (data.getUTCMonth() + 1).toString().padStart(2, "0");
    const ano = data.getUTCFullYear();
    return `Controle de Frequência | ${dia}/${mes}/${ano}`;
  };

  const formatarDataParaTabela = (dataISO) => {
    if (!dataISO) {
      return "";
    }

    const dataFormatada = formatarData(dataISO);
    return dataFormatada.replace("Chamado do dia ", "");
  };

  const formatarDataParaAccondeon = (dataISO) => {
    const data = new Date(dataISO);
    const dia = data.getUTCDate().toString().padStart(2, "0");
    const mes = (data.getUTCMonth() + 1).toString().padStart(2, "0");
    const ano = data.getUTCFullYear();
    return `${ano}-${mes}-${dia}`;
  };

  const getColorByStatus = (status) => {
    switch (status) {
      case "Presente":
        return "green";
      case "Ausente":
        return "red";
      case "Justificado":
        return "orange";
      default:
        return "black";
    }
  };

  const getBadgeColorByStatus = (status) => {
    switch (status) {
      case "Presente":
        return "success"; // Cor verde
      case "Ausente":
        return "danger"; // Cor vermelha
      case "Justificado":
        return "warning"; // Cor laranja
      default:
        return "secondary"; // Cor padrão
    }
  };

  const handleSaveResumoEMaterial = () => {
    if (!selectedTurma) {
      toast.error("Selecione uma turma antes de salvar o resumo.");
      return;
    }

    if (!selectedDate) {
      toast.error("Selecione uma data válida no acordeon.");
      return;
    }

    const formData = new FormData();
    formData.append("turmaId", selectedTurma);
    formData.append("resumo", resumo);
    formData.append("aula", selectedAula);
    formData.append("data", selectedDate);
    formData.append("link", link);
    formData.append("linkYoutube", linkYoutube);
    const now = new Date();
    const currentTime = now.toTimeString().slice(0, 5);
    formData.append("hora", currentTime);

    if (material) {
      formData.append("arquivo", material);
    }

    axios
      .post(`${API_BASE_URL}/resumos`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        toast.success("Resumo e material salvos com sucesso.");
        setResumo("");
        setMaterial(null);
        setLink("");
        setLinkYoutube("");

        // Atualiza a lista de materiais e resumos após o salvamento
        const formattedDate = formatarDataParaAccondeon(selectedDate);

        // Atualiza resumos
        axios
          .get(`${API_BASE_URL}/resumos/${formattedDate}/${selectedTurma}`)
          .then((response) => setResumos(response.data))
          .catch((error) => console.error("Erro ao buscar resumos:", error));
      })
      .catch((error) => {
        console.error("Erro ao salvar resumo, material e links:", error);
        toast.error(
          "Erro ao salvar resumo, material e links. Tente novamente mais tarde."
        );
      });
  };

  const handleDeleteResumo = (id) => {
    axios.delete(`${API_BASE_URL}/resumos/${id}`)
      .then(() => {
        toast.success('Resumo excluído com sucesso');
        setResumos(resumos.filter(resumo => resumo.cp_res_id !== id)); // Atualiza a lista
      })
      .catch((error) => {
        console.error("Erro ao excluir resumo:", error);
        toast.error('Erro ao excluir resumo');
      });
  };

  const handleEditResumo = (resumo) => {
    setResumoEditando(resumo);
    setEditSelectedCursoId(selectedCursoId);
    setShowEditResumoModal(true);
  };

  // const handleSaveEdicaoResumo = () => {
  //   const formData = new FormData();
  //   formData.append("resumo", resumoEditando.cp_res_resumo);
  //   formData.append("aula", editSelectedAula || resumoEditando.cp_res_aula);
  //   formData.append("link", resumoEditando.cp_res_link);
  //   formData.append("linkYoutube", resumoEditando.cp_res_link_youtube);

  //   axios.put(`${API_BASE_URL}/resumos/${resumoEditando.cp_res_id}`, formData)
  //     .then(() => {
  //       toast.success('Resumo editado com sucesso');

  //       const formattedDate = formatarDataParaAccondeon(selectedDate);
  //       axios
  //         .get(`${API_BASE_URL}/resumos/${formattedDate}/${selectedTurma}`)
  //         .then((response) => setResumos(response.data))
  //         .catch((error) => console.error("Erro ao buscar resumos:", error));

  //       setShowEditResumoModal(false);
  //       setResumoEditando(null);
  //     })
  //     .catch((error) => {
  //       console.error("Erro ao editar resumo:", error);
  //       toast.error('Erro ao editar resumo');
  //     });
  // };

  const handleSaveEdicaoResumo = () => {
    const formData = new FormData();
    formData.append("resumo", resumoEditando.cp_res_resumo);
    formData.append("aula", editSelectedAula || resumoEditando.cp_res_aula);
    formData.append("link", resumoEditando.cp_res_link);
    formData.append("linkYoutube", resumoEditando.cp_res_link_youtube);

    // Se houver um novo arquivo, adicionar ao formData
    if (resumoEditando.cp_res_arquivo) {
      formData.append("arquivo", resumoEditando.cp_res_arquivo);
    }

    axios.put(`${API_BASE_URL}/resumos/${resumoEditando.cp_res_id}`, formData)
      .then(() => {
        toast.success('Resumo editado com sucesso');
        setShowEditResumoModal(false);
        setResumoEditando(null);

        // Atualiza a lista de resumos
        const formattedDate = formatarDataParaAccondeon(selectedDate);
        axios.get(`${API_BASE_URL}/resumos/${formattedDate}/${selectedTurma}`)
          .then((response) => setResumos(response.data))
          .catch((error) => console.error("Erro ao buscar resumos:", error));
      })
      .catch((error) => {
        console.error("Erro ao editar resumo:", error);
        toast.error('Erro ao editar resumo');
      });
  };


  const formatarLink = (link) => {
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      return `https://${link}`;
    }
    return link;
  };


  const renderAulasSelect = () => {
    if (!selectedCursoId) {
      return null;
    }

    let aulas = [];
    let options = [];

    if (selectedCursoId >= 85 && selectedCursoId <= 90) {
      options = [
        { label: "Anual", value: "anual" },
        { label: "Semestral", value: "semestral" },
      ];
    } else if (selectedCursoId >= 115 && selectedCursoId <= 118) {
      options = [{ label: "Anual", value: "anual" }];
    }

    const handleCursoChange = (event) => {
      const tipoCurso = event.target.value;

      switch (selectedCursoId) {
        case 85:
          aulas = tipoCurso === "anual" ? book1.aulas : book1Semestral.aulas;
          break;
        case 87:
          aulas = tipoCurso === "anual" ? book2.aulas : book2Semestral.aulas;
          break;
        case 88:
          aulas = tipoCurso === "anual" ? book3.aulas : book3Semestral.aulas;
          break;
        case 89:
          aulas = tipoCurso === "anual" ? book4.aulas : book4Semestral.aulas;
          break;
        case 90:
          aulas = tipoCurso === "anual" ? book5.aulas : book5Semestral.aulas;
          break;
        case 115:
          aulas = tweens1.aulas;
          break;
        case 116:
          aulas = tweens2.aulas;
          break;
        case 117:
          aulas = tweens3.aulas;
          break;
        case 118:
          aulas = tweens4.aulas;
          break;
        default:
          aulas = [];
      }
      setSelectedAulaTipo(tipoCurso);
      setAulasDisponiveis(aulas);
    };

    return (
      <>
        <Form.Group controlId="formTipoCurso">
          <Form.Label>Selecione o tipo do curso</Form.Label>
          <Form.Control as="select" onChange={handleCursoChange}>
            <option value="">Selecione...</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {selectedAulaTipo && (
          <Form.Group controlId="formSelect">
            <Form.Label>Selecione uma Aula</Form.Label>
            <Form.Control
              as="select"
              value={selectedAula}
              onChange={handleAulaChange}
            >
              <option value="">Selecione uma Aula</option>
              {aulasDisponiveis.map((aula) => (
                <option key={aula.numero} value={aula.numero}>
                  Aula {aula.numero}: {aula.descricao.substring(0, 50)}...
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}
      </>
    );
  };

  return (
    <Container fluid>
      <ToastContainer />
      <Row className="justify-content-center my-4">
        <Col xs={12} md={12}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Sala de Aula</h2>
              {userType < 5 && (
                <Form.Group controlId="formTurma">
                  <Form.Label>Selecione a Turma</Form.Label>
                  <Form.Control as="select" onChange={handleSelectTurma}>
                    <option value="">Selecione...</option>
                    {turmas.map((turma) => (
                      <option key={turma.cp_tr_id} value={turma.cp_tr_id}>
                        {turma.cp_tr_nome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
              {errorMessage && (
                <Alert variant="warning" className="my-3">
                  {errorMessage}
                </Alert>
              )}
              {userType < 5 && selectedTurma && alunos.length > 0 && (
                <Table striped bordered hover responsive className="my-4">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome</th>
                      <th>Data</th>
                      <th>Hora</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alunos.map((aluno, index) => (
                      <tr key={aluno.cp_id}>
                        <td>{index + 1}</td>
                        <td>{aluno.cp_nome}</td>
                        <td>{formatarDataParaTabela(aluno.data)}</td>
                        <td>{aluno.hora}</td>
                        <td style={{ textAlign: "center" }}>
                          <Badge bg={getBadgeColorByStatus(aluno.status)}>
                            {aluno.status}
                          </Badge>
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            onClick={() => handleOpenModal(aluno)}
                          >
                            Editar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {userType < 5 ? (
                historicoChamadas.length > 0 && (
                  <Accordion defaultActiveKey="0" className="my-4">
                    {Object.entries(
                      historicoChamadas.reduce((acc, chamada) => {
                        const data = chamada.cp_ch_data;
                        if (!acc[data]) {
                          acc[data] = [];
                        }
                        acc[data].push(chamada);
                        return acc;
                      }, {})
                    ).map(([data, chamadasPorData]) => (
                      <Accordion.Item eventKey={data} key={data}>
                        <Accordion.Header
                          onClick={() => handleAccordionToggle(data)}
                        >
                          {formatarData(data)}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col md={6}>
                              {chamadasPorData.map((chamada, idx) => (
                                <Card key={idx} className="mb-3">
                                  <Card.Body>
                                    <Card.Title>
                                      {chamada.cp_nome_aluno}
                                    </Card.Title>
                                    <Card.Subtitle
                                      className="mb-2"
                                      style={{
                                        color: getColorByStatus(
                                          chamada.cp_ch_status
                                        ),
                                      }}
                                    >
                                      Status: {chamada.cp_ch_status}
                                    </Card.Subtitle>
                                    <Card.Text>
                                      Hora: {chamada.cp_ch_hora}
                                    </Card.Text>
                                    <div className="d-flex justify-content-between">
                                      <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={() =>
                                          handleEditChamada(chamada.cp_ch_id)
                                        }
                                      >
                                        Editar
                                      </Button>
                                      <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() =>
                                          handleDeleteChamada(chamada.cp_ch_id)
                                        }
                                      >
                                        Deletar
                                      </Button>
                                    </div>
                                  </Card.Body>
                                </Card>
                              ))}
                            </Col>

                            <Col md={6}>
                              <Form>
                                {renderAulasSelect()}
                                <Form.Group controlId="formInputText">
                                  <Form.Label>Resumo da aula</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Descreva aqui..."
                                    value={resumo}
                                    onChange={handleResumoChange}
                                  />
                                </Form.Group>
                                <Form.Group
                                  controlId="formLink"
                                  className="mt-3"
                                >
                                  <Form.Label>Link</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Insira o link"
                                    value={link}
                                    onChange={handleLinkChange}
                                  />
                                </Form.Group>

                                <Form.Group
                                  controlId="formLinkYoutube"
                                  className="mt-3"
                                >
                                  <Form.Label>Link do YouTube</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Insira o link do YouTube"
                                    value={linkYoutube}
                                    onChange={handleLinkYoutubeChange}
                                  />
                                </Form.Group>
                                <Form.Group
                                  controlId="formPdf3"
                                  className="mt-3"
                                >
                                  <Form.Label>Material do dia</Form.Label>
                                  <Form.Control
                                    type="file"
                                    onChange={handleMaterialChange}
                                  />
                                </Form.Group>
                                <div className="d-flex mt-2">
                                  <Button
                                    variant="primary"
                                    className="me-2"
                                    onClick={handleSaveResumoEMaterial}
                                  >
                                    Salvar
                                  </Button>
                                  <Button variant="secondary" disabled className="me-2">
                                    Salvar e Finalizar Turma
                                  </Button>
                                </div>
                              </Form>
                              {resumos.length > 0 && (
                                <div className="mt-4">
                                  <h4 className="mb-3">Registro de Conteúdos:</h4>
                                  <div className="d-flex flex-column">
                                    {resumos.map((resumo) => (
                                      <Card key={resumo.cp_res_id} className="mb-3">
                                        <Card.Body>
                                          <div className="d-flex justify-content-between">
                                            <div>
                                              {resumo.cp_res_aula && <Card.Title>Aula: {resumo.cp_res_aula}</Card.Title>}

                                              {/* Botão para abrir o material */}
                                              {resumo.cp_res_arquivo && (
                                                <Card.Subtitle className="mb-2 text-muted">
                                                  <Button variant="primary" onClick={() => handleShowPDF(resumo.cp_res_arquivo)}>
                                                    <FaFilePdf style={{ marginRight: '5px' }} /> Abrir Material
                                                  </Button>
                                                </Card.Subtitle>
                                              )}

                                              {/* Resumo */}
                                              {resumo.cp_res_resumo && (
                                                <Card.Text className="mt-3">
                                                  <strong>Resumo:</strong> {resumo.cp_res_resumo}
                                                </Card.Text>
                                              )}

                                              {/* Links lado a lado */}
                                              <div className="d-flex">
                                                {resumo.cp_res_link && (
                                                  <a
                                                    href={formatarLink(resumo.cp_res_link)}
                                                    target="_blank"
                                                    className="link-icon me-3"
                                                  >
                                                    <FaLink size={20} />
                                                  </a>
                                                )}
                                                {resumo.cp_res_link_youtube && (
                                                  <a
                                                    href={formatarLink(resumo.cp_res_link_youtube)}
                                                    target="_blank"
                                                    className="link-icon"
                                                  >
                                                    <FaYoutube size={20} />
                                                  </a>
                                                )}
                                              </div>
                                            </div>

                                            {/* Botões de editar e deletar */}
                                            <div className="d-flex">
                                              <Button variant="link" onClick={() => handleEditResumo(resumo)}>
                                                <FaEdit size={16} />
                                              </Button>
                                              <Button variant="link" onClick={() => handleDeleteResumo(resumo.cp_res_id)}>
                                                <FaTrashAlt size={16} />
                                              </Button>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    ))}
                                  </div>
                                </div>
                              )}

                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                )
              ) : userType === "5" ? (
                <SaladeAulaAluno />
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        style={{ zIndex: 1050 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Chamada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formData">
              <Form.Label>Data</Form.Label>
              <Form.Control
                type="date"
                value={formValues.data}
                onChange={(e) =>
                  setFormValues({ ...formValues, data: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formHora">
              <Form.Label>Hora</Form.Label>
              <Form.Control
                type="time"
                value={formValues.hora}
                onChange={(e) =>
                  setFormValues({ ...formValues, hora: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={formValues.status}
                onChange={(e) =>
                  setFormValues({ ...formValues, status: e.target.value })
                }
              >
                <option value="">Selecione...</option>
                <option value="Presente">Presente</option>
                <option value="Ausente">Ausente</option>
                <option value="Justificado">Justificado</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        centered
        style={{ zIndex: 1050 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Chamada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editFormData">
              <Form.Label>Data</Form.Label>
              <Form.Control
                type="date"
                value={editFormValues.data}
                onChange={(e) =>
                  setEditFormValues({ ...editFormValues, data: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="editFormHora">
              <Form.Label>Hora</Form.Label>
              <Form.Control
                type="time"
                value={editFormValues.hora}
                onChange={(e) =>
                  setEditFormValues({ ...editFormValues, hora: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="editFormStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={editFormValues.status}
                onChange={(e) =>
                  setEditFormValues({
                    ...editFormValues,
                    status: e.target.value,
                  })
                }
              >
                <option value="">Selecione...</option>
                <option value="Presente">Presente</option>
                <option value="Ausente">Ausente</option>
                <option value="Justificado">Justificado</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveEdicao}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPDF}
        onHide={handleClose}
        centered
        fullscreen
        dialogClassName="modal-90w"
        className="custom-modal-size"
        style={{ zIndex: "1050" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Visualizar PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={`https://docs.google.com/gview?url=${pdfUrl}&embedded=true&zoom=100`}
            width="100%"
            height="600px"
            className="custom-modal-iframe"
            style={{ border: "none" }}
            title="PDF Viewer"
          />
        </Modal.Body>
      </Modal>

      <Modal show={showEditResumoModal} onHide={() => setShowEditResumoModal(false)} 
        centered style={{ zIndex: 1050 }} >
        <Modal.Header closeButton>
          <Modal.Title>Editar Resumo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editFormTipoCurso">
              <Form.Label>Selecione o tipo do curso</Form.Label>
              <Form.Control
                as="select"
                value={editSelectedAulaTipo}
                onChange={handleEditCursoChange}
              >
                <option value="">Selecione...</option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {editSelectedAulaTipo && (
              <Form.Group controlId="editFormSelectAula">
                <Form.Label>Selecione uma Aula</Form.Label>
                <Form.Control
                  as="select"
                  value={editSelectedAula}
                  onChange={(e) => setEditSelectedAula(e.target.value)}
                >
                  <option value="">Selecione uma Aula</option>
                  {editAulasDisponiveis.map((aula) => (
                    <option key={aula.numero} value={aula.numero}>
                      Aula {aula.numero}: {aula.descricao.substring(0, 50)}...
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            <Form.Group controlId="formEditResumo">
              <Form.Label>Resumo</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={resumoEditando?.cp_res_resumo || ''}
                onChange={(e) => setResumoEditando({ ...resumoEditando, cp_res_resumo: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formEditLink">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                value={resumoEditando?.cp_res_link || ''}
                onChange={(e) => setResumoEditando({ ...resumoEditando, cp_res_link: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formEditLinkYoutube">
              <Form.Label>Link do YouTube</Form.Label>
              <Form.Control
                type="text"
                value={resumoEditando?.cp_res_link_youtube || ''}
                onChange={(e) => setResumoEditando({ ...resumoEditando, cp_res_link_youtube: e.target.value })}
              />
            </Form.Group>


            <Form.Group controlId="formEditMaterial">
              <Form.Label>Substituir Material</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setResumoEditando({ ...resumoEditando, cp_res_arquivo: e.target.files[0] })}
              />
            </Form.Group>


            <Button variant="primary" onClick={handleSaveEdicaoResumo} className="mt-3">
              <FaCheck /> Salvar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {showAlert && (
        <Alert
          variant="success"
          className="floating-alert"
          onClose={handleAlertClose}
          dismissible
        >
          {alertMessage}
        </Alert>
      )}
    </Container>
  );
};

export default SalaDeAula;
