const book2Semestral = {
  titulo: "Book 2 (Semestral)",
  aulas: [
    { numero: 1, descricao: "Abertura, boas vindas, apresentações. (Apresentação do material e metodologia Cipex, se necessário. Ex.: alunos vindos de outras escolas, começando no Cipex direto no livro 2)", unidade: "Unid 1", pagina: 11 },
    { numero: 2, descricao: "Unid 1 – pág 22 (guiar os alunos no que deve ser feito no workbook p/ próxima aula)", unidade: "Unid 1", pagina: 22 },
    { numero: 3, descricao: "Wkbk 1 – teste 1 - Unid 2 pág 28", unidade: "Wkbk 1", pagina: 0 },
    { numero: 4, descricao: "Unid 2 – pág 38", unidade: "Unid 2", pagina: 38 },
    { numero: 5, descricao: "Unid 2 – pág 46 (guiar o wkbk p/ próxima aula)", unidade: "Unid 2", pagina: 46 },
    { numero: 6, descricao: "Wkbk 2 – teste 2 – Unid 3 – pág 52", unidade: "Wkbk 2", pagina: 0 },
    { numero: 7, descricao: "Unid 3 – pág 63 (guiar o wkbk p/ próxima aula)", unidade: "Unid 3", pagina: 63 },
    { numero: 8, descricao: "Wkbk 3 – teste 3 – Unid 4 – pág 68", unidade: "Wkbk 3", pagina: 0 },
    { numero: 9, descricao: "Unid 4 – pág 79", unidade: "Unid 4", pagina: 79 },
    { numero: 10, descricao: "Unid 4 – pág 84 (guiar o wkbk p/ próxima aula)", unidade: "Unid 4", pagina: 84 },
    { numero: 11, descricao: "Wkbk 4 – teste 4 – Unid 5 – pág 91", unidade: "Wkbk 4", pagina: 0 },
    { numero: 12, descricao: "Unid 5 – pág 101 (guiar o wkbk p/ próxima aula)", unidade: "Unid 5", pagina: 101 },
    { numero: 13, descricao: "Unid 5 – final – Wkbk 5 – teste 5", unidade: "Unid 5", pagina: 0 },
    { numero: 14, descricao: "Unid 6 – pág 113 (trabalhar bem a pronuncia do “ed” nessa aula)", unidade: "Unid 6", pagina: 113 },
    { numero: 15, descricao: "Unid 6 – pág 125 (guiar o wkbk p/ próxima aula)", unidade: "Unid 6", pagina: 125 },
    { numero: 16, descricao: "Wkbk 6 – teste 6 – Unid 7 – pág 134", unidade: "Wkbk 6", pagina: 0 },
    { numero: 17, descricao: "Unid 7 – pág 143 (guiar o wkbk p/ próxima aula)", unidade: "Unid 7", pagina: 143 },
    { numero: 18, descricao: "Wkbk 7 – teste 7 – Unid 8 – pág 147", unidade: "Wkbk 7", pagina: 0 },
    { numero: 19, descricao: "Unid 8 – pág 158 (guiar o wkbk p/ próxima aula)", unidade: "Unid 8", pagina: 158 },
    { numero: 20, descricao: "Unid 8 – final – Wkbk 8 – teste 8", unidade: "Wkbk 8", pagina: 0 },
    { numero: 21, descricao: "Unid 9 – pág 174 (guiar o wkbk p/ próxima aula)", unidade: "Unid 9", pagina: 174 },
    { numero: 22, descricao: "Unid 9 – pág 179 - Wkbk 9 – teste 9", unidade: "Unid 9", pagina: 0 },
    { numero: 23, descricao: "Unid 10 – pág 186", unidade: "Unid 10", pagina: 186 },
    { numero: 24, descricao: "Unid 10 – pág 198 (guiar o wkbk p/ próxima aula)", unidade: "Unid 10", pagina: 198 },
    { numero: 25, descricao: "Wkbk 10 – teste 10 – Considerações finais", unidade: "Wkbk 10", pagina: 0 }
  ]
};

export { book2Semestral };
