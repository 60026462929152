import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Badge,
  Card,
  Pagination,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { API_BASE_URL } from "./config";
import { FaPencilAlt } from "react-icons/fa";

const Financeiro = () => {
  const [dados, setDados] = useState([]);
  const [totalAtrasado, setTotalAtrasado] = useState(0);
  const [valorMensal, setValorMensal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [userType, setUserType] = useState(
    parseInt(localStorage.getItem("userType"), 10)
  );
  const [nomeFiltro, setNomeFiltro] = useState("");
  const [statusFiltro, setStatusFiltro] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [selectedParcela, setSelectedParcela] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/financeira`);
      const parcelas = response.data;

      const fetchNomes = async (id) => {
        try {
          const response = await axios.get(`${API_BASE_URL}/financeira/${id}`);
          return response.data[0]?.cp_mt_nome_usuario || "Desconhecido";
        } catch (error) {
          console.error("Erro ao buscar nome do usuário:", error);
          return "Erro";
        }
      };

      const verificarStatus = (status, dataVencimento) => {
        const hoje = new Date();
        const dataVenc = new Date(dataVencimento);
        if (status === "à vencer" && dataVenc < hoje) {
          return "Vencido";
        }
        return status;
      };

      const formatarData = (data) => {
        const dataObj = new Date(data);
        const dia = String(dataObj.getDate()).padStart(2, "0");
        const mes = String(dataObj.getMonth() + 1).padStart(2, "0");
        const ano = dataObj.getFullYear();
        return `${dia}/${mes}/${ano}`;
      };

      const dadosComNomes = await Promise.all(
        parcelas.map(async (parcela) => {
          const nome = await fetchNomes(parcela.cp_mt_id);
          const statusAtualizado = verificarStatus(
            parcela.cp_mtPar_status,
            parcela.cp_mtPar_dataParcela
          );
          const dataFormatada = formatarData(parcela.cp_mtPar_dataParcela);
          return {
            ...parcela,
            nome,
            cp_mtPar_status: statusAtualizado,
            cp_mtPar_dataParcela: dataFormatada,
          };
        })
      );

      // Filtrar com base no tipo de usuário
      let dadosFiltrados = dadosComNomes;
      if (userType === 5) {
        const userName = localStorage.getItem("userName");
        dadosFiltrados = dadosComNomes.filter((dado) => dado.nome === userName);

        // Calcular o valor mensal apenas para o usuário logado
        const valorMensalUsuario = dadosFiltrados
          .filter((dado) => {
            const dataParcela = new Date(
              dado.cp_mtPar_dataParcela.replace(
                /(\d{2})\/(\d{2})\/(\d{4})/,
                "$3-$2-$1"
              )
            );
            return dataParcela.getMonth() === new Date().getMonth(); // Filtra pelo mês atual
          })
          .reduce(
            (acc, curr) => acc + parseFloat(curr.cp_mtPar_valorParcela),
            0
          );

        setValorMensal(valorMensalUsuario.toFixed(2));
      } else if (userType === 1) {
        // Calcular a soma total do valor mensal para todos os usuários no mês atual
        const valorMensalTotal = dadosComNomes
          .filter((dado) => {
            const dataParcela = new Date(
              dado.cp_mtPar_dataParcela.replace(
                /(\d{2})\/(\d{2})\/(\d{4})/,
                "$3-$2-$1"
              )
            );
            return dataParcela.getMonth() === new Date().getMonth(); // Filtra pelo mês atual
          })
          .reduce(
            (acc, curr) => acc + parseFloat(curr.cp_mtPar_valorParcela),
            0
          );

        setValorMensal(valorMensalTotal.toFixed(2));
      }

      setDados(dadosFiltrados);

      // Calcular o total em atraso
      const totalAtrasado = dadosFiltrados
        .filter((dado) => dado.cp_mtPar_status === "Vencido")
        .reduce((acc, curr) => acc + parseFloat(curr.cp_mtPar_valorParcela), 0);

      setTotalAtrasado(totalAtrasado.toFixed(2));
    } catch (error) {
      console.error("Erro ao buscar dados financeiros:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userType]);

  const getBadgeVariant = (status) => {
    switch (status) {
      case "Pago":
        return "success";
      case "à vencer":
        return "warning";
      case "Vencido":
        return "danger";
      default:
        return "secondary";
    }
  };

  // Paginação
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = dados
    .filter((dado) =>
      dado.nome.toLowerCase().includes(nomeFiltro.toLowerCase())
    )
    .filter((dado) =>
      statusFiltro ? dado.cp_mtPar_status === statusFiltro : true
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(
    dados
      .filter((dado) =>
        dado.nome.toLowerCase().includes(nomeFiltro.toLowerCase())
      )
      .filter((dado) =>
        statusFiltro ? dado.cp_mtPar_status === statusFiltro : true
      ).length / itemsPerPage
  );

  const handlePagination = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const handleBadgeClick = (parcela) => {
    setSelectedParcela(parcela);
    setCurrentStatus(parcela.cp_mtPar_status);
    setShowModal(true);
  };

  const handleStatusUpdate = async () => {
    try {
      if (selectedParcela) {
        await axios.put(
          `${API_BASE_URL}/update-status/${selectedParcela.cp_mtPar_id}`,
          {
            status: currentStatus,
          }
        );
        setShowModal(false);
        fetchData(); // Recarregar os dados para atualizar a tela
      }
    } catch (error) {
      console.error("Erro ao atualizar o status:", error);
    }
  };

  return (
    <Container fluid>
      <Row className="my-4 ">
        <Col xs={12}>
          <Card>
            <Card.Header className="text-start">
              <h1>Financeiro</h1>
            </Card.Header>
          </Card>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12} md={6} className="mb-3 mb-md-0">
          <Card style={{ padding: "15px" }}>
            <h3 className="text-center text-md-start">Valor mensal</h3>
            <p className="text-center text-md-start">R$ {valorMensal}</p>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card style={{ padding: "15px" }}>
            <h3 className="text-center text-md-start">Valor em atraso</h3>
            <p className="text-center text-md-start">R$ {totalAtrasado}</p>
          </Card>
        </Col>
      </Row>
      <Row className="mb-3">
        {userType === 1 && (
          <>
            <Col xs={12} md={6} className="mb-2 mb-md-0">
              <Form.Control
                type="text"
                placeholder="Pesquisar por nome"
                value={nomeFiltro}
                onChange={(e) => setNomeFiltro(e.target.value)}
              />
            </Col>
            <Col xs={12} md={6}>
              <Form.Control
                as="select"
                value={statusFiltro}
                onChange={(e) => setStatusFiltro(e.target.value)}
              >
                <option value="">Todos os Status</option>
                <option value="Pago">Pago</option>
                <option value="à vencer">À vencer</option>
                <option value="Vencido">Vencido</option>
              </Form.Control>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col style={{ marginBottom: "10px" }} xs={12}>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Status</th>
                <th>Data de Vencimento</th>
                <th>Nome</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((dado, index) => (
                <tr key={index}>
                  {userType <= 3 ? (
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleBadgeClick(dado)}
                    >
                      <Badge bg={getBadgeVariant(dado.cp_mtPar_status)}>
                        {dado.cp_mtPar_status}
                        <FaPencilAlt style={{ marginLeft: "5px" }} />
                      </Badge>
                    </td>
                  ) : (
                    <td>
                      <Badge bg={getBadgeVariant(dado.cp_mtPar_status)}>
                        {dado.cp_mtPar_status}
                      </Badge>
                    </td>
                  )}
                  <td>{dado.cp_mtPar_dataParcela}</td>
                  <td>{dado.nome}</td>
                  <td>R$ {dado.cp_mtPar_valorParcela}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {userType !== 5 && (
        <Row className="justify-content-center align-items-center">
          <Col className="d-flex justify-content-center">
            <Pagination>
              <Pagination.First onClick={() => handlePagination(1)} />
              <Pagination.Prev
                onClick={() => handlePagination(currentPage - 1)}
              />
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <Pagination.Item
                  key={pageNumber + 1}
                  active={currentPage === pageNumber + 1}
                  onClick={() => handlePagination(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handlePagination(currentPage + 1)}
              />
              <Pagination.Last onClick={() => handlePagination(totalPages)} />
            </Pagination>
          </Col>
          <Col xs="auto" style={{ marginTop: "-20px" }}>
            <Form.Control
              as="select"
              value={currentPage}
              onChange={(e) => handlePagination(parseInt(e.target.value))}
              className="mt-2"
              style={{ width: "70px" }}
            >
              {Array.from({ length: totalPages }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ zIndex: 1050 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alterar Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Selecione o novo status:</Form.Label>
            <Form.Control
              as="select"
              value={currentStatus}
              onChange={(e) => setCurrentStatus(e.target.value)}
            >
              <option value="Pago">Pago</option>
              <option value="à vencer">À vencer</option>
              <option value="Vencido">Vencido</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleStatusUpdate}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Financeiro;
