// ProvaDetalhada.js
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const ProvaDetalhada = ({ curso }) => {
  return (
    <div>
      <h5>Curso: {curso}</h5>
      <h6>Questões de Múltipla Escolha</h6>
      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>1. What is the capital of France?</Form.Label>
            <div>
              <Form.Check
                type="radio"
                label="a) Berlin"
                name="questao1"
                id="questao1a"
              />
              <Form.Check
                type="radio"
                label="b) Madrid"
                name="questao1"
                id="questao1b"
              />
              <Form.Check
                type="radio"
                label="c) Paris"
                name="questao1"
                id="questao1c"
              />
              <Form.Check
                type="radio"
                label="d) Lisbon"
                name="questao1"
                id="questao1d"
              />
            </div>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>2. What is the largest planet in the Solar System?</Form.Label>
            <div>
              <Form.Check
                type="radio"
                label="a) Earth"
                name="questao2"
                id="questao2a"
              />
              <Form.Check
                type="radio"
                label="b) Mars"
                name="questao2"
                id="questao2b"
              />
              <Form.Check
                type="radio"
                label="c) Jupiter"
                name="questao2"
                id="questao2c"
              />
              <Form.Check
                type="radio"
                label="d) Saturn"
                name="questao2"
                id="questao2d"
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
      <h6>Questões Dissertativas</h6>
      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>1. Describe the process of photosynthesis.</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>2. Explain Einstein's theory of relativity.</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
        </Col>
      </Row>
      <h6>English Course Questions</h6>
      <Row>
        <Col md={6}>
          <Form.Group>
            <Form.Label>3. Choose the correct option: "She ____ to the store every day."</Form.Label>
            <div>
              <Form.Check
                type="radio"
                label="a) go"
                name="questao3"
                id="questao3a"
              />
              <Form.Check
                type="radio"
                label="b) goes"
                name="questao3"
                id="questao3b"
              />
              <Form.Check
                type="radio"
                label="c) going"
                name="questao3"
                id="questao3c"
              />
              <Form.Check
                type="radio"
                label="d) gone"
                name="questao3"
                id="questao3d"
              />
            </div>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>4. Translate the following sentence to English: "Ela vai à escola todos os dias."</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default ProvaDetalhada;
