import React, { useState } from "react";
import axios from "axios";
import "./CadastroModal.css"; // Importe o arquivo CSS do modal
import { API_BASE_URL } from "./config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";

const opcoesCursos = [
  { value: "FERRIS WHEEL 1", label: "[ING] - FERRIS WHEEL 1" },
  { value: "FERRIS WHEEL 2", label: "[ING] - FERRIS WHEEL 2" },
  { value: "FERRIS WHEEL 3", label: "[ING] - FERRIS WHEEL 3" },
  { value: "BEST BUDDIES 1", label: "[ING] - BEST BUDDIES 1" },
  { value: "BEST BUDDIES 2", label: "[ING] - BEST BUDDIES 2" },
  { value: "BEST BUDDIES 3", label: "[ING] - BEST BUDDIES 3" },
  { value: "KIDS 1", label: "[ING] - Kids 1" },
  { value: "KIDS 2", label: "[ING] - Kids 2" },
  { value: "NEXT STATION STARTER", label: "[ING] - NEXT STATION STARTER" },
  { value: "NEXT STATION 2", label: "[ING] - NEXT STATION 2" },
  { value: "NEXT STATION 3", label: "[ING] - NEXT STATION 3" },
  { value: "CIPEX TWEENS 1", label: "[ING] - CIPEX TWEENS 1" },
  { value: "CIPEX TWEENS 2", label: "[ING] - CIPEX TWEENS 2" },
  { value: "CIPEX TWEENS 3", label: "[ING] - CIPEX TWEENS 3" },
  { value: "CIPEX TWEENS 4", label: "[ING] - CIPEX TWEENS 4" },
  { value: "CIPEX TEENS 5", label: "[ING] - CIPEX TEENS 5" },
  { value: "CIPEX ENGLISH BOOK 1", label: "[ING] - CIPEX ENGLISH BOOK 1" },
  { value: "CIPEX ENGLISH BOOK 2", label: "[ING] - CIPEX ENGLISH BOOK 2" },
  { value: "CIPEX ENGLISH BOOK 3", label: "[ING] - CIPEX ENGLISH BOOK 3" },
  { value: "CIPEX ENGLISH BOOK 4", label: "[ING] - CIPEX ENGLISH BOOK 4" },
  { value: "CIPEX ENGLISH BOOK 5", label: "[ING] - CIPEX ENGLISH BOOK 5" },
  { value: "CIPEX ENGLISH BOOK 6", label: "[ING] - CIPEX ENGLISH BOOK 6" },
  { value: "CIPEX ENGLISH BOOK 7", label: "[ING] - CIPEX ENGLISH BOOK 7" },
  { value: "TV BOX CONVERSATION VOL. 1", label: "[ING] - TV BOX CONVERSATION VOL. 1" },
  { value: "TV BOX CONVERSATION VOL. 2", label: "[ING] - TV BOX CONVERSATION VOL. 2" },
  { value: "THE BUSINESS PRE-INTERMEDIATE", label: "[ING] - The Business Pre-Intermediate" },
  { value: "THE BUSINESS INTERMEDIATE", label: "[ING] - The Business Intermediate" },
  { value: "THE BUSINESS UPPER-INTERMEDIATE", label: "[ING] - The Business Upper-Intermediate" },
  { value: "THE BUSINESS ADVANCED", label: "[ING] - The Business Advanced" },
  { value: "IN COMPANY 3.0 PRE-INTERMEDIATE", label: "[ING] - In Company 3.0 Pre-Intermediate" },
  { value: "IN COMPANY 3.0 INTERMEDIATE", label: "[ING] - In Company 3.0 Intermediate" },
  { value: "IN COMPANY 3.0 UPPER-INTERMEDIATE", label: "[ING] - In Company 3.0 Upper-Intermediate" },
  { value: "IN COMPANY 3.0 ADVANCED", label: "[ING] - In Company 3.0 Advanced" },
  { value: "NUEVO ESPAÑOL EN MARCHA 1", label: "[ESP] - NUEVO ESPAÑOL EN MARCHA 1" },
  { value: "NUEVO ESPAÑOL EN MARCHA 2", label: "[ESP] - NUEVO ESPAÑOL EN MARCHA 2" },
  { value: "NUEVO ESPAÑOL EN MARCHA 3", label: "[ESP] - NUEVO ESPAÑOL EN MARCHA 3" },
  { value: "NUEVO ESPAÑOL EN MARCHA 4", label: "[ESP] - NUEVO ESPAÑOL EN MARCHA 4" },
  { value: "ALFABETIZACAO", label: "[ALE] - ALFABETIZAÇÃO" },
  { value: "MOMENTE A1", label: "[ALE] - MOMENTE A1" },
  { value: "MOMENTE A2", label: "[ALE] - MOMENTE A2" },
  { value: "MOMENTE B1", label: "[ALE] - MOMENTE B1" },
  { value: "ASPEKTE B2", label: "[ALE] - ASPEKTE B2" },
  { value: "DAF+", label: "[ALE] - DAF+" },
  { value: "TESTE", label: "[TT] - TESTE" }
];



const CadastroCursoModal = ({ closeModal, cursoDataToEdit }) => {
  const [cursoData, setCursoData] = useState({
    cp_nome_curso: "",
    audios: [],
    cp_curso_id: cursoDataToEdit ? cursoDataToEdit.cp_curso_id : null,
    cp_youtube_link_curso: "",
    pdf1: null,
    pdf2: null,
    pdf3: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCursoData((prevCursoData) => ({
      ...prevCursoData,
      [name]: value,
    }));
  };

  const handleCursoDeletar = async (cursoId) => {
    try {
      await axios.delete(`${API_BASE_URL}/delete-curso/${cursoId}`);
      console.log("Curso excluído com sucesso");
      toast.success("Curso excluído com sucesso");
    } catch (error) {
      console.error("Erro ao excluir o curso:", error);
      toast.error("Erro ao excluir o curso");
    }
  };


  const handleFileChange = (e) => {
    const files = e.target.files;
    setCursoData((prevCursoData) => ({
      ...prevCursoData,
      audioFiles: files,
    }));
  };

  const handlePDFChange = (e) => {
    const { name, files } = e.target;
    setCursoData((prevCursoData) => ({
      ...prevCursoData,
      [name]: files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); 
  
    let cursoId; 
  
    try {
      const formData = new FormData();
      formData.append("cp_nome_curso", cursoData.cp_nome_curso);
      formData.append("cp_youtube_link_curso", cursoData.cp_youtube_link_curso);
  
      if (cursoData.pdf1) formData.append("pdf1", cursoData.pdf1);
      if (cursoData.pdf2) formData.append("pdf2", cursoData.pdf2);
      if (cursoData.pdf3) formData.append("pdf3", cursoData.pdf3);
  
      
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
  
      
      const response = await axios.post(`${API_BASE_URL}/cursos`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      cursoId = response.data.cursoId; 
  
      
      const audioFormData = new FormData();
      Array.from(cursoData.audioFiles).forEach((audio) => {
        audioFormData.append("audios", audio);
      });
  
      await axios.post(`${API_BASE_URL}/register-audio/${cursoId}`, audioFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      toast.success("Curso e áudios cadastrados com sucesso!");
    } catch (error) {
      console.error("Erro ao cadastrar o curso ou áudios:", error);
      toast.error("Erro ao cadastrar o curso ou áudios");
  
      
      if (cursoId) {
        await handleCursoDeletar(cursoId);
      }
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div className="modal-edit">
      <ToastContainer />
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <form onSubmit={handleSubmit} className="form-container-cad">
          <Row>
            <Col md={4}>
              <label htmlFor="cp_nome_curso">Nome do Curso:</label>
              <select
                id="cp_nome_curso"
                name="cp_nome_curso"
                value={cursoData.cp_nome_curso}
                onChange={handleChange}
                className="input-field"
                required
              >
                <option value="">Selecione um curso</option>
                {opcoesCursos.map((curso, index) => (
                  <option key={index} value={curso.value}>
                    {curso.label}
                  </option>
                ))}
              </select>
            </Col>

            <Col md={4}>
              <label htmlFor="audios">Adicionar Áudios:</label>
              <input
                type="file"
                id="audios"
                // name="audios"
                name="audioFiles"
                onChange={handleFileChange}
                className="input-field"
                multiple
                accept="audio/*"
              />
            </Col>

            <Col md={4}>
              <label htmlFor="cp_youtube_link_curso">Link do YouTube:</label>
              <input
                type="text"
                id="cp_youtube_link_curso"
                name="cp_youtube_link_curso"
                value={cursoData.cp_youtube_link_curso}
                onChange={handleChange}
                className="input-field"
                placeholder="Insira o link do YouTube"
              />
            </Col>

            <Col md={4}>
              <label htmlFor="pdf1">Adicionar PDF 1:</label>
              <input
                type="file"
                id="pdf1"
                name="pdf1"
                onChange={handlePDFChange}
                className="input-field"
                accept="application/pdf"
              />
            </Col>

            <Col md={4}>
              <label htmlFor="pdf2">Adicionar PDF 2:</label>
              <input
                type="file"
                id="pdf2"
                name="pdf2"
                onChange={handlePDFChange}
                className="input-field"
                accept="application/pdf"
              />
            </Col>

            <Col md={4}>
              <label htmlFor="pdf3">Adicionar PDF 3:</label>
              <input
                type="file"
                id="pdf3"
                name="pdf3"
                onChange={handlePDFChange}
                className="input-field"
                accept="application/pdf"
              />
            </Col>

            <Col md={12}>
              <button className="submit-button" type="submit">
                Cadastrar Curso
              </button>
            </Col>
          </Row>
        </form>
      )}
      <button className="close-button" onClick={closeModal}>X</button>
    </div>
  );
};

export default CadastroCursoModal;