import React, { useState } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from './pages/config';
import './login.css';
import background from '../components/pages/arquivos/gradiente.jpg';
import logo from '../components/pages/arquivos/logo.png';
import frase from '../components/pages/arquivos/frase.png';

const Login = () => {
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();

  const handleClickLogin = async (values) => {
    try {
      const response = await Axios.post(`${API_BASE_URL}/login`, values);
      console.log(response.data);

      if (response.data.msg === 'Usuário Logado com sucesso') {
        // Armazenar o tipo de usuário após o login bem-sucedido
        localStorage.setItem('userType', response.data.userType);
        localStorage.setItem('userName', response.data.userName);
        localStorage.setItem('userId', response.data.userId);
        localStorage.setItem('userProfilePhoto', response.data.userProfilePhoto);
        localStorage.setItem('schoolId', response.data.schoolId);
        localStorage.setItem('turmaID', response.data.turmaID);

        toast.success('Login realizado com sucesso!');

        if (response.data.userType === 5) {
          navigate('/index/');
        } else {
          navigate('/index');
        }
      } else {
        // setLoginError(true);
        toast.error('Usuário ou senha incorretos.');
      }
    } catch (error) {
      if (error.response) {
        console.error('Erro de resposta do servidor:', error.response.data);
        console.error('Status do erro:', error.response.status);
        console.error('Cabeçalhos do erro:', error.response.headers);
      } else if (error.request) {
        console.error('Erro na requisição:', error.request);
      } else {
        console.error('Erro ao processar a requisição:', error.message);
      }
      console.error('Erro ao fazer login:', error);
      // setLoginError(true);
      toast.error('Erro ao fazer login.');
    }
  };

  return (
    // <div className="pageLogin">

    //   {/* <div className="row">
    //     <div className="col-md-6 col-lg-6 d-none d-md-block"> */}
    //   <div className='container-formulario'>
    //     <div className='coluna-imagem'>
    //       <div className="imagem-container">
    //         <div className='fundo-img'></div>
    //         {/* <img src={estudanteImg} alt="imagem-login" className="imagem-login" />-->*/}
    //       </div>


    //     </div>
    //     <div className='coluna-formulario'>
    //       <Formik initialValues={{ login: '', password: '' }} onSubmit={handleClickLogin}>
    //         {({ handleSubmit }) => (
    //           <form className="formLogin" onSubmit={handleSubmit}>
    //             <h1>Logar</h1>
    //             <p>Digite os seus dados de acesso no campo abaixo.</p>
    //             <span>Login</span>
    //             <Field name="login" placeholder="Digite seu login" autoFocus={true} />
    //             <ErrorMessage component="span" name="login" className="form-error" />
    //             <span>Senha</span>
    //             <Field name="password" type="password" placeholder="Sua senha" />
    //             <ErrorMessage component="span" name="password" className="form-error" />
    //             <button type="submit" className="btn-login">
    //               Acessar
    //             </button>
    //             {loginError && <p className="error-message">Usuário ou senha incorretos.</p>}
    //           </form>
    //         )}
    //       </Formik>
    //     </div>
    //   </div>
    // </div>
    <Container fluid className="login-container">
      <ToastContainer />
      <Row>
        <Col className="logo-container">
          <Image src={logo} className="logo" />
        </Col>
      </Row>
      <Row className="justify-content-between align-items-center min-vh-100">
        <Col md={6} className="text-center">
          <Image src={frase} className="frase" />
        </Col>
        <Col md={4} className="login-form">
          <h2 className="text-center">Logar</h2>
          <p className="text-center">Digite os seus dados de acesso no campo abaixo.</p>
          <Formik initialValues={{ login: '', password: '' }} onSubmit={handleClickLogin}>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formLogin">
                  <Form.Label>Login</Form.Label>
                  <Field name="login" type="text" placeholder="Login" className="form-control" />
                  <ErrorMessage component="div" name="login" className="form-error" />
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Label>Senha</Form.Label>
                  <Field name="password" type="password" placeholder="Senha" className="form-control" />
                  <ErrorMessage component="div" name="password" className="form-error" />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100 mt-2">
                  Acessar
                </Button>
                {/* {loginError && <p className="error-message">Usuário ou senha incorretos.</p>} */}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;