import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Pagination,
} from "react-bootstrap";
import { FaTrashAlt, FaPlay } from "react-icons/fa";
import CadastroCursosModal from "./CadastroCursosModal";
import "./CadastroModal.css";
import AudioModal from "./AudioModal";
import { API_BASE_URL } from "./config";

const Modal = ({ children }) => {
  return (
    <div className="modal-background">
      <div className="modal-content">
        <div className="modal-form">{children}</div>
      </div>
    </div>
  );
};

const CadastroCurso = () => {
  const [cursos, setCursos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [cursoDataToEdit, setCursoDataToEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [coursesPerPage, setCoursesPerPage] = useState(6);
  const [searchTerm, setSearchTerm] = useState("");
  const [turmas, setTurmas] = useState([]);
  const [audioModalData, setAudioModalData] = useState({
    cursoId: null,
    cursoAudios: [],
  });
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    fetchCursos();
  }, []);

  // const fetchCursos = async () => {
  //   try {
  //     const response = await axios.get(`${API_BASE_URL}/cursos`);
  //     setCursos(response.data);
  //   } catch (error) {
  //     console.error("Erro ao buscar os cursos:", error);
  //   }
  // };

  const fetchCursos = async () => {
    try {
      const responseCursos = await axios.get(`${API_BASE_URL}/cursos`);
      setCursos(responseCursos.data);

      const userType = parseInt(localStorage.getItem("userType"));

      if (userType === 4) {
        // Se o userType for 4 (professor)
        const responseTurmas = await axios.get(`${API_BASE_URL}/turmas`);
        const professorTurmas = responseTurmas.data.filter(
          (turma) =>
            turma.cp_tr_id_professor ===
            parseInt(localStorage.getItem("userId"))
        );
        setTurmas(professorTurmas);
      } else {
        setTurmas([]); // Se não for professor, não precisa das turmas
      }
    } catch (error) {
      console.error("Erro ao buscar os cursos e turmas:", error);
    }
  };

  const userType = parseInt(localStorage.getItem("userType"));

  const cursosFiltradosPorTurma =
    userType === 4
      ? cursos.filter((curso) =>
          turmas.some((turma) => turma.cp_tr_curso_id === curso.cp_curso_id)
        )
      : cursos;

  const handleDelete = async (cursoId) => {
    try {
      await axios.delete(`${API_BASE_URL}/delete-curso/${cursoId}`);
      fetchCursos();
    } catch (error) {
      console.error("Erro ao excluir curso:", error);
    }
  };

  const openEditModal = (cursoId) => {
    const curso = cursos.find((curso) => curso.cp_curso_id === cursoId);
    setCursoDataToEdit(curso);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    fetchCursos();
    setShowAudioModal(false);
  };

  const handleAddModal = () => {
    setCursoDataToEdit(null);
    setShowModal(true);
  };

  const handleViewAudios = async (cursoId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/audios-curso/${cursoId}`
      );
      const cursoAudios = response.data;
      setAudioModalData({ cursoId, cursoAudios });
      setShowAudioModal(true); // Define o estado para exibir o modal de áudio
    } catch (error) {
      console.error("Erro ao buscar áudios do curso:", error);
    }
  };

  const handleSortChange = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSearchTerm("");
    setCurrentPage(1);
  };

  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;

  // let filteredCourses = cursos.filter((curso) =>
  //   curso.cp_nome_curso.toLowerCase().includes(searchTerm.toLowerCase())
  // );
  let filteredCourses = cursosFiltradosPorTurma.filter((curso) =>
    curso.cp_nome_curso.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (selectedCategory) {
    filteredCourses = filteredCourses.filter((curso) =>
      curso.cp_nome_curso
        .toLowerCase()
        .startsWith(selectedCategory.toLowerCase())
    );
  }

  const sortedCourses = filteredCourses.sort((a, b) => {
    const nameA = a.cp_nome_curso.toLowerCase();
    const nameB = b.cp_nome_curso.toLowerCase();
    return sortDirection === "asc"
      ? nameA.localeCompare(nameB)
      : nameB.localeCompare(nameA);
  });

  const currentCourses = sortedCourses.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container fluid>
      <div className="background-image">
        <div className="form-container">
          <div className="header-content">
            <h1 className="user-title">Audios</h1>
            <div className="header-controls">
              <form className="search-form">
                <input
                  type="text"
                  placeholder="Pesquisar..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </form>
              <div className="buttons">
                {localStorage.getItem("userType") <= 3 && (
                  <Button
                    className="submit-buttonB"
                    variant="primary"
                    onClick={handleAddModal}
                  >
                    + Adicionar
                  </Button>
                )}
                {/* <Button
                  className="submit-buttonB"
                  variant="primary"
                  onClick={handleAddModal}
                >
                  + Adicionar
                </Button> */}
                <Button
                  className="submit-buttonB"
                  variant="primary"
                  onClick={handleSortChange}
                >
                  Ordenar por {sortDirection === "asc" ? "A-Z" : "Z-A"}
                </Button>
              </div>
            </div>
          </div>

          <div className="categories-container">
            {/* <p style={{ fontSize: "18px" }}>Categorias:</p> */}
            {/* <div className="category-buttons justify-content-center text-center">
              {[
                "FERRIS WHEEL",
                "NEXT STATION",
                "CIPEX TWEENS",
                "CIPEX TEENS",
                "CIPEX ENGLISH",
                "TV BOX CONVERSATION",
                "NUEVO ESPAÑOL EN MARCHA",
                // "ALFABETIZAÇÃO",
                "MOMENTE",
                "ASPEKTE",
                "CONVERSAÇÃO",
              ].map((category, index) => (
                <Button
                  style={{ margin: "1px" }}
                  key={index}
                  variant="danger"
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </Button>
              ))}
            </div> */}
          </div>

          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Nome do Curso</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentCourses.map((curso) => (
                <tr key={curso.cp_curso_id}>
                  <td>{curso.cp_nome_curso}</td>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <Button
                      variant="primary"
                      style={{ margin: " 0 5px" }}
                      onClick={() => handleViewAudios(curso.cp_curso_id)}
                    >
                      <FaPlay />
                    </Button>
                    {localStorage.getItem("userType") <= 3 && (
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(curso.cp_curso_id)}
                      >
                        <FaTrashAlt />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* <div className="pagination-container">
        <ul className="pagination-numero">
          {coursesPerPage < filteredCourses.length &&
            Array.from(
              { length: Math.ceil(filteredCourses.length / coursesPerPage) },
              (_, i) => (
                <li key={i} className={i + 1 === currentPage ? "active" : ""}>
                  <Button variant="link" onClick={() => paginate(i + 1)}>
                    {i + 1}
                  </Button>
                </li>
              )
            )}
        </ul>
      </div> */}
      <Pagination className="justify-content-center">
        <Pagination.First onClick={() => paginate(1)} />
        <Pagination.Prev
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        />

        {Array.from(
          { length: Math.ceil(filteredCourses.length / coursesPerPage) },
          (_, i) => (
            <Pagination.Item
              key={i}
              active={i + 1 === currentPage}
              onClick={() => paginate(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          )
        )}

        <Pagination.Next
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredCourses.length / coursesPerPage)
          }
        />
        <Pagination.Last
          onClick={() =>
            paginate(Math.ceil(filteredCourses.length / coursesPerPage))
          }
        />
      </Pagination>

      {showModal && (
        <Modal>
          <CadastroCursosModal
            closeModal={closeModal}
            isEdit={!!cursoDataToEdit}
            cursoDataToEdit={cursoDataToEdit}
          />
        </Modal>
      )}

      {showAudioModal && (
        <Modal>
          <AudioModal closeModal={closeModal} audioModalData={audioModalData} />
        </Modal>
      )}
    </Container>
  );
};

export default CadastroCurso;
