const book2 = {
  titulo: "Book 2 (Anual)",
  aulas: [
    { numero: 1, descricao: "Abertura, boas vindas, apresentações. (Apresentação do material e metodologia Cipex, se necessário. Ex.: alunos vindos de outras escolas, começando no Cipex direto no livro 2)", unidade: "Unid 1", pagina: 7 },
    { numero: 2, descricao: "Unid 1 – pág 17", unidade: "Unid 1", pagina: 17 },
    { numero: 3, descricao: "Unid 1 – pág 22 (guiar os alunos no que deve ser feito no workbook p/ próxima aula)", unidade: "Unid 1", pagina: 22 },
    { numero: 4, descricao: "Wkbk 1 – teste 1", unidade: "Wkbk 1", pagina: 0 },
    { numero: 5, descricao: "Unid 2 – pág 30", unidade: "Unid 2", pagina: 30 },
    { numero: 6, descricao: "Unid 2 – pág 38", unidade: "Unid 2", pagina: 38 },
    { numero: 7, descricao: "Unid 2 – pág 46 (guiar o wkbk p/ próxima aula)", unidade: "Unid 2", pagina: 46 },
    { numero: 8, descricao: "Wkbk 2 – teste 2", unidade: "Wkbk 2", pagina: 0 },
    { numero: 9, descricao: "Unid 3 – pág 51", unidade: "Unid 3", pagina: 51 },
    { numero: 10, descricao: "Unid 3 – pág 58", unidade: "Unid 3", pagina: 58 },
    { numero: 11, descricao: "Unid 3 – pág 63 (guiar o wkbk p/ próxima aula)", unidade: "Unid 3", pagina: 63 },
    { numero: 12, descricao: "Wkbk 3 – teste 3", unidade: "Wkbk 3", pagina: 0 },
    { numero: 13, descricao: "Unid 4 – pág 70", unidade: "Unid 4", pagina: 70 },
    { numero: 14, descricao: "Unid 4 – pág 78", unidade: "Unid 4", pagina: 78 },
    { numero: 15, descricao: "Unid 4 – pág 84 (guiar o wkbk p/ próxima aula)", unidade: "Unid 4", pagina: 84 },
    { numero: 16, descricao: "Wkbk 4 – teste 4", unidade: "Wkbk 4", pagina: 0 },
    { numero: 17, descricao: "Unid 5 – pág 91", unidade: "Unid 5", pagina: 91 },
    { numero: 18, descricao: "Unid 5 – pág 98", unidade: "Unid 5", pagina: 98 },
    { numero: 19, descricao: "Unid 5 – pág 106 (guiar o wkbk p/ próxima aula)", unidade: "Unid 5", pagina: 106 },
    { numero: 20, descricao: "Wkbk 5 – teste 5", unidade: "Wkbk 5", pagina: 0 },
    { numero: 21, descricao: "Unid 6 – pág 112 (trabalhar bem a pronuncia do “ed” nessa aula)", unidade: "Unid 6", pagina: 112 },
    { numero: 22, descricao: "Unid 6 – pág 118", unidade: "Unid 6", pagina: 118 },
    { numero: 23, descricao: "Unid 6 – pág 125 (guiar o wkbk p/ próxima aula)", unidade: "Unid 6", pagina: 125 },
    { numero: 24, descricao: "Wkbk 6 – teste 6", unidade: "Wkbk 6", pagina: 0 },
    { numero: 25, descricao: "Unid 7 – pág 131", unidade: "Unid 7", pagina: 131 },
    { numero: 26, descricao: "Unid 7 – pág 137", unidade: "Unid 7", pagina: 137 },
    { numero: 27, descricao: "Unid 7 – pág 143 (guiar o wkbk p/ próxima aula)", unidade: "Unid 7", pagina: 143 },
    { numero: 28, descricao: "Wkbk 7 – teste 7", unidade: "Wkbk 7", pagina: 0 },
    { numero: 29, descricao: "Unid 8 – pág 149", unidade: "Unid 8", pagina: 149 },
    { numero: 30, descricao: "Unid 8 – pág 158", unidade: "Unid 8", pagina: 158 },
    { numero: 31, descricao: "Unid 8 – pág 163 (guiar o wkbk p/ próxima aula)", unidade: "Unid 8", pagina: 163 },
    { numero: 32, descricao: "Wkbk 8 – teste 8", unidade: "Wkbk 8", pagina: 0 },
    { numero: 33, descricao: "Unid 9 – pág 168", unidade: "Unid 9", pagina: 168 },
    { numero: 34, descricao: "Unid 9 – pág 174", unidade: "Unid 9", pagina: 174 },
    { numero: 35, descricao: "Unid 9 – pág 179 (guiar o wkbk p/ próxima aula)", unidade: "Unid 9", pagina: 179 },
    { numero: 36, descricao: "Wkbk 9 – teste 9", unidade: "Wkbk 9", pagina: 0 },
    { numero: 37, descricao: "Unid 10 – pág 186", unidade: "Unid 10", pagina: 186 },
    { numero: 38, descricao: "Unid 10 – pág 193", unidade: "Unid 10", pagina: 193 },
    { numero: 39, descricao: "Unid 10 – pág 198 (guiar o wkbk p/ próxima aula)", unidade: "Unid 10", pagina: 198 },
    { numero: 40, descricao: "Wkbk 10 – teste 10. Considerações finais", unidade: "Wkbk 10", pagina: 0 }
  ]
};

export { book2 };
