import { Modal, Button, Card } from "react-bootstrap";
import { useEffect } from "react";

const VisualizarTurmaModal = ({ turmaData, closeModal, alunosFiltrados }) => {
  useEffect(() => {
    // Garante que o console.log seja executado sempre que o componente for renderizado e alunosFiltrados mudar
    console.log("AlunosFiltrados no Modal:", alunosFiltrados);
  }, [alunosFiltrados]); // Corrigido: removeu a condicional

  if (!turmaData) return null; 

  return (
    <Modal show onHide={closeModal} style={{ zIndex: 1050 }}>
      <Modal.Header closeButton>
        <Modal.Title>Detalhes da Turma</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            <Card.Title>{turmaData.cp_tr_nome}</Card.Title>
            <Card.Text>
              <strong>Data de Início:</strong> {turmaData.cp_tr_data}
            </Card.Text>
            <Card.Text>
              <strong>Professor:</strong> {turmaData.nomeDoProfessor}
            </Card.Text>
            <Card.Text>
              <strong>Escola:</strong> {turmaData.nomeDaEscola}
            </Card.Text>
            <Card.Text>
              <strong>Curso:</strong> {turmaData.cursoNome}
            </Card.Text>
            <div>
              <strong>Alunos da Turma:</strong>
              <ul>
                {Array.isArray(alunosFiltrados) && alunosFiltrados.map((aluno) => (
                  <li key={aluno.cp_id}>{aluno.cp_nome}</li>  
                ))}
              </ul>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VisualizarTurmaModal;
