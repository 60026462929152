import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./agenda.css";

const Agenda = () => {
  const [eventosMes, setEventosMes] = useState([]);
  const [mostrarMais, setMostrarMais] = useState(false);
  const quantidadeInicial = 4;
  const [quantidadeMostrada, setQuantidadeMostrada] =
    useState(quantidadeInicial);
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "long" });
  const year = today.getFullYear();

  // Lista de eventos manualmente adicionados
  const eventosManual = [
    { name: "Ano Novo", date: "2024-01-01T00:00:00Z" },
    { name: "Férias escolares", date: "2024-01-01T00:00:00Z" },
    { name: "Treinamento profes kids", date: "2024-01-29T00:00:00Z" },
    { name: "Matrículas", date: "2024-02-01T00:00:00Z" },
    { name: "Treinamento profes", date: "2024-02-01T00:00:00Z" },
    { name: "Carnaval", date: "2024-02-12T00:00:00Z" },
    { name: "Quaresma", date: "2024-02-14T00:00:00Z" },
    { name: "Valentine’s Day", date: "2024-02-14T00:00:00Z" },
    { name: "Aniversário CIPEX", date: "2024-02-15T00:00:00Z" },
    { name: "Reunião pedagógica", date: "2024-02-17T00:00:00Z" },
    { name: "Início do ano letivo", date: "2024-02-19T00:00:00Z" },
    { name: "Jornada pedagógica", date: "2024-02-24T00:00:00Z" },
    { name: "Dia dos povos indígenas", date: "2024-04-19T00:00:00Z" },
    { name: "Reunião pedagógica", date: "2024-04-20T00:00:00Z" },
    { name: "Tiradentes", date: "2024-04-24T00:00:00Z" },
    { name: "Descobrimento do Brasil", date: "2024-04-22T00:00:00Z" },
    { name: "Dia mundial do livro", date: "2024-04-23T00:00:00Z" },
    { name: "Palestra intercâmbio", date: "2024-04-25T00:00:00Z" },
    { name: "Dia do trabalho", date: "2024-05-01T00:00:00Z" },
    { name: "Dia das mães", date: "2024-05-12T00:00:00Z" },
    { name: "Reunião pedagógica", date: "2024-05-18T00:00:00Z" },
    { name: "Corpus Christi", date: "2024-05-30T00:00:00Z" },
    { name: "Dia mundial do meio ambiente", date: "2024-06-05T00:00:00Z" },
    { name: "Dia dos namorados", date: "2024-06-12T00:00:00Z" },
    { name: "Início do inverno", date: "2024-06-20T00:00:00Z" },
    { name: "São João", date: "2024-06-24T00:00:00Z" },
    { name: "Matrículas e rematrículas", date: "2024-07-01T00:00:00Z" },
    { name: "Independence Day USA", date: "2024-07-04T00:00:00Z" },
    { name: "Dia do amigo", date: "2024-07-20T00:00:00Z" },
    { name: "Encerramento 1° semestre letivo", date: "2024-07-20T00:00:00Z" },
    { name: "Férias escolares", date: "2024-07-22T00:00:00Z" },
    { name: "Treinamentos profes", date: "2024-07-22T00:00:00Z" },
    { name: "Jornada pedagógica", date: "2024-07-27T00:00:00Z" },
    { name: "Treinamentos profes", date: "2024-07-29T00:00:00Z" },
    {
      name: "Início das aulas 2 semestre letivo",
      date: "2024-08-05T00:00:00Z",
    },
    { name: "Dia dos pais", date: "2024-08-11T00:00:00Z" },
    { name: "Dia do estudante", date: "2024-08-11T00:00:00Z" },
    { name: "Reunião pedagógica", date: "2024-08-17T00:00:00Z" },
    { name: "Dia do folclore", date: "2024-08-22T00:00:00Z" },
    { name: "Independência do Brasil", date: "2024-09-07T00:00:00Z" },
    { name: "Reunião pedagógica", date: "2024-09-14T00:00:00Z" },
    { name: "Revolução Farroupilha", date: "2024-09-20T00:00:00Z" },
    { name: "Dia da árvore", date: "2024-09-21T00:00:00Z" },
    { name: "Início da primavera", date: "2024-09-22T00:00:00Z" },
    { name: "Dia das crianças", date: "2024-10-12T00:00:00Z" },
    { name: "Dia do professor", date: "2024-10-15T00:00:00Z" },
    { name: "Oktoberfest", date: "2024-10-26T00:00:00Z" },
    { name: "Halloween / Dia do Saci", date: "2024-10-31T00:00:00Z" },
    { name: "Finados", date: "2024-11-02T00:00:00Z" },
    { name: "Proclamação da república", date: "2024-11-15T00:00:00Z" },
    { name: "Dia nacional da consciência negra", date: "2024-11-20T00:00:00Z" },
    {
      name: "Encerramento das aulas 2° semestre",
      date: "2024-12-20T00:00:00Z",
    },
    {
      name: "Entrega de certificados e boletins",
      date: "2024-12-20T00:00:00Z",
    },
    { name: "Encerramento do ano letivo", date: "2024-12-21T00:00:00Z" },
    { name: "Natal", date: "2024-12-25T00:00:00Z" },
    { name: "Véspera de Ano Novo", date: "2024-12-31T00:00:00Z" },
  ];

  useEffect(() => {
    const eventosDoMes = eventosManual.filter((evento) => {
      const eventDate = new Date(evento.date);
      return (
        eventDate.getFullYear() === year &&
        eventDate.getMonth() === today.getMonth()
      );
    });

    setEventosMes(eventosDoMes);
  }, []);

  const renderEventos = () => {
    const eventosAgrupadosPorDia = eventosMes.reduce((acc, evento) => {
      const eventDate = new Date(evento.date);
      const diaLocal = eventDate.getUTCDate();
      if (!acc[diaLocal]) {
        acc[diaLocal] = [];
      }
      acc[diaLocal].push(evento.name);
      return acc;
    }, {});

    const diasComEventos = Object.keys(eventosAgrupadosPorDia);

    const eventosParaMostrar = mostrarMais
      ? diasComEventos
      : diasComEventos.slice(0, quantidadeMostrada);

    return eventosParaMostrar.map((dia) =>
      eventosAgrupadosPorDia[dia].map((evento, index) => (
        <Row key={`${dia}-${index}`} className="event-row">
          <Col xs={12} sm={2} className="day-col">
            <div className={`day-box ${parseInt(dia) === day ? "today" : ""}`}>
              {dia}
            </div>
          </Col>
          <Col xs={12} sm={10} className="event-col">
            <p className="event-name">{evento}</p>
          </Col>
        </Row>
      ))
    );
  };

  const mostrarBotao = eventosMes.length > quantidadeInicial;

  return (
    <div className="agenda-container ">
      <Row className="agenda-header justify-content-center">
        <Col
          md={12}
          className="text-center d-flex w-100 justify-content-center"
        >
          <div className="agenda-today text-center w-100">
            <h1>{day}</h1>
            <h3>{month}</h3>
          </div>
        </Col>
      </Row>
      <Row className="events-list justify-content-center">
        {renderEventos()}
      </Row>
      {mostrarBotao && (
        <Row md={12} className="justify-content-center d-flex w-100">
          <Col style={{marginLeft:"10px"}} className="text-center">
            <Button
              variant="primary"
              onClick={() => {
                if (!mostrarMais) {
                  setQuantidadeMostrada(eventosMes.length);
                } else {
                  setQuantidadeMostrada(quantidadeInicial);
                }
                setMostrarMais(!mostrarMais);
              }}
            >
              {mostrarMais ? "Ver Menos" : "Ver Mais"}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Agenda;
