import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CadastroModal.css"; // Importe o arquivo CSS
import InputMask from "react-input-mask";
import { API_BASE_URL } from "./config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Row, Col, Button } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";


const CadastroUsuarioModal = ({ closeModal, escolas = [] }) => {
  const [userData, setUserData] = useState({
    cp_nome: "",
    cp_email: "",
    cp_login: "",
    cp_password: "",
    cp_tipo_user: "",
    cp_rg: "",
    cp_cpf: "",
    cp_datanascimento: "",
    cp_estadocivil: "",
    cp_cnpj: "",
    cp_ie: "",
    cp_whatsapp: "",
    cp_telefone: "",
    cp_empresaatuacao: "",
    cp_profissao: "",
    cp_end_cidade_estado: "",
    cp_end_rua: "",
    cp_end_num: "",
    cp_end_cep: "",
    cp_descricao: "",
    cp_escola_id: "",
    cp_foto_perfil: null,
  });
  // const [errorMessage, setErrorMessage] = useState('');
  // const [successMessage, setSuccessMessage] = useState('');
  const [isEmpresa, setIsEmpresa] = useState(false);
  const [userType, setUserType] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");


  useEffect(() => {
    const userTypeFromStorage = localStorage.getItem("userType");
    setUserType(parseInt(userTypeFromStorage, 10));
  }, []);

  const getFilteredOptions = () => {
    const options = [
      { value: "1", label: "Gestor" },
      { value: "2", label: "Diretor" },
      { value: "3", label: "Secretária" },
      { value: "4", label: "Professor" },
      { value: "5", label: "Aluno" },
    ];

    return options.filter((option) => parseInt(option.value, 10) >= userType);
  };

  // Adicione esta verificação antes de enviar o formulário:
  // if (userData.cp_password !== confirmPassword) {
  //   toast.error("As senhas não coincidem.");
  //   return;
  // }

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "cp_foto_perfil") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: files[0], // Armazena o arquivo de foto de perfil
      }));
    } else {
      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("cp_foto_perfil", userData.cp_foto_perfil);

      // Adicione os demais campos do usuário ao formData
      Object.keys(userData).forEach((key) => {
        if (key !== "cp_foto_perfil") {
          formData.append(key, userData[key]);
        }
      });

      const response = await axios.post(`${API_BASE_URL}/register`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.exists) {
        toast.error("Usuário já cadastrado");
      } else {
        toast.success("Usuário cadastrado com sucesso");
        closeModal();
      }
    } catch (error) {
      console.error(
        "Erro ao realizar a solicitação:",
        error.response ? error.response.data : error.message
      );
      toast.error(
        `Erro ao realizar a solicitação: ${error.response ? error.response.data : error.message
        }`
      );
    }
  };

  return (
    <div>
      <ToastContainer />
      <h2>Adicionar Usuário</h2>
      <form className="form-container-cad" onSubmit={handleSubmit}>
        <Row>
          <Col md={4}>
            <label htmlFor="cp_nome">
              Nome<span className="required">*</span>:
            </label>
            <input
              type="text"
              id="cp_nome"
              name="cp_nome"
              value={userData.cp_nome}
              onChange={handleChange}
              className="input-field"
              placeholder="Nome"
              required
            />
          </Col>

          <Col md={4}>
            <label htmlFor="cp_email">
              E-mail<span className="required">*</span>:
            </label>
            <input
              type="email"
              id="cp_email"
              name="cp_email"
              value={userData.cp_email}
              onChange={handleChange}
              className="input-field"
              placeholder="E-mail"
              required
            />
          </Col>
          <Col md={4}>
            <label htmlFor="cp_login">
              Login<span className="required">*</span>:
            </label>
            <input
              type="text"
              id="cp_login"
              name="cp_login"
              value={userData.cp_login}
              onChange={handleChange}
              className="input-field"
              placeholder="Login"
              required
            />
          </Col>

          <Col md={4}>
            <label htmlFor="cp_password">
              Senha<span className="required">*</span>:
            </label>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                id="cp_password"
                name="cp_password"
                value={userData.cp_password}
                onChange={handleChange}
                className="input-field"
                placeholder="Senha"
                required
                style={{ flex: 1 }}
              />
              <Button
                variant="secondary"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'absolute',
                  right: '15px',  
                  top: '50%',
                  transform: 'translateY(-50%)',
                  marginTop: '-5px',  
                  marginLeft: '-10px',  
                  padding: '5px',
                  borderRadius: '50%',  
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </Button>
            </div>
          </Col>

          {/* <Col md={4}>
            <label htmlFor="confirm_password">
              Confirmar Senha<span className="required">*</span>:
            </label>
            <input
              type="password"
              id="confirm_password"
              name="confirm_password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="input-field"
              placeholder="Confirmar Senha"
              required
            />
          </Col> */}

          <div className="form-group">
            <label htmlFor="cp_tipo_user">
              Tipo de Usuário<span className="required">*</span>:
            </label>
            <select
              id="cp_tipo_user"
              name="cp_tipo_user"
              value={userData.cp_tipo_user}
              onChange={handleChange}
              className="input-field"
              required
            >
              <option value="">Selecione o tipo de usuário</option>
              {getFilteredOptions().map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <Col md={4}>
            <label htmlFor="cp_rg">RG:</label>
            <input
              type="text"
              id="cp_rg"
              name="cp_rg"
              value={userData.cp_rg}
              onChange={handleChange}
              className="input-field"
              placeholder="RG"
            // required
            />
          </Col>

          <Col md={4}>
            <label htmlFor="cp_cpf">
              CPF<span className="required">*</span>:
            </label>
            <InputMask
              type="text"
              id="cp_cpf"
              name="cp_cpf"
              mask="999.999.999-99"
              value={userData.cp_cpf}
              onChange={handleChange}
              className="input-field"
              placeholder="CPF"
              required
            />
          </Col>

          <Col md={4}>
            <label htmlFor="cp_datanascimento">
              Data de Nascimento<span className="required">*</span>:
            </label>
            <input
              type="date"
              id="cp_datanascimento"
              name="cp_datanascimento"
              value={userData.cp_datanascimento}
              onChange={handleChange}
              className="input-field"
              required
            />
          </Col>
          <Col md={4}>
            <label htmlFor="cp_whatsapp">WhatsApp:</label>
            <InputMask
              type="text"
              id="cp_whatsapp"
              name="cp_whatsapp"
              // as={InputMask}
              mask="(99)99999-9999"
              value={userData.cp_whatsapp}
              onChange={handleChange}
              className="input-field"
              placeholder="WhatsApp"
            />
          </Col>

          <Col md={4}>
            <label htmlFor="cp_telefone">Telefone:</label>
            <InputMask
              type="text"
              id="cp_telefone"
              name="cp_telefone"
              mask="(99)99999-9999"
              value={userData.cp_telefone}
              onChange={handleChange}
              className="input-field"
              placeholder="Telefone"
            />
          </Col>
          <Col md={4}>
            <label htmlFor="cp_estadocivil">Estado Civil:</label>
            <input
              type="text"
              id="cp_estadocivil"
              name="cp_estadocivil"
              value={userData.cp_estadocivil}
              onChange={handleChange}
              className="input-field"
              placeholder="Estado Civil"
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label>
              <input
                type="checkbox"
                checked={isEmpresa}
                onChange={(e) => setIsEmpresa(e.target.checked)}
                className="input-checkbox styled-checkbox"
              />
              <span className="span-check">Cadastro de Empresa</span>
            </label>
          </Col>
          {isEmpresa && (
            <>
              <Col md={4}>
                <label htmlFor="cp_cnpj">CNPJ:</label>
                <InputMask
                  type="text"
                  id="cp_cnpj"
                  name="cp_cnpj"
                  mask="99.999.999/9999-99"
                  value={userData.cp_cnpj}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="CNPJ"
                />
              </Col>

              <Col md={4}>
                <label htmlFor="cp_ie">IE:</label>
                <input
                  type="text"
                  id="cp_ie"
                  name="cp_ie"
                  value={userData.cp_ie}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="IE"
                />
              </Col>

              <Col md={4}>
                <label htmlFor="cp_empresaatuacao">Empresa de Atuação:</label>
                <input
                  type="text"
                  id="cp_empresaatuacao"
                  name="cp_empresaatuacao"
                  value={userData.cp_empresaatuacao}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="Empresa de Atuação"
                />
              </Col>

              <Col md={4}>
                <label htmlFor="cp_profissao">Profissão:</label>
                <input
                  type="text"
                  id="cp_profissao"
                  name="cp_profissao"
                  value={userData.cp_profissao}
                  onChange={handleChange}
                  className="input-field"
                  placeholder="Profissão"
                />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col md={4}>
            <label htmlFor="cp_end_cidade_estado">Cidade e Estado:</label>
            <input
              type="text"
              id="cp_end_cidade_estado"
              name="cp_end_cidade_estado"
              value={userData.cp_end_cidade_estado}
              onChange={handleChange}
              className="input-field"
              placeholder="Cidade e Estado"
            />
          </Col>

          <Col md={4}>
            <label htmlFor="cp_end_rua">Rua:</label>
            <input
              type="text"
              id="cp_end_rua"
              name="cp_end_rua"
              value={userData.cp_end_rua}
              onChange={handleChange}
              className="input-field"
              placeholder="Rua"
            />
          </Col>

          <Col md={4}>
            <label htmlFor="cp_end_num">Número:</label>
            <input
              type="text"
              id="cp_end_num"
              name="cp_end_num"
              value={userData.cp_end_num}
              onChange={handleChange}
              className="input-field"
              placeholder="Número"
            />
          </Col>

          <Col md={4}>
            <label htmlFor="cp_end_cep">CEP:</label>
            <input
              type="text"
              id="cp_end_cep"
              name="cp_end_cep"
              value={userData.cp_end_cep}
              onChange={handleChange}
              className="input-field"
              placeholder="CEP"
            />
          </Col>

          <Col md={4}>
            <label htmlFor="cp_descricao">Descrição:</label>
            <input
              type="text"
              id="cp_descricao"
              name="cp_descricao"
              value={userData.cp_descricao}
              onChange={handleChange}
              className="input-field"
              placeholder="Descrição"
            />
          </Col>
          <Col md={4}>
            <label htmlFor="cp_foto_perfil">Foto de Perfil:</label>
            <input
              type="file"
              id="cp_foto_perfil"
              name="cp_foto_perfil"
              onChange={handleChange}
              className="input-field"
              accept="image/*" // Permite somente arquivos de imagem
            />
          </Col>
          <Col md={4}>
            <label htmlFor="cp_escola_id">Escola<span className="required">*</span>:</label>
            <select
              id="cp_escola_id"
              name="cp_escola_id"
              value={userData.cp_escola_id}
              onChange={handleChange}
              required
              className="input-field"
            >
              <option value="">Selecione a escola</option>
              {escolas.length > 0 ? (
                escolas.map((escola) => (
                  <option key={escola.cp_ec_id} value={escola.cp_ec_id}>
                    {escola.cp_ec_nome}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  Carregando escolas...
                </option>
              )}
            </select>
          </Col>
        </Row>

        <div className="form-group-2">
          <button type="submit" className="submit-button">
            Cadastrar Usuário
          </button>
        </div>
      </form>
    </div>
  );
};

export default CadastroUsuarioModal;
