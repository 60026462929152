import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CadastroModal.css";
import { API_BASE_URL } from "./config";
import { FaSearch } from "react-icons/fa";
import { Form, Button, Row, Col, Table, Container } from "react-bootstrap";

const CadastroTurmaModal = ({ closeModal, isEdit, turmaDataToEdit }) => {
  const [turmaData, setTurmaData] = useState({
    cp_tr_nome: "",
    cp_tr_data: "",
    cp_tr_id_professor: "",
    cp_tr_id_escola: "",
    cp_tr_alunos: [],
    cp_tr_curso_id: "",
  });

  const [professores, setProfessores] = useState([]);
  const [escolas, setEscolas] = useState([]);
  const [alunosPorEscola, setAlunosPorEscola] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [alunosFiltrados, setAlunosFiltrados] = useState([]);


  useEffect(() => {
    fetchProfessores();
    fetchEscolas();
    fetchCursos();
  }, []);

  useEffect(() => {
    if (turmaData.cp_tr_id_escola) {
      fetchAlunosPorEscola(turmaData.cp_tr_id_escola);
    }
  }, [turmaData.cp_tr_id_escola]);


  useEffect(() => {
    if (isEdit && turmaDataToEdit) {
      const formattedDate = new Date(turmaDataToEdit.cp_tr_data)
        .toISOString()
        .split("T")[0];
      setTurmaData({
        ...turmaDataToEdit,
        cp_tr_data: formattedDate,
        cp_tr_alunos: Array.isArray(turmaDataToEdit.cp_tr_alunos) ?
          turmaDataToEdit.cp_tr_alunos.map((aluno) => aluno.cp_id) : []
      });
      // Filtrar os alunos que pertencem à turma
      if (alunosPorEscola.length > 0) {
        const alunosDaTurma = alunosPorEscola.filter(aluno => aluno.cp_turma_id === turmaDataToEdit.cp_tr_id);
        setAlunosFiltrados(alunosDaTurma);
      }
    }
  }, [isEdit, turmaDataToEdit, alunosPorEscola]);


  const fetchProfessores = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users-professores`);
      setProfessores(response.data);
    } catch (error) {
      console.error("Erro ao buscar os professores:", error);
    }
  };

  const fetchEscolas = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/escolas`);
      setEscolas(response.data);
    } catch (error) {
      console.error("Erro ao buscar as escolas:", error);
    }
  };

  // const fetchAlunosPorEscola = async (escolaId) => {
  //   try {
  //     const response = await axios.get(
  //       `${API_BASE_URL}/escola/alunos/${escolaId}`
  //     );
  //     setAlunosPorEscola(response.data);

  //   } catch (error) {
  //     console.error("Erro ao buscar os alunos da escola:", error);
  //   }
  // };

  const fetchAlunosPorEscola = async (escolaId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/escola/alunos/${escolaId}`);
      const alunos = response.data;
      setAlunosPorEscola(alunos);

      // Se estiver editando, filtrar os alunos que pertencem à turma
      if (isEdit && turmaDataToEdit) {
        const alunosDaTurma = alunos.filter(aluno => aluno.cp_turma_id === turmaDataToEdit.cp_tr_id);
        setAlunosFiltrados(alunosDaTurma);
      } else {
        setAlunosFiltrados(alunos);
      }
    } catch (error) {
      console.error("Erro ao buscar os alunos da escola:", error);
    }
  };


  const fetchCursos = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/cursos`);
      setCursos(response.data);
    } catch (error) {
      console.error("Erro ao buscar os cursos:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "cp_tr_alunos") {
      const selectedValues = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setTurmaData((prevTurmaData) => ({
        ...prevTurmaData,
        [name]: selectedValues,
      }));
    } else if (name === "cp_tr_id_escola") {
      // setTurmaData((prevTurmaData) => ({ ...prevTurmaData, [name]: value, cp_tr_alunos: [] }));
      setTurmaData((prevTurmaData) => ({ ...prevTurmaData, [name]: value }));
      fetchAlunosPorEscola(value);
    } else {
      setTurmaData((prevTurmaData) => ({ ...prevTurmaData, [name]: value }));
    }
  };

  // const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     fetchAlunosPorEscola(value);
  //     setTurmaData((prevTurmaData) => ({ ...prevTurmaData, [name]: value }));
  // };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (e, alunoId) => {
    const isChecked = e.target.checked;
    setTurmaData((prevData) => {
      const updatedAlunos = isChecked
        ? [...prevData.cp_tr_alunos, alunoId]
        : prevData.cp_tr_alunos.filter((id) => id !== alunoId);

      return { ...prevData, cp_tr_alunos: updatedAlunos };
    });
  };

  const filteredAlunos = alunosFiltrados.filter((aluno) =>
    aluno.cp_nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     // Realiza a chamada para o backend para cadastrar a turma
  //     const response = await axios.post(
  //       `${API_BASE_URL}/register-turma`,
  //       turmaData
  //     );
  //     console.log("Resposta do servidor ao cadastrar a turma:", response.data);

  //     setSuccessMessage("Operação realizada com sucesso");
  //     setErrorMessage("");
  //     closeModal();
  //   } catch (error) {
  //     setErrorMessage("Erro ao realizar a solicitação");
  //     setSuccessMessage("");
  //     console.error("Erro durante o processamento:", error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Filtrar apenas os alunos marcados para enviar ao backend
      const alunosSelecionados = turmaData.cp_tr_alunos.map((alunoId) =>
        filteredAlunos.find((aluno) => aluno.cp_id === alunoId)
      );

      // Atualizar turmaData para enviar apenas os IDs dos alunos
      const dataToSend = {
        ...turmaData,
        cp_tr_alunos: alunosSelecionados.map((aluno) => aluno.cp_id),
      };

      // Realiza a chamada para o backend para cadastrar a turma
      const response = await axios.post(`${API_BASE_URL}/register-turma`, dataToSend);
      console.log("Resposta do servidor ao cadastrar a turma:", response.data);

      setSuccessMessage("Operação realizada com sucesso");
      setErrorMessage("");
      closeModal();
    } catch (error) {
      setErrorMessage("Erro ao realizar a solicitação");
      setSuccessMessage("");
      console.error("Erro durante o processamento:", error);
    }
  };


  return (
    <Container>
      <Form onSubmit={handleSubmit} className="form-container-cad">
        <Row className="w-100">
          <Col md={4}>
            <Form.Group>
              <Form.Label htmlFor="cp_tr_nome">Nome:</Form.Label>
              <Form.Control
                type="text"
                id="cp_tr_nome"
                name="cp_tr_nome"
                value={turmaData.cp_tr_nome}
                onChange={handleChange}
                placeholder="Nome"
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label htmlFor="cp_tr_data">Data:</Form.Label>
              <Form.Control
                type="date"
                id="cp_tr_data"
                name="cp_tr_data"
                value={turmaData.cp_tr_data}
                onChange={handleChange}
                placeholder="Data"
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label htmlFor="cp_tr_id_professor">Professor:</Form.Label>
              <Form.Control
                as="select"
                id="cp_tr_id_professor"
                name="cp_tr_id_professor"
                value={turmaData.cp_tr_id_professor}
                onChange={handleChange}
                required
              >
                <option value="">Selecione o professor</option>
                {localStorage.getItem("userType") === "4"
                  ? // Se o usuário for um professor, filtre os professores pelo nome do professor logado
                  professores
                    .filter(
                      (professor) =>
                        professor.cp_nome === localStorage.getItem("userName")
                    )
                    .map((professor) => (
                      <option key={professor.cp_id} value={professor.cp_id}>
                        {professor.cp_nome}
                      </option>
                    ))
                  : // Se o usuário não for um professor, exiba todos os professores
                  professores.map((professor) => (
                    <option key={professor.cp_id} value={professor.cp_id}>
                      {professor.cp_nome}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row className="w-100">
          <Col md={6} xs={12}>
            <Form.Group>
              <Form.Label htmlFor="cp_tr_id_escola">Escola:</Form.Label>
              <Form.Control
                as="select"
                id="cp_tr_id_escola"
                name="cp_tr_id_escola"
                value={turmaData.cp_tr_id_escola}
                onChange={handleChange}
                required
              >
                <option value="">Selecione a escola</option>
                {escolas.map((escola) => (
                  <option key={escola.cp_ec_id} value={escola.cp_ec_id}>
                    {escola.cp_ec_nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6} xs={12}>
            <Form.Group>
              <Form.Label htmlFor="cp_tr_curso_id">Curso:</Form.Label>
              <Form.Control
                as="select"
                id="cp_tr_curso_id"
                name="cp_tr_curso_id"
                value={turmaData.cp_tr_curso_id}
                onChange={handleChange}
                required
              >
                <option value="">Selecione o curso</option>
                {cursos.map((curso) => (
                  <option key={curso.cp_curso_id} value={curso.cp_curso_id}>
                    {curso.cp_nome_curso}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row className="w-100">
          <Col md={12} xs={12}>
            <Form.Group className="search-group">
              <Form.Label htmlFor="search">Buscar Aluno:</Form.Label>
              <div className="search-input-wrapper">
                <Form.Control
                  type="text"
                  id="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Buscar aluno..."
                />
                <FaSearch className="search-icon" />
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="w-100">
          <Col md={12} xs={12}>
            <Form.Group>
              <Form.Label>Alunos:</Form.Label>
              <div className="alunos-container">
                {filteredAlunos.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nome</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredAlunos.map((aluno) => (
                        <tr key={aluno.cp_id}>
                          <td>
                            <Form.Check
                              type="switch"
                              checked={turmaData.cp_tr_alunos.includes(aluno.cp_id)}
                              onChange={(e) => handleCheckboxChange(e, aluno.cp_id)}
                            />
                          </td>
                          <td>{aluno.cp_nome}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>Selecione uma escola!</p>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="w-100">
          <Col>
            <Button className="submit-button" type="submit">
              {isEdit ? "Salvar Alterações" : "Cadastrar Turma"}
            </Button>
          </Col>
        </Row>
      </Form>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
    </Container>
  );
};

export default CadastroTurmaModal;