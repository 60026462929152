import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { API_BASE_URL } from "./config";
import { FaLink, FaYoutube, FaFilePdf } from "react-icons/fa";

const SaladeAulaAluno = () => {
  const [audios, setAudios] = useState([]);
  const [materiais, setMateriais] = useState([]);
  const [turmaId, setTurmaId] = useState(localStorage.getItem("turmaID"));
  const [audioStatus, setAudioStatus] = useState({});
  const [showPDF, setShowPDF] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loadingAudios, setLoadingAudios] = useState(true);
  const [showPrimeiraUnidade, setShowPrimeiraUnidade] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [pdfs, setPdfs] = useState([]);

  const handleShowPrimeiraUnidade = (youtubeLink, pdfLinks) => {
    // Verifica se o link é um link normal e o converte para um link de incorporação
    const embedUrl = youtubeLink.includes("youtube.com/watch")
      ? youtubeLink.replace("watch?v=", "embed/")
      : youtubeLink;

    setYoutubeUrl(embedUrl);
    setPdfs(pdfLinks);
    setShowPrimeiraUnidade(true);
  };

  const handleClosePrimeiraUnidade = () => {
    setShowPrimeiraUnidade(false);
    setYoutubeUrl("");
    setPdfs([]);
  };

  const handleShowPDF = (url) => {
    setPdfUrl(url);
    setShowPDF(true);
  };

  const handleClosePDF = () => {
    setShowPDF(false);
    setPdfUrl("");
  };

  useEffect(() => {
    const fetchAudios = async () => {
      try {
        // Obter o cp_tr_curso_id usando o turmaId
        const cursoResponse = await axios.get(
          `${API_BASE_URL}/curso-id-da-turma/${turmaId}`
        );
        const cursoId = cursoResponse.data.cp_tr_curso_id;

        // Obter os áudios usando o cp_tr_curso_id
        const audioResponse = await axios.get(
          `${API_BASE_URL}/audios-curso/${cursoId}`
        );
        setAudios(audioResponse.data);

        // Buscar status dos áudios marcados como ouvidos pelo usuário
        const userId = localStorage.getItem("userId");
        if (userId) {
          const statusResponse = await axios.get(
            `${API_BASE_URL}/audios-marcados/${userId}`
          );
          const audiosMarcados = statusResponse.data.reduce((acc, audioId) => {
            acc[audioId] = true;
            return acc;
          }, {});
          setAudioStatus(audiosMarcados);
        }
        setLoadingAudios(false);
      } catch (error) {
        console.error("Erro ao buscar os áudios:", error);
        setLoadingAudios(false);
      }
    };

    const fetchMateriais = async () => {
      try {
        // Obter o cp_tr_curso_id usando o turmaId
        const cursoResponse = await axios.get(
          `${API_BASE_URL}/curso-id-da-turma/${turmaId}`
        );
        const cursoId = cursoResponse.data.cp_tr_curso_id;

        // Buscar os materiais do curso usando o cursoId
        const response = await axios.get(
          `${API_BASE_URL}/materiais/${turmaId}`
        );
        setMateriais(response.data);

        // Busca o vídeo do YouTube e os PDFs do curso no backend usando o cursoId
        if (response.data.length > 0) {
          const cursoMaterialResponse = await axios.get(
            `${API_BASE_URL}/curso-material/${cursoId}`
          );
          const {
            cp_youtube_link_curso,
            cp_pdf1_curso,
            cp_pdf2_curso,
            cp_pdf3_curso,
          } = cursoMaterialResponse.data;

          // Define o link do vídeo e os PDFs no estado
          setYoutubeUrl(cp_youtube_link_curso);

          // Construindo as URLs corretas para os PDFs, removendo duplicidade se necessário
          const pdfLinks = [cp_pdf1_curso, cp_pdf2_curso, cp_pdf3_curso]
            .filter((pdf) => pdf && !pdf.includes("undefined"))
            .map((pdf) =>
              pdf.startsWith("https://")
                ? pdf
                : `https://testes.cursoviolaocristao.com.br${pdf}`
            );

          setPdfs(pdfLinks);
        }
      } catch (error) {
        console.error("Erro ao buscar os materiais:", error);
      }
    };

    fetchAudios();
    fetchMateriais();
  }, [turmaId]);

  const handleAudioPlay = async (audioId) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        // Registra a visualização do áudio
        await axios.post(`${API_BASE_URL}/registrar-visualizacao`, {
          userId,
          audioId,
        });
        console.log(
          `Áudio ${audioId} foi marcado como ouvido pelo usuário ${userId}.`
        );

        // Atualiza o estado local
        setAudioStatus((prevStatus) => ({
          ...prevStatus,
          [audioId]: true,
        }));
      } catch (error) {
        console.error("Erro ao registrar visualização de áudio:", error);
      }
    }
  };

  const handleCheckboxChange = async (audioId) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const isChecked = audioStatus[audioId];
        if (isChecked) {
          // Remove a visualização do áudio
          await axios.delete(`${API_BASE_URL}/registrar-visualizacao`, {
            data: { userId, audioId },
          });
          console.log(
            `Visualização do áudio ${audioId} removida para o usuário ${userId}.`
          );
        } else {
          // Registra a visualização do áudio
          await axios.post(`${API_BASE_URL}/registrar-visualizacao`, {
            userId,
            audioId,
          });
          console.log(
            `Áudio ${audioId} foi marcado como ouvido pelo usuário ${userId}.`
          );
        }
        // Atualiza o estado local
        setAudioStatus((prevStatus) => ({
          ...prevStatus,
          [audioId]: !isChecked,
        }));
      } catch (error) {
        console.error(
          "Erro ao registrar/remover visualização de áudio:",
          error
        );
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    return `${parseInt(hours)}:${minutes}`;
  };

  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <Button
            variant="warning"
            style={{ width: "100%", marginBottom: "10px" }}
            onClick={() => handleShowPrimeiraUnidade(youtubeUrl, pdfs)}
          >
            Visualizar Primeira Unidade
          </Button>

          <Card>
            <Card.Header>Áudios</Card.Header>
            <Card.Body>
              {loadingAudios ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                  </Spinner>
                </div>
              ) : (
                <ListGroup variant="flush">
                  {audios.map((audio) => (
                    <ListGroup.Item key={audio.cp_audio_id}>
                      <Row className="align-items-center">
                        <Col xs={8}>
                          <strong>{audio.cp_nome_audio}</strong>
                          <audio
                            controls
                            style={{ width: "100%", marginTop: "10px" }}
                            onPlay={() => handleAudioPlay(audio.cp_audio_id)}
                          >
                            <source
                              src={`${API_BASE_URL}${audio.cp_arquivo_audio}`}
                              type="audio/mpeg"
                            />
                            Seu navegador não suporta o elemento de áudio.
                          </audio>
                        </Col>
                        <Col xs={4} className="text-center mt-4">
                          <Form.Check
                            type="switch"
                            label="Ouvi este áudio"
                            id={`custom-switch-${audio.cp_audio_id}`}
                            checked={audioStatus[audio.cp_audio_id] || false}
                            onChange={() =>
                              handleCheckboxChange(audio.cp_audio_id)
                            }
                          />
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header>Materiais</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {materiais.map((material) => (
                  <ListGroup.Item key={material.cp_res_id}>
                    <div>
                      <strong>Aula {material.cp_res_aula}</strong>
                    </div>
                    <div>
                      {formatDate(material.cp_res_data)}{" "}
                      {formatTime(material.cp_res_hora)}
                    </div>
                    <div>
                      <p>{material.cp_res_resumo}</p>
                    </div>
                    <div className="d-flex mt-2">
                      {material.cp_res_link && (
                        <a
                          href={
                            material.cp_res_link.startsWith("http")
                              ? material.cp_res_link
                              : `https://${material.cp_res_link}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="me-3"
                        >
                          <FaLink size={20} />
                        </a>
                      )}
                      {material.cp_res_link_youtube && (
                        <a
                          href={
                            material.cp_res_link_youtube.startsWith("http")
                              ? material.cp_res_link_youtube
                              : `https://${material.cp_res_link_youtube}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaYoutube size={20} />
                        </a>
                      )}
                    </div>
                    {material.cp_res_arquivo && (
                      <Button
                        variant="primary"
                        onClick={() =>
                          handleShowPDF(
                            `https://testes.cursoviolaocristao.com.br${material.cp_res_arquivo}`
                          )
                        }
                        style={{ display: "block", marginTop: "10px" }}
                      >
                        <FaFilePdf style={{ marginRight: "5px" }} /> Ver
                        Material
                      </Button>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <Modal
        show={showPDF}
        onHide={handleClosePDF}
        centered
        fullscreen
        dialogClassName="modal-90w"
        className="custom-modal-size"
        style={{ zIndex: "1052" }} // Ajustado para garantir que este modal esteja sobre o modal da Primeira Unidade
      >
        <Modal.Header closeButton>
          <Modal.Title>Visualizar PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={`https://docs.google.com/viewer?url=${pdfUrl}&embedded=true&zoom=100`}
            width="100%"
            height="600px"
            className="custom-modal-iframe"
            style={{ border: "none" }}
            title="PDF Viewer"
          />
        </Modal.Body>
      </Modal> */}

      <Modal
        show={showPrimeiraUnidade}
        onHide={handleClosePrimeiraUnidade}
        centered
        fullscreen
        dialogClassName="modal-90w"
        className="custom-modal-size"
        style={{ zIndex: "1051" }} // Z-Index diferente para sobrepor
      >
        <Modal.Header closeButton>
          <Modal.Title>Visualizar Primeira Unidade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Vídeo do YouTube */}
          <div className="text-center">
            <iframe
              width="100%"
              height="400px"
              src={youtubeUrl}
              title="YouTube Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ marginBottom: "20px" }}
            ></iframe>
          </div>

          {/* Botões para PDFs */}
          <div className="d-flex justify-content-center flex-wrap mt-4">
            {pdfs.map((pdfUrl, index) => (
              <Button
                key={index}
                variant="primary"
                onClick={() => handleShowPDF(pdfUrl)} // Usa a função existente para abrir o PDF
                style={{ margin: "10px" }} // Ajustado para espaçamento uniforme
              >
                <FaFilePdf style={{ marginRight: "5px" }} /> Ver PDF {index + 1}
              </Button>
            ))}
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal para Visualização de PDF com Z-index maior para sobrepor */}
      <Modal
        show={showPDF}
        onHide={handleClosePDF}
        centered
        fullscreen
        dialogClassName="modal-90w"
        className="custom-modal-size"
        style={{ zIndex: "1052" }} // Z-Index ajustado para sobrepor o modal de Primeira Unidade
      >
        <Modal.Header closeButton>
          <Modal.Title>Visualizar PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={`https://docs.google.com/viewer?url=${pdfUrl}&embedded=true&zoom=100`}
            width="100%"
            height="600px"
            className="custom-modal-iframe"
            style={{ border: "none" }}
            title="PDF Viewer"
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default SaladeAulaAluno;
