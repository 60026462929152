import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaRegSadTear } from 'react-icons/fa';

const Pagina404 = () => {
  return (
    <Container fluid style={{ height: '100vh', backgroundColor: '#f0f4f8', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Row>
        <Col md={12} className="text-center">
          <div style={{ fontSize: '120px', fontWeight: 'bold', color: '#2f3b52' }}>404</div>
          <div style={{ fontSize: '24px', color: '#2f3b52', marginBottom: '20px' }}>Algo deu errado!</div>
          <FaRegSadTear style={{ fontSize: '50px', color: '#2f3b52' }} />
        </Col>
      </Row>
    </Container>
  );
};

export default Pagina404;
