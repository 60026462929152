import React, { useState } from "react";
import { Button, Modal, Form, Card, Row, Col } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { AiOutlineFile } from "react-icons/ai";
import ProvaDetalhada from "./ProvaDetalhada";

const Provas = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showProva, setShowProva] = useState(false);
  const [currentProva, setCurrentProva] = useState({
    id: "",
    nome: "",
    curso: "",
    turma: "",
  });
  const [provas, setProvas] = useState([
    { id: 1, nome: "Cipex Tweens Book 1", curso: "Inglês", turma: "" },
    { id: 2, nome: "Cipex Tweens Book 2", curso: "Inglês", turma: "" },
    { id: 3, nome: "Cipex Tweens Book 3", curso: "Inglês", turma: "" },
    { id: 4, nome: "Cipex Tweens Book 4", curso: "Inglês", turma: "" },
    { id: 5, nome: "Cipex Tweens Book 5", curso: "Inglês", turma: "" },
  ]);
  const [turmas] = useState(["Turma 1", "Turma 2", "Turma 3"]);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = (prova) => {
    setCurrentProva(prova);
    setShowEdit(true);
  };

  const handleCloseProva = () => setShowProva(false);
  const handleShowProva = (prova) => {
    setCurrentProva(prova);
    setShowProva(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentProva({ ...currentProva, [name]: value });
  };

  const handleSave = () => {
    setProvas(
      provas.map((prova) =>
        prova.id === currentProva.id ? currentProva : prova
      )
    );
    handleCloseEdit();
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Lista de Provas</h1>
      <Row className="w-100">
        {provas.map((prova) => (
          <Col md={4} key={prova.id}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>{prova.nome}</Card.Title>
                <Card.Text>{prova.curso}</Card.Text>
                <Button
                  variant="warning"
                  className="me-2"
                  onClick={() => handleShowEdit(prova)}
                  disabled
                >
                  <FaEdit /> Editar
                </Button>
                <Button variant="info" onClick={() => handleShowProva(prova)}>
                  <AiOutlineFile /> Abrir Prova
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal Editar Prova */}
      <Modal show={showEdit} onHide={handleCloseEdit} style={{ zIndex: 1050 }}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Prova</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNomeProva">
              <Form.Label>Nome da Prova</Form.Label>
              <Form.Control
                type="text"
                name="nome"
                value={currentProva.nome}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formCurso">
              <Form.Label>Curso</Form.Label>
              <Form.Control
                type="text"
                name="curso"
                value={currentProva.curso}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTurma">
              <Form.Label>Turma</Form.Label>
              <Form.Control
                as="select"
                name="turma"
                value={currentProva.turma}
                onChange={handleChange}
              >
                <option value="">Selecione a turma</option>
                {turmas.map((turma) => (
                  <option key={turma} value={turma}>
                    {turma}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Abrir Prova */}
      <Modal
        size="lg"
        show={showProva}
        onHide={handleCloseProva}
        style={{ zIndex: 1050 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Prova: {currentProva.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProvaDetalhada curso={currentProva.curso} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseProva}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Provas;
