const tweens2 = {
  titulo: "Tweens 2 (Anual)",
  aulas: [
    { numero: 1, descricao: "Páginas 01 e 02 - Introduce the topics. Set the scene. Present the dialogue. https://mundoeducacao.uol.com.br/educacao-fisica/futebol-2.htm Material extra na plataforma: Origem do esporte e o nome das posições dos jogadores em inglês. Páginas 03, 04 e 05 - Revisão do e does and what/where.", unidade: "", pagina: "" },
    { numero: 2, descricao: "Páginas 07, 08 e 09 - Revisar what/where and do e does. Grammar box (opcional). Página 10 - Introduce the dialogue. Culture tip: The biggest beach in Brazil. https://www.planetware.com/brazil/top-rated-beaches-in-brazil-bra-1-2.htm#:~:text=1. Copacabana Text=Brazil's%20most%20famous%20beach%20sits,heart%20of%20Rio%20de%20Janeiro. Practices on pages 10 and 11.", unidade: "", pagina: "" },
    { numero: 3, descricao: "Páginas 12 e 13 - Introduce the Reading and do the exercises. Bingo time. Motivate them to get more friends and followers as Arthur writes a message like that. Sugestão: bingos de multiplicação e divisão. Páginas 14, 15 e 16 - Do the exercises. Games, dictation, spelling and YouTube vídeos are a good way to vary the class.", unidade: "", pagina: "" },
    { numero: 4, descricao: "Página 17 - Practice the numbers and the right pronunciation. Introduce the dialogue. Páginas 19, 20 e 21 - Clock time. https://www.youtube.com/watch?v=fq2tRfHu5s8&t=172s https://www.youtube.com/watch?v=fq2tRfHu5s8&list=RDCMUC0kejkXg2LG-pFqAeWD_zLQ&start_radio=1&rv=fq2tRfHu5s8&t=191 Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 5, descricao: "Página 22 - Reading. Do it as usual. Exotic sports for teens. https://www.bestforteens.com/best-sports-teenagers Página 23 - Culture tip. Start the workbook. Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 6, descricao: "Correção do workbook e teste.", unidade: "", pagina: "" },
    { numero: 7, descricao: "Páginas 25 e 26 - Introduce the topics preferences, school days, life... Present the dialogue. Suggestion: make a schedule with Europe x USA x South Korea x Brazil after school activities. https://www.google.com/search?q=after+school+activities+in+south+korea&oq=after+school+activities+in+south+korea&aqs=chrome..69i57j33i160l2.12669j0j15&sourceid=chrome&ie=UTF-8 Páginas 27, 28 e 29 - Review how and who plus review do and does. Do the exercises and transfer.", unidade: "", pagina: "" },
    { numero: 8, descricao: "Páginas 30 e 31 - Exercises with 'who' and habits. Página 32 - Present reading. Read. Translate. Check comprehension and answer the questions. Suggestion: www.linguee.com.br", unidade: "", pagina: "" },
    { numero: 9, descricao: "Página 33 - Practice. Help them to transfer the text from I to he. Página 33 - Transfer. Help them to write a passage about them.", unidade: "", pagina: "" },
    { numero: 10, descricao: "Página 34 - Help them to transfer the passage from I to she or he. Página 34 - Introduce the dialogue as usual. Review what time and tell the time, numbers and different ways to say. Ex: quarter and half/to and past.", unidade: "", pagina: "" },
    { numero: 11, descricao: "Páginas 36 e 37 - Review do and does. Introducing the reading as usual. Páginas 38 e 39 - Translate the verbs. Do transfer giving them models how to write the way to describe daily routines. Practice: they list the things they do/don't do according to the exercise.", unidade: "", pagina: "" },
    { numero: 12, descricao: "Página 41 - Introduce the imperative case. Grammar box. Do the exercises. Páginas 42 e 43 - Do the exercises. Introduce the Reading and answer the questions about it.", unidade: "", pagina: "" },
    { numero: 13, descricao: "Página 44 - Culture tip. Start the workbook. Correct the workbook.", unidade: "", pagina: "" },
    { numero: 14, descricao: "Take the test.", unidade: "", pagina: "" },
    { numero: 15, descricao: "Páginas 46 e 47 - Set the scene. Introduce the dialogue and the modal 'can'. List the school subjects in Brazil. Aulas de religião ou religião têm que se adaptar para o catecismo. Make them search the school subjects in the USA, South Africa, Emirates and China. 01 student per country https://smapse.com/10-school-subjects-from-different-schools-of-the-world-that-surprise/ Páginas 47, 48 e 49 - Do the exercises as usual. Grammar box: ask a questions. They have to use the short answers in the box. Start practice on p. 49.", unidade: "", pagina: "" },
    { numero: 16, descricao: "Páginas 50 e 51 - Do the exercises as usual. Página 52 - Use the model to explain interrogative form. Review aff and neg short answers. Introduce object pronouns. Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 17, descricao: "Páginas 53 e 54 - Do exercises as much as you can. Do it as usual. Personal x object pronouns. https://www.youtube.com/watch?v=mrhyuhhuxrq Página 55 - Introduce reading. Do it as usual.", unidade: "", pagina: "" },
    { numero: 18, descricao: "Página 56 - Review object pronouns again. Do the exercise as usual. Páginas 57 e 58 - Introduce the dialogue as usual. Do practice as usual.", unidade: "", pagina: "" },
    { numero: 19, descricao: "Páginas 59, 60 e 61 - Set the scene: where and how. Do the exercises as usual. Páginas 62 e 63 - Set the scene: who. Transfer. Explore the grammar box. Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 20, descricao: "Páginas 64 e 65 - Introduce the Reading and do the exercises as usual. Introduce the dialogue as usual. Grammar box object pronouns. Stop and explain carefully. Provide many examples. O diálogo usa muito os pronomes adjetivos possessivos. Interessante revisar. Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 21, descricao: "Página 67 - Do the exercises as usual and very carefully. Página 68 - Introduce the days of the week. Do practice. Explain the model and do the exercises.", unidade: "", pagina: "" },
    { numero: 22, descricao: "Página 69 - Practice. Explain the schedule. Use the model as 'set the scene'. Do the exercises as usual. Make a planner with them in class. Use their daily activities. Páginas 70, 71 e 72 - Do the exercise as usual.", unidade: "", pagina: "" },
    { numero: 23, descricao: "Google has great models to print or to use online. Be free to choose any other option. Use the link we suggested at the beginning of the lesson. Página 73 - Skip the page. It's not necessary yet. The possessive case is out for a while. Páginas 74 e 75 - Use the model as set the scene. Review the object pronouns. Do the exercises as usual. Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 24, descricao: "Páginas 75 e 76 - Practice. Use the exercises to set the scene. Páginas 77 e 78 - Introduce the Reading and answer the questions. Culture tip. Start the workbook. https://books.apple.com/br/book/the-world-almanac-and-book-of-facts-2022/id1551653182", unidade: "", pagina: "" },
    { numero: 25, descricao: "Correct the workbook and take the test.", unidade: "", pagina: "" },
    { numero: 26, descricao: "Páginas 80 e 81 - Set the scene. Review how to say the seasons of the year. Introduce the dialogue as usual. Visit PUC Museum em Porto Alegre. Links and pictures available on google. https://www.pucrs.br/mct/ Material extra na plataforma. Páginas 82, 83 e 84 - Introduce the simple present progressive tense and explain the goal of the exercise. Practice. Use the model to explain the question form and affirmative and negative short answers. Then do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 27, descricao: "Página 85 - Do the exercises. Página 86 - Use the grammar box to explain the affirmative form. Páginas 87 e 88 - Do the exercises as usual and transfer on p. 89. Página 89 - Use the grammar box to explain the negative form. Páginas 90 e 91 - Do the exercises as usual. Página 92 - Introduce Reading as usual. Talk about Parana state, Curitiba and Caioba Beach. Make them feel proud of Brazil. Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 28, descricao: "Página 93 - Introduce the dialogue. Show tourist spots in Curitiba (parks and gardens and so on). Do the dialogue as usual. Start practice. Use the model to explain the following exercises. material extra na plataforma. Páginas 94, 95, 96 e 97 - Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 29, descricao: "Páginas 98 e 99 - Practice. Do the exercises as usual. Introduce the email. Show pictures of the Library Congress in Washington DC. https://www.loc.gov/visit/ Páginas 100, 101 - Review. This review is extremely important. Do it carefully. Reuse exercises you have done before. Then do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 30, descricao: "Páginas 102 e 103 - Check the colors and do the exercises as usual. Suggestions: ask them how to say their favorite items of clothing in English. The vocabulary is very traditional in this unit. Use www.linguee.com.br Páginas 103, 104 e 105 - Practice. Use the exercise to set the scene. Explain the question form and the answers.", unidade: "", pagina: "" },
    { numero: 31, descricao: "Página 105 - Use the same extra material you used at the beginning of the lesson. The four seasons and months of the year. Ask them to represent each month with a picture. You can use weather words. Páginas 106, 107, 108 e 109 - Do the exercises as usual. Introduce the Reading and culture tip as usual.", unidade: "", pagina: "" },
    { numero: 32, descricao: "Página 110 - Having fun is optional. Start workbook. Take the test.", unidade: "", pagina: "" },
    { numero: 33, descricao: "Páginas 111 e 112 - Introduce unit 05 and the conversation as usual. Show the most comfortable movie theaters around the world. Grammar box. Take a lot of time to explain. Explain look x look like. www.linguee.com.br for helping with vocabulary https://www.atchuup.com/most-beautiful-movie-theaters-around-the-world/ Páginas 113 e 114 - Complete the chart. Ask them to make avatars or any other thing to help with vocabulary. Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 34, descricao: "Página 115 - Start the class with transfer. Introduce the reading as usual. Talk about how important “Expointer” is for Brazil and the world. Curiosity: how many countries are there at the fair? More than 20 in 2022. https://www.expointer.rs.gov.br/historia Página 116 - Answer the questions. Introduce the conversation as usual. Curiosity: handball Páginas 117 e 118 - Practice. Do it as usual. Compare present continuous x simple present. Be careful with simple present continuous used as future. Review and make them feel safe about the verb tenses. Páginas 119, 120 e 121 - Review do and does and TO linking two verbs. Explain carefully. Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 35, descricao: "Páginas 122 e 123 - Practice. Use the model as set the scene to explain the exercise and the meaning of need, want, has to and have to. Página 124 - Introduce the Reading as usual and answer the questions. Extra: origin of pizza. https://whatscookingamerica.net/history/pizza/pizzahistory.htm You can use Cipex book 04, unit 08 - page 130 to help you.", unidade: "", pagina: "" },
    { numero: 36, descricao: "Página 125 - Introduce the dialogue as usual and use the grammar box to explain the difference between countable and uncountable nouns in English. Páginas 126 e 127 - Introduce the food and prepare something delicious with them. Suggestion: cooking class.", unidade: "", pagina: "" },
    { numero: 37, descricao: "Página 128 - Introduce the dialogue as usual. Use the grammar box to explain the difference betweens how much and how many. Use the containers you have at school to show the difference when it's necessary. Ex: coffee is uncountable. Páginas 129 e 130 - Use the model to review there is and there are. Explain again how much and how many. Introduce a little. Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 38, descricao: "Páginas 131, 132, 133 e 134 - Start the class with transfer if it 's necessary. Practices. Explain using the models. Review do and do. Do the exercises as usual. Review questions with there is and there are + how many. Páginas 135, 136 e 137 - Practice. Use the model to set the scene. Explain how much, how many and review do and does. It's always necessary to repeat these subjects. Practice: present the vocabulary and do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 39, descricao: "Páginas 138 e 139 - Introduce the dialogue as usual. Use the grammar box on p. 139 to explain the difference betweens some and any. Do the exercises as usual. Extra: history of sandwich https://cellones.com/the-history-of-the-sandwich/#:~:text=According%20to%20history%2C%20the%20sandwich,hours%20at%20the%20card%20table. Páginas 140, 141 e 142 - Do the exercises as usual. Introduce the Reading and answer the questions.", unidade: "", pagina: "" },
    { numero: 40, descricao: "Página 143 - Introduce the culture tip and start the workbook. Take the test.", unidade: "", pagina: "" }
  ]
};

export { tweens2 };
