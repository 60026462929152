import React, { useState, useEffect } from "react";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import CadastroMatriculaModal from "./CadastroMatriculaModalTeste";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  Pagination,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
// import { Spinner } from "react-bootstrap";
import "./CadastroModal.css";
import { API_BASE_URL } from "./config";
const Modal = ({ children, closeModal }) => {
  return (
    <div className="modal-background">
      <div className="modal-content">
        <div className="modal-form">{children}</div>
        <button className="close-button" onClick={closeModal}>
          X
        </button>
      </div>
    </div>
  );
};
const CadastroMatricula = () => {
  const [matriculas, setMatriculas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [matriculaDataToEdit, setMatriculaDataToEdit] = useState(null);
  const [usuarios, setUsuarios] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [matriculasPerPage, setMatriculasPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [loading, setLoading] = useState(false);
  const userType = localStorage.getItem("userType");
  const schoolId = localStorage.getItem("schoolId");
  useEffect(() => {
    // console.log("Chegou no fetchMatricula [1]")
    fetchMatriculas();
  }, []);

  const fetchMatriculas = async () => {
    setLoading(true);
    try {
      const responseMatriculas = await fetch(`${API_BASE_URL}/matriculas`);
      const matriculasData = await responseMatriculas.json();
      setMatriculas(matriculasData);

      const uniqueUserIds = [
        ...new Set(matriculasData.map((matricula) => matricula.cp_mt_usuario)),
      ];
      await Promise.all(
        uniqueUserIds.map(async (userId) => {
          try {
            const responseUsuario = await fetch(
              `${API_BASE_URL}/matricula/${userId}`
            );
            const usuarioData = await responseUsuario.json();
            setUsuarios((prevState) => ({
              ...prevState,
              [userId]: usuarioData.nomeUsuario,
            }));
          } catch (error) {
            console.error(`Erro ao buscar usuário com ID ${userId}:`, error);
            setLoading(false);
          }
        })
      );
    } catch (error) {
      console.error("Erro ao buscar as matrículas:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleDelete = async (matriculaId) => {
    setLoading(true);
    try {
      await fetch(`${API_BASE_URL}/excluir-matricula/${matriculaId}`, {
        method: "DELETE",
      });
      setTimeout(() => {
        fetchMatriculas();
      }, 1000);
    } catch (error) {
      console.error("Erro ao excluir matrícula:", error);
    }
  };
  const openEditModal = async (matriculaId) => {
    try {
      const responseMatricula = await fetch(
        `${API_BASE_URL}/matriculas/${matriculaId}`
      );
      const matricula = await responseMatricula.json();
      if (matricula.cp_mt_id) {
        const responseUsuario = await fetch(
          `${API_BASE_URL}/matricula/${matricula.cp_mt_usuario}`
        );
        const usuarioData = await responseUsuario.json();
        setMatriculaDataToEdit({
          cp_mt_id: matricula.cp_mt_id,
          usuarioId: parseInt(matricula.cp_mt_usuario, 10),
          cpfUsuario: matricula.cp_mt_cadastro_usuario,
          valorCurso: matricula.cp_mt_valor_curso,
          numeroParcelas: matricula.cp_mt_quantas_parcelas,
          status: matricula.cp_status_matricula,
          cursoId: matricula.cp_mt_curso,
          nomeUsuario: usuarioData.nomeUsuario,
          escolaridade: matricula.cp_mt_escolaridade,
          localNascimento: matricula.cp_mt_local_nascimento,
          redeSocial: matricula.cp_mt_rede_social,
          nomePai: matricula.cp_mt_nome_pai,
          contatoPai: matricula.cp_mt_contato_pai,
          nomeMae: matricula.cp_mt_nome_mae,
          contatoMae: matricula.cp_mt_contato_mae,
          horarioInicio: matricula.cp_mt_horario_inicio,
          horarioFim: matricula.cp_mt_horario_fim,
          nivelIdioma: matricula.cp_mt_nivel,
          primeiraDataPagamento: matricula.cp_mt_primeira_parcela,
        });
        setShowModal(true);
      } else {
        console.error("ID de matrícula não encontrado na resposta da API");
      }
    } catch (error) {
      console.error("Erro ao buscar dados da matrícula para edição:", error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    // setTimeout(() => {
    fetchMatriculas();
    // }, 1000);
  };

  const handleAddModal = () => {
    setMatriculaDataToEdit(null);
    setShowModal(true);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  // const filteredMatriculas = matriculas.filter(
  //   (matricula) =>
  //     usuarios[matricula.cp_mt_usuario] &&
  //     usuarios[matricula.cp_mt_usuario]
  //       .toLowerCase()
  //       .includes(searchTerm.toLowerCase())
  // );

  // Filtrar matrículas
  const filteredMatriculas = matriculas.filter((matricula) => {
    if (userType === "1") {
      return (
        usuarios[matricula.cp_mt_usuario] &&
        usuarios[matricula.cp_mt_usuario]
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    } else {
      return (
        usuarios[matricula.cp_mt_usuario] &&
        usuarios[matricula.cp_mt_usuario]
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) &&
        matricula.cp_mt_escola == schoolId
      );
    }
  });

  const indexOfLastMatricula = currentPage * matriculasPerPage;
  const indexOfFirstMatricula = indexOfLastMatricula - matriculasPerPage;

  const currentMatriculas = filteredMatriculas.slice(
    indexOfFirstMatricula,
    indexOfLastMatricula
  );
  const handleSortChange = () => {
    const newDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    const sortedMatriculas = [...matriculas].sort((a, b) => {
      const nomeA = usuarios[a.cp_mt_usuario] || "";
      const nomeB = usuarios[b.cp_mt_usuario] || "";
      // Verificar se nomeA ou nomeB é uma string vazia
      if (!nomeA || !nomeB) {
        return 0; // Não faz nada na ordenação se uma das strings estiver vazia
      }
      if (newDirection === "asc") {
        return nomeA.localeCompare(nomeB);
      } else {
        return nomeB.localeCompare(nomeA);
      }
    });
    setMatriculas(sortedMatriculas);
  };

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePagination = (pageNumber) => {
    const totalPages = Math.ceil(filteredMatriculas.length / matriculasPerPage);
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <Container fluid>
      <Row className="my-4 form-container">
        <Col md="12" className="header-content">
          <h1 className="user-title">Matrículas</h1>

          <div className="header-controls">
            <Form inline className="mr-2">
              <Form.Control
                type="text"
                placeholder="Pesquisar..."
                onChange={handleSearchChange}
                className="mr-2"
              />
            </Form>
            <div className="buttons">
              <Button
                variant="primary"
                className="add-button mr-2"
                onClick={handleAddModal}
              >
                + Adicionar
              </Button>
              <Button variant="secondary" className="sort-button" onClick={handleSortChange}>
                Ordenar por {sortDirection === "asc" ? "A-Z" : "Z-A"}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <div className="user-table-container">
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Aluno</th>
              <th>Status</th>
              <th>Parcelas Pagas</th>
              <th>Excluir</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5" className="text-center">
                  <Spinner animation="border" />
                </td>
              </tr>
            ) : (
              currentMatriculas.map((matricula, index) => (
                <tr style={{ backgroundColor: "#fff" }} key={index}>
                  <td>{usuarios[matricula.cp_mt_usuario]}</td>
                  <td className={`status-td ${matricula.cp_status_matricula}`}>
                    <div className="status-cell">
                      <span>{matricula.cp_status_matricula}</span>
                    </div>
                  </td>
                  <td>{`${matricula.cp_mt_parcelas_pagas}/${matricula.cp_mt_quantas_parcelas}`}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(matricula.cp_mt_id)}
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="warning"
                      onClick={() => openEditModal(matricula.cp_mt_id)}
                    >
                      <FaEdit />
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <div className="pagination-container">
        <Row className="justify-content-center align-items-center">
          <Col className="d-flex justify-content-center">
            <Pagination>
              <Pagination.First onClick={() => handlePagination(1)} />
              <Pagination.Prev
                onClick={() => handlePagination(Math.max(1, currentPage - 1))}
              />
              {[
                ...Array(
                  Math.min(
                    Math.ceil(filteredMatriculas.length / matriculasPerPage),
                    5
                  )
                ).keys(),
              ].map((_, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={currentPage === i + 1}
                  onClick={() => handlePagination(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              {Math.ceil(filteredMatriculas.length / matriculasPerPage) > 5 && (
                <Pagination.Ellipsis disabled />
              )}
              <Pagination.Next
                onClick={() =>
                  handlePagination(
                    Math.min(
                      currentPage + 1,
                      Math.ceil(filteredMatriculas.length / matriculasPerPage)
                    )
                  )
                }
              />
              <Pagination.Last
                onClick={() =>
                  handlePagination(
                    Math.ceil(filteredMatriculas.length / matriculasPerPage)
                  )
                }
              />
            </Pagination>
          </Col>
          <Col xs="auto" style={{ marginTop: "-20px" }}>
            {" "}
            {/* Adicionando ml-3 para dar um pequeno espaçamento */}
            <Form.Control
              as="select"
              value={currentPage}
              onChange={(e) => handlePagination(parseInt(e.target.value))}
              className="mt-2"
              style={{ width: "70px" }} // Define a largura do select
            >
              {Array.from(
                {
                  length: Math.ceil(
                    filteredMatriculas.length / matriculasPerPage
                  ),
                },
                (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                )
              )}
            </Form.Control>
          </Col>
        </Row>
      </div>

      {showModal && (
        <Modal closeModal={closeModal}>
          <CadastroMatriculaModal
            closeModal={closeModal}
            isEdit={!!matriculaDataToEdit}
            matriculaDataToEdit={matriculaDataToEdit}
            matriculaId={matriculaDataToEdit?.cp_mt_id} // Adiciona o matriculaId aqui
          />
        </Modal>
      )}
    </Container>
  );
};
export default CadastroMatricula;
