import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Form, Container, Row, Col } from 'react-bootstrap';
import { API_BASE_URL } from './config';
import './AudioModal.css';

const AudioModal = ({ closeModal, audioModalData }) => {
    const { cursoId, cursoAudios } = audioModalData;
    const [cursoNome, setCursoNome] = useState('');
    const [audioStatus, setAudioStatus] = useState({});

    useEffect(() => {
        const fetchCursoNome = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/cursos/${cursoId}`);
                setCursoNome(response.data.cp_nome_curso);
            } catch (error) {
                console.error('Erro ao buscar o nome do curso:', error);
            }
        };

        fetchCursoNome();

        // Função para carregar os áudios marcados como ouvidos pelo usuário
        const fetchAudiosMarcados = async () => {
            const userId = localStorage.getItem('userId');
            if (userId) {
                try {
                    const response = await axios.get(`${API_BASE_URL}/audios-marcados/${userId}`);
                    const audiosMarcados = response.data;

                    // Atualiza o estado audioStatus para marcar os áudios que foram marcados como ouvidos
                    const updatedAudioStatus = {};
                    cursoAudios.forEach(audio => {
                        if (audiosMarcados.includes(audio.cp_audio_id)) {
                            updatedAudioStatus[audio.cp_audio_id] = true;
                        } else {
                            updatedAudioStatus[audio.cp_audio_id] = false;
                        }
                    });
                    setAudioStatus(updatedAudioStatus);
                } catch (error) {
                    console.error('Erro ao buscar áudios marcados como ouvidos:', error);
                }
            }
        };

        fetchAudiosMarcados();

        const handleBeforeUnload = () => {
            handleClose();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [cursoId, cursoAudios]); // Certifique-se de incluir cursoAudios aqui para que useEffect seja acionado ao mudar os áudios

    const handleCheckboxChange = async (audioId) => {
        const isChecked = audioStatus[audioId];

        setAudioStatus(prevStatus => ({
            ...prevStatus,
            [audioId]: !isChecked
        }));

        const userId = localStorage.getItem('userId');
        if (userId) {
            try {
                if (isChecked) {
                    // Remove a visualização do áudio
                    await axios.delete(`${API_BASE_URL}/registrar-visualizacao`, {
                        data: { userId, audioId }
                    });
                    console.log(`Visualização do áudio ${audioId} removida para o usuário ${userId}.`);
                } else {
                    // Registra a visualização do áudio
                    await axios.post(`${API_BASE_URL}/registrar-visualizacao`, { userId, audioId });
                    console.log(`Áudio ${audioId} foi marcado como ouvido pelo usuário ${userId}.`);
                }
            } catch (error) {
                console.error('Erro ao registrar/remover visualização de áudio:', error);
            }
        }
    };

    const handleClose = () => {
        closeModal();
    };

    return (
        <div>
            <Button className="close-button" variant="secondary" onClick={handleClose}>X</Button>
            <Card>
                <Card.Header>
                    <h2>{cursoNome}</h2>
                </Card.Header>
                <Card.Body>
                    {cursoAudios.map(audio => (
                        <div key={audio.cp_audio_id} className="mb-3">
                            <Row className="mb-2">
                                <Col xs={12} md={4}>
                                    <h5>{audio.cp_nome_audio}</h5>
                                </Col>
                                <Col xs={12} md={4}>
                                    <audio controls className="w-100 mb-2">
                                        <source src={`${API_BASE_URL}/audios/${audio.cp_nome_audio}`} type="audio/mpeg" />
                                        Seu navegador não suporta o elemento <code>audio</code>.
                                    </audio>
                                </Col>
                                {/* <Col className='d-flex justify-content-center align-items-center' xs={12} md={4}>
                                    <Form>
                                        <Form.Check 
                                            type="switch"
                                            label="Ouvi este áudio"
                                            id={`custom-switch-${audio.cp_audio_id}`}
                                            checked={audioStatus[audio.cp_audio_id] || false}
                                            onChange={() => handleCheckboxChange(audio.cp_audio_id)}
                                        />
                                    </Form>
                                </Col> */}
                            </Row>
                        </div>
                    ))}
                </Card.Body>
            </Card>
        </div>
    );
};

export default AudioModal;
