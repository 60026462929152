import React, { useState, useEffect } from "react";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import CadastroUsuarioModal from "./CadastroUsuarioModal";
import CadastroUserEditar from "./CadastroUserEditar";
import "./CadastroUsuarios.css";
import "./UserList.css";
import { API_BASE_URL } from "./config";
import { FaGift } from "react-icons/fa";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  Pagination,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const Modal = ({ children, closeModal }) => {
  return (
    <div className="modal-background">
      <div className="modal-content">
        <div className="modal-form">{children}</div>
        <button className="close-button" onClick={closeModal}>
          X
        </button>
      </div>
    </div>
  );
};

const CadastroUsuario = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userDataToEdit, setUserDataToEdit] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [editingUserId, setEditingUserId] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [escolas, setEscolas] = useState([]);
  const [showOnlyBirthdays, setShowOnlyBirthdays] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    fetchUsers();
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    fetchUsers();
  };

  const isBirthdaySoon = (dateOfBirth) => {
    const today = new Date();
    const [year, month, day] = dateOfBirth.split("T")[0].split("-").map(Number);

    // Resetando horas, minutos, segundos para comparação apenas da data
    const todayOnlyDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const birthDateThisYear = new Date(today.getFullYear(), month - 1, day);

    const diffTime = Math.abs(birthDateThisYear - todayOnlyDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    console.log("Data de nascimento recebida:", dateOfBirth);
    console.log(
      `Verificando aniversário para ${day}/${month} -> Dentro de 5 dias: ${diffDays <= 5 && birthDateThisYear >= todayOnlyDate
      }`
    );

    return diffDays <= 5 && birthDateThisYear >= todayOnlyDate;
  };


  const openEditModal = (userId, pageNumber = 1) => {
    setEditingUserId(userId);
    setCurrentPage(pageNumber);
    fetch(`${API_BASE_URL}/users/${userId}`)
      .then((response) => response.json())
      .then((userData) => {
        setShowEditModal(true);
        setUserDataToEdit(userData);
      })
      .catch((error) =>
        console.error("Erro ao buscar dados do usuário:", error)
      );
  };

  // const filterActiveUsers = (user) => {
  //   if (selectedUserType === "") {
  //     return !user.cp_excluido;
  //   } else {
  //     return !user.cp_excluido && user.cp_tipo_user === selectedUserType;
  //   }
  // };
  const filterActiveUsers = (user) => {
    const schoolId = parseInt(localStorage.getItem("schoolId"), 10); // Obter o schoolId do localStorage
    const userType = localStorage.getItem("userType"); // Obter o userType do localStorage

    // Mostrar todos os usuários se o userType for 1
    if (userType === "1") {
      return !user.cp_excluido;
    }

    // Filtrar usuários ativos da mesma escola
    if (selectedUserType === "") {
      return !user.cp_excluido && user.cp_escola_id === schoolId;
    } else {
      return (
        !user.cp_excluido &&
        user.cp_tipo_user === selectedUserType &&
        user.cp_escola_id === schoolId
      );
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/users`);
      console.log("Online users");
      const data = await response.json();
      const activeUsers = data.filter((user) => !user.cp_excluido);

      setUsers(
        activeUsers.map((user) => ({
          ...user,
          cp_tipo_user: mapFunction(user.cp_tipo_user),
        }))
      );
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchEscolas();
  }, []);

  const handleUserTypeChange = (e) => {
    setSelectedUserType(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedUserType]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = () => {
    const newDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    const sortedUsers = [...users].sort((a, b) => {
      const nameA = a.cp_nome.toLowerCase();
      const nameB = b.cp_nome.toLowerCase();
      if (newDirection === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });
    setUsers(sortedUsers);
  };

  const handleDelete = (userId) => {
    fetch(`${API_BASE_URL}/delete-user/${userId}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Usuário desativado com sucesso") {
          const updatedUsers = users.filter((user) => user.cp_id !== userId);
          setUsers(updatedUsers);
        }
      })
      .catch((error) => console.error("Erro ao excluir usuário:", error));
  };

  const fetchEscolas = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/escolas`);
      const data = await response.json();
      setEscolas(data);
    } catch (error) {
      console.error("Erro ao buscar escolas:", error);
    }
  };

  const mapFunction = (functionNumber) => {
    switch (functionNumber) {
      case 1:
        return "Gestor";
      case 2:
        return "Diretor";
      case 3:
        return "Secretária";
      case 4:
        return "Professor";
      case 5:
        return "Aluno";
      default:
        return "Outro";
    }
  };

  const handlePagination = (pageNumber) => {
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  // Primeiro, filtre os usuários ativos
  const activeUsers = users.filter((user) => !user.cp_excluido);
  const filteredActiveUsers = activeUsers.filter(filterActiveUsers);

  // Em seguida, aplique o filtro de pesquisa aos usuários ativos filtrados
  // const filteredUsers = filteredActiveUsers.filter((user) =>
  //   user.cp_nome.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const normalizedSearchTerm = searchTerm
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

  let filteredUsers = filteredActiveUsers.filter((user) =>
    user.cp_nome
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .includes(normalizedSearchTerm)
  );

  if (showOnlyBirthdays) {
    filteredUsers = filteredUsers.filter((user) =>
      isBirthdaySoon(user.cp_datanascimento)
    );
  }

  // Finalmente, aplique a lógica de paginação aos usuários filtrados
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <Container fluid>
      <Row className="my-4 form-container">
        <Col md="12" className="header-content">
          <h1 className="user-title">Usuário</h1>
          <div className="header-controls">
            <Form style={{ margin: "2px" }}>
              <Form.Control
                type="text"
                placeholder="Pesquisar..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="mr-2 custom-input"
              />
            </Form>

            {/* Adicionando o botão de presente */}
            <Button
              variant="outline-secondary"
              onClick={() => setShowOnlyBirthdays(!showOnlyBirthdays)} // Alterna entre mostrar todos ou apenas aniversariantes
              style={{ margin: "2px" }}
            >
              <FaGift style={{ color: '#ff5733' }} />
            </Button>

            <Form.Select
              style={{ margin: "2px" }}
              className="custom-select"
              value={selectedUserType}
              onChange={handleUserTypeChange}
            >
              <option value="">Todos os tipos</option>
              <option value="Gestor">Gestor</option>
              <option value="Diretor">Diretor</option>
              <option value="Secretária">Secretária</option>
              <option value="Professor">Professor</option>
              <option value="Aluno">Aluno</option>
            </Form.Select>
            <div className="buttons" style={{ margin: "2px" }}>
              <Button
                className="add-button mr-2"
                variant="primary"
                onClick={openModal}
              >
                + Adicionar
              </Button>
              <Button className="sort-button" onClick={handleSortChange}>
                Ordenar por {sortDirection === "asc" ? "A-Z" : "Z-A"}
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <div className="user-table-container">
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Nome</th>
              {/* <th >Email</th> */}
              <th>Data de Nascimento</th>
              <th>Tipo de Usuário</th>
              <th>Excluir</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user, index) => (
              <tr key={index}>
                <td>
                  {user.cp_nome}{" "}
                  {isBirthdaySoon(user.cp_datanascimento) && (
                    <FaGift className="ml-2" style={{ color: '#ff5733' }} />
                  )}
                </td>
                <td>{new Date(user.cp_datanascimento).toLocaleDateString()}</td>
                <td>{user.cp_tipo_user}</td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(user.cp_id)}
                  >
                    <FaTrashAlt />
                  </Button>
                </td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => openEditModal(user.cp_id, currentPage)}
                  >
                    <FaEdit />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="pagination-container">
        <Row className="justify-content-center align-items-center">
          <Col className="d-flex justify-content-center">
            <Pagination>
              <Pagination.First onClick={() => handlePagination(1)} />
              <Pagination.Prev
                onClick={() => handlePagination(Math.max(1, currentPage - 1))}
              />
              {[
                ...Array(
                  Math.min(Math.ceil(filteredUsers.length / usersPerPage), 5)
                ).keys(),
              ].map((_, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={currentPage === i + 1}
                  onClick={() => handlePagination(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              {Math.ceil(filteredUsers.length / usersPerPage) > 5 && (
                <Pagination.Ellipsis disabled />
              )}
              <Pagination.Next
                onClick={() =>
                  handlePagination(
                    Math.min(
                      currentPage + 1,
                      Math.ceil(filteredUsers.length / usersPerPage)
                    )
                  )
                }
              />
              <Pagination.Last
                onClick={() =>
                  handlePagination(
                    Math.ceil(filteredUsers.length / usersPerPage)
                  )
                }
              />
            </Pagination>
          </Col>
          <Col xs="auto" style={{ marginTop: "-20px" }}>
            <Form.Control
              as="select"
              value={currentPage}
              onChange={(e) => handlePagination(parseInt(e.target.value))}
              className="mt-2"
              style={{ width: "70px" }}
            >
              {Array.from(
                { length: Math.ceil(filteredUsers.length / usersPerPage) },
                (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                )
              )}
            </Form.Control>
          </Col>
        </Row>
      </div>

      {showModal && (
        <Modal closeModal={closeModal}>
          <CadastroUsuarioModal closeModal={closeModal} escolas={escolas} />
        </Modal>
      )}
      {showEditModal && (
        <Modal closeModal={closeEditModal}>
          <CadastroUserEditar
            userData={userDataToEdit}
            closeModal={closeEditModal}
            escolas={escolas}
          />
        </Modal>
      )}
    </Container>
  );
};

export default CadastroUsuario;
