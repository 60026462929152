const book3Semestral = {
  titulo: "Book 3 (Semestral)",
  aulas: [
    { numero: 1, descricao: "Abertura, boas vindas, apresentações. (Apresentação do material e metodologia Cipex, se necessário. Ex.: alunos vindos de outras escolas, começando no Cipex direto no livro 2)", unidade: "Unid 1", pagina: 11 },
    { numero: 2, descricao: "Unid 1 – pág 22 (guiar os alunos no que deve ser feito no workbook p/ próxima aula)", unidade: "Unid 1", pagina: 22 },
    { numero: 3, descricao: "Wkbk 1 – teste 1 - Unid 2 pág 27", unidade: "Wkbk 1", pagina: 0 },
    { numero: 4, descricao: "Unid 2 – pág 43 (guiar o wkbk p/ próxima aula)", unidade: "Unid 2", pagina: 43 },
    { numero: 5, descricao: "Wkbk 2 – teste 2 – Unid 3 – pág 51", unidade: "Wkbk 2", pagina: 0 },
    { numero: 6, descricao: "Unid 3 – pág 64 (guiar o wkbk p/ próxima aula)", unidade: "Unid 3", pagina: 64 },
    { numero: 7, descricao: "Wkbk 3 – teste 3 – Unid 4 – pág 72", unidade: "Wkbk 3", pagina: 0 },
    { numero: 8, descricao: "Unid 4 – pág 87 (guiar o wkbk p/ próxima aula)", unidade: "Unid 4", pagina: 87 },
    { numero: 9, descricao: "Wkbk 4 – teste 4 – Unid 5 – pág 94", unidade: "Wkbk 4", pagina: 0 },
    { numero: 10, descricao: "Unid 5 – pág 106 (guiar o wkbk p/ próxima aula)", unidade: "Unid 5", pagina: 106 },
    { numero: 11, descricao: "Wkbk 5 – teste 5 – Unid 6 – pág 111", unidade: "Wkbk 5", pagina: 0 },
    { numero: 12, descricao: "Unid 6 – pág 121", unidade: "Unid 6", pagina: 121 },
    { numero: 13, descricao: "Unid 6 – pág 131 (guiar o wkbk p/ próxima aula)", unidade: "Unid 6", pagina: 131 },
    { numero: 14, descricao: "Wkbk 6 – teste 6 – Unid 7 – pág 136", unidade: "Wkbk 6", pagina: 0 },
    { numero: 15, descricao: "Unid 7 – pág 147 (guiar o wkbk p/ próxima aula)", unidade: "Unid 7", pagina: 147 },
    { numero: 16, descricao: "Unid 7 – final – Wkbk 7 – teste 7", unidade: "Unid 7", pagina: 0 },
    { numero: 17, descricao: "Unid 8 – pág 158", unidade: "Unid 8", pagina: 158 },
    { numero: 18, descricao: "Unid 8 – pág 164 (guiar o wkbk p/ próxima aula)", unidade: "Unid 8", pagina: 164 },
    { numero: 19, descricao: "Unid 8 – final – Wkbk 8 – teste 8", unidade: "Unid 8", pagina: 0 },
    { numero: 20, descricao: "Unid 9 – pág 174", unidade: "Unid 9", pagina: 174 },
    { numero: 21, descricao: "Unid 9 – pág 182 (guiar o wkbk p/ próxima aula)", unidade: "Unid 9", pagina: 182 },
    { numero: 22, descricao: "Unid 9 – final – Wkbk 9 – teste 9", unidade: "Unid 9", pagina: 0 },
    { numero: 23, descricao: "Unid 10 – pág 197", unidade: "Unid 10", pagina: 197 },
    { numero: 24, descricao: "Unid 10 – pág 207 (guiar o wkbk p/ próxima aula)", unidade: "Unid 10", pagina: 207 },
    { numero: 25, descricao: "Wkbk 10 – teste 10 – considerações finais", unidade: "Wkbk 10", pagina: 0 }
  ]
};

export { book3Semestral };
