import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CadastroModal.css";
import InputMask from "react-input-mask";
import { API_BASE_URL } from "./config";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Button } from "react-bootstrap";
import "./Modal.css";

const CadastroMatriculaModal = ({
  closeModal,
  isEdit,
  matriculaDataToEdit,
  matriculaId,
}) => {
  const [usuarios, setUsuarios] = useState([]);
  const [dadosUsuario, setDadosUsuario] = useState({});
  const [cursos, setCursos] = useState([]);
  const [escolas, setEscolas] = useState([]);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showParentFields, setShowParentFields] = useState(false);
  const [filteredUsuarios, setFilteredUsuarios] = useState([]);
  const [showUserSearchModal, setShowUserSearchModal] = useState(false);
  // const [usuarioSelecionado, setUsuarioSelecionado] = useState();
  const [matriculaData, setMatriculaData] = useState({
    status: "ativo",
    numeroParcelas: "",
    valorParcela: "",
    usuarioId: "",
    nomeUsuario: "",
    cpfUsuario: "",
    valorCurso: "",
    cursoId: "",
    escolaId: "",
    dataNascimento: "",
    profissao: "",
    estadoCivil: "",
    endereco: "",
    rua: "",
    numero: "",
    whatsapp: "",
    telefone: "",
    email: "",
    escolaridade: "",
    localNascimento: "",
    redeSocial: "",
    nomePai: "",
    contatoPai: "",
    nomeMae: "",
    contatoMae: "",
    horarioInicio: "",
    horarioFim: "",
    nivelIdioma: "",
    primeiraDataPagamento: "",
  });

  // Funções para abrir e fechar o modal
  const openUserSearchModal = () => setShowUserSearchModal(true);
  const closeUserSearchModal = () => setShowUserSearchModal(false);

  useEffect(() => {
    console.log("ID da matrícula a editar:", matriculaDataToEdit?.cp_mt_id);
  }, [matriculaDataToEdit, matriculaId]);

  useEffect(() => {
    if (!isEdit) {
      axios
        .get(`${API_BASE_URL}/buscarusermatricula`)
        .then((response) => {
          setUsuarios(response.data);
          setFilteredUsuarios(response.data);
        })
        .catch((error) => {
          console.error("Erro ao buscar usuários:", error);
        });
    }
  }, [isEdit]);

  // useEffect(() => {
  //   if (isEdit && matriculaDataToEdit) {
  //     setMatriculaData(matriculaDataToEdit);

  //   }
  // }, [isEdit, matriculaDataToEdit]);
  // const calcularValorParcela = () => {
  //   if (matriculaData.valorCurso && matriculaData.numeroParcelas) {
  //     const valorParcela = matriculaData.valorCurso / matriculaData.numeroParcelas;
  //     setMatriculaData((prevState) => ({
  //       ...prevState,
  //       valorParcela: valorParcela.toFixed(2),
  //     }));
  //   }
  // };

  const calcularValorParcela = () => {
    const valorCurso = parseFloat(matriculaData.valorCurso);
    const numeroParcelas = parseInt(matriculaData.numeroParcelas);

    if (!isNaN(valorCurso) && !isNaN(numeroParcelas) && numeroParcelas > 0) {
      const valorParcela = (valorCurso / numeroParcelas).toFixed(2);
      setMatriculaData((prevState) => ({
        ...prevState,
        valorParcela: valorParcela,
      }));
    } else {
      setMatriculaData((prevState) => ({
        ...prevState,
        valorParcela: "",
      }));
    }
  };

  useEffect(() => {
    if (isEdit) {
      calcularValorParcela();
    }
  }, [matriculaData.valorCurso]);


  useEffect(() => {
    if (isEdit && matriculaDataToEdit) {
      setMatriculaData(matriculaDataToEdit);
      const usuarioId = matriculaDataToEdit.usuarioId;

      axios.get(`${API_BASE_URL}/buscarusermatricula/${usuarioId}`)
        .then(response => {
          const usuario = response.data;
          setDadosUsuario(usuario);

          setMatriculaData(prevMatriculaData => ({
            ...prevMatriculaData,
            dataNascimento: usuario.cp_datanascimento,
            profissao: usuario.cp_profissao,
            estadoCivil: usuario.cp_estadocivil,
            endereco: `${usuario.cp_end_cidade_estado}, ${usuario.cp_end_rua}, ${usuario.cp_end_num}`,
            whatsapp: usuario.cp_whatsapp,
            telefone: usuario.cp_telefone,
            email: usuario.cp_email,
            escolaId: usuario.cp_escola_id,
          }));
        })
        .catch(error => {
          console.error("Erro ao buscar usuário:", error);
          toast.error("Erro ao buscar usuário");
        });
    }
  }, [isEdit, matriculaDataToEdit]);

  const loadDefaultLanguageLevels = () => {
    return [
      { id: "basico", nome: "Básico" },
      { id: "intermediario", nome: "Intermediário" },
      { id: "avancado", nome: "Avançado" },
    ];
  };

  const defaultLanguageLevels = loadDefaultLanguageLevels();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const requestBody = {

  //       cursoId: matriculaData.cursoId,
  //       usuarioId: matriculaData.usuarioId,
  //       escolaId: matriculaData.escolaId,
  //       nomeUsuario: matriculaData.nomeUsuario,
  //       cpfUsuario: matriculaData.cpfUsuario,
  //       valorCurso: matriculaData.valorCurso,
  //       numeroParcelas: matriculaData.numeroParcelas,
  //       primeiraDataPagamento: matriculaData.primeiraDataPagamento,
  //       status: matriculaData.status,
  //       nivelIdioma: matriculaData.nivelIdioma,
  //       horarioInicio: matriculaData.horarioInicio,
  //       horarioFim: matriculaData.horarioFim,
  //       localNascimento: matriculaData.localNascimento,
  //       escolaridade: matriculaData.escolaridade,
  //       redeSocial: matriculaData.redeSocial,
  //       nomePai: matriculaData.nomePai,
  //       contatoPai: matriculaData.contatoPai,
  //       nomeMae: matriculaData.nomeMae,
  //       contatoMae: matriculaData.contatoMae,
  //     };

  //     console.log('Request Body:', requestBody);

  //     let response;
  //     if (isEdit) {
  //       console.log("chegou no edit")
  //       const matriculaId = matriculaData.cp_mt_id; // ID da matrícula a ser editada
  //       console.log(`Enviando requisição PUT para editar a matrícula com ID: ${matriculaId}`);
  //       // response = await axios.put(
  //       //   `${API_BASE_URL}/editar-matricula/${matriculaId}`,
  //       //   requestBody
  //       // );

  //       response = await axios.put(
  //         `${API_BASE_URL}/editar-matricula/${matriculaDataToEdit.cp_mt_id}`,
  //         requestBody
  //       );
  //     } else {
  //       response = await axios.post(
  //         `${API_BASE_URL}/cadastrar-matricula`,
  //         requestBody
  //       );
  //     }

  //     if (response.status === 200) {
  //       toast.success(
  //         `Matrícula ${isEdit ? "atualizada" : "cadastrada"} com sucesso!`
  //       );
  //       closeModal();
  //     } else {
  //       toast.error(
  //         `Erro ao ${isEdit ? "atualizar" : "cadastrar"} matrícula. Por favor, tente novamente.`
  //       );
  //     }
  //   } catch (error) {
  //     console.error(
  //       `Erro ao ${isEdit ? "atualizar" : "cadastrar"} matrícula:`,
  //       error
  //     );
  //     toast.error(
  //       `Erro ao ${isEdit ? "atualizar" : "cadastrar"} matrícula. Por favor, tente novamente.`
  //     );
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!matriculaData.status) {
      matriculaData.status = "ativo";
    }

    console.log("matriculaData.status:", matriculaData.status);
    try {
      if (isEdit) {
        if (!matriculaData) {
          console.error("Dados de matrícula não encontrados:", matriculaData);
          toast.error("Dados de matrícula não encontrados");
          return;
        }
        const editObj = {
          cursoId: matriculaData.cursoId,
          usuarioId: matriculaData.usuarioId,
          escolaId: matriculaData.escolaId,
          nomeUsuario: matriculaData.nomeUsuario,
          cpfUsuario: matriculaData.cpfUsuario,
          valorCurso: matriculaData.valorCurso,
          numeroParcelas: matriculaData.numeroParcelas,
          primeiraDataPagamento: matriculaData.primeiraDataPagamento,
          status: matriculaData.status,
          nivelIdioma: matriculaData.nivelIdioma,
          horarioInicio: matriculaData.horarioInicio,
          horarioFim: matriculaData.horarioFim,
          localNascimento: matriculaData.localNascimento,
          escolaridade: matriculaData.escolaridade,
          redeSocial: matriculaData.redeSocial,
          nomePai: matriculaData.nomePai,
          contatoPai: matriculaData.contatoPai,
          nomeMae: matriculaData.nomeMae,
          contatoMae: matriculaData.contatoMae,
        };
        const response = await axios.put(
          `${API_BASE_URL}/editar-matricula/${matriculaDataToEdit.cp_mt_id}`,
          editObj
        );
        if (
          response.data &&
          response.data.msg === "Matrícula editada com sucesso"
        ) {
          toast.success("Matrícula editada com sucesso");
          closeModal();
        } else {
          console.error("Erro ao editar matrícula:", response.data);
          toast.error("Erro ao editar matrícula");
        }
      } else {
        const response = await axios.post(
          `${API_BASE_URL}/cadastrar-matricula`,
          matriculaData
        );
        console.log("Resposta do servidor:", response.data);

        if (
          response.data &&
          response.data.msg === "Matrícula cadastrada com sucesso"
        ) {
          toast.success("Operação realizada com sucesso");
          closeModal();
        } else {
          console.error("Erro ao cadastrar matrícula:", response.data);
        }
      }
    } catch (error) {
      console.error("Erro durante o processamento:", error);

      if (error.response) {
        console.error("Detalhes do erro no servidor:", error.response.data);
      }
    }
  };

  const handleUsuarioChange = (e) => {
    const selectedUserId = e.target.value;

    if (selectedUserId) {
      axios.get(`${API_BASE_URL}/buscarusermatricula/${selectedUserId}`)
        .then(response => {
          if (response.data) {
            const usuario = response.data;
            setDadosUsuario(usuario);

            setMatriculaData(prevMatriculaData => ({
              ...prevMatriculaData,
              usuarioId: usuario.cp_id,
              nomeUsuario: usuario.cp_nome,
              cpfUsuario: usuario.cp_cpf,
              dataNascimento: usuario.cp_datanascimento,
              profissao: usuario.cp_profissao,
              estadoCivil: usuario.cp_estadocivil,
              endereco: `${usuario.cp_end_cidade_estado}, ${usuario.cp_end_rua}, ${usuario.cp_end_num}`,
              whatsapp: usuario.cp_whatsapp,
              telefone: usuario.cp_telefone,
              email: usuario.cp_email,
              // escolaridade: usuario.cp_escolaridade,
              escolaId: usuario.cp_escola_id
            }));
          } else {
            console.error("Usuário não encontrado:", response.data);
            toast.error("Usuário não encontrado");
          }
        })
        .catch(error => {
          console.error("Erro ao buscar dados do usuário:", error);
          toast.error("Erro ao buscar dados do usuário");
        });
    } else {
      setDadosUsuario({});
      setMatriculaData({
        ...matriculaData,
        usuarioId: '',
        nomeUsuario: '',
        cpfUsuario: '',
        dataNascimento: '',
        profissao: '',
        estadoCivil: '',
        endereco: '',
        whatsapp: '',
        telefone: '',
        email: '',
        escolaridade: '',
        escolaId: ''
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMatriculaData({
      ...matriculaData,
      [name]: value,
    });
  };

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    if (!isNaN(data.getTime())) {
      const dia = data.getDate().toString().padStart(2, "0");
      const mes = (data.getMonth() + 1).toString().padStart(2, "0");
      const ano = data.getFullYear();
      return `${ano}-${mes}-${dia}`;
    } else {
      return "";
    }
  };


  // useEffect(() => {
  //   setMatriculaData((prevState) => ({
  //     ...prevState,
  //     numeroParcelas: "", 
  //   }));
  // }, [matriculaData.valorCurso]);

  const handleNumeroParcelasChange = (e) => {
    const { value } = e.target;

    const valorCurso = matriculaData.valorCurso;
    const numeroParcelas = parseInt(value, 10);

    if (!isNaN(numeroParcelas) && numeroParcelas > 0) {
      const valorParcela = (valorCurso / numeroParcelas).toFixed(2);
      setMatriculaData((prevMatriculaData) => ({
        ...prevMatriculaData,
        numeroParcelas: value,
        valorParcela: valorParcela,
      }));
    } else {
      setMatriculaData((prevMatriculaData) => ({
        ...prevMatriculaData,
        numeroParcelas: "",
        valorParcela: "",
      }));
    }
  };

  useEffect(() => {
    fetchCursos();
    fetchEscolas();
  }, []);

  const fetchCursos = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/cursos`);
      if (Array.isArray(response.data)) {
        setCursos(response.data);
      } else {
        console.error(
          "Formato de resposta inválido para cursos:",
          response.data
        );
        toast.error("Formato de resposta inválido para cursos");
      }
    } catch (error) {
      console.error("Erro ao buscar cursos:", error);
      toast.error("Erro ao buscar cursos");
    }
  };

  const fetchEscolas = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/escolas`);
      if (Array.isArray(response.data)) {
        setEscolas(response.data);
      } else {
        console.error(
          "Formato de resposta inválido para escolas:",
          response.data
        );
        toast.error("Formato de resposta inválido para escolas:");
      }
    } catch (error) {
      console.error("Erro ao buscar escolas:", error);
      toast.error("Erro ao buscar escolas:");
    }
  };

  const handleParentDataChange = () => {
    setShowParentFields(!showParentFields);
  };

  const toggleAdditionalFields = () => {
    setShowAdditionalFields(!showAdditionalFields);
  };
  const toggleParentFields = () => {
    setShowParentFields(!showParentFields);
  };

  useEffect(() => {
    const hasParentData =
      matriculaData.nomePai !== "" ||
      matriculaData.contatoPai !== "" ||
      matriculaData.nomeMae !== "" ||
      matriculaData.contatoMae !== "";

    setShowParentFields(hasParentData);
  }, [
    matriculaData.nomePai,
    matriculaData.contatoPai,
    matriculaData.nomeMae,
    matriculaData.contatoMae,
  ]);

  useEffect(() => {
    const hasAdditionalData =
      matriculaData.escolaridade !== "" ||
      matriculaData.localNascimento !== "" ||
      matriculaData.redeSocial !== "";

    setShowAdditionalFields(hasAdditionalData);
  }, [
    matriculaData.escolaridade,
    matriculaData.localNascimento,
    matriculaData.redeSocial,
  ]);
  // const handleAdditionalDataChange = (e) => {
  //   const isChecked = e.target.checked;
  //   setShowAdditionalFields(isChecked);
  //   // Se o checkbox for marcado, esperar 1 segundo antes de enviar os dados para a tela
  //   if (isChecked) {
  //     setTimeout(() => {
  //       // Envie os dados para a tela aqui
  //       console.log("Dados enviados para a tela:", matriculaData);
  //     }, 1000); 
  //   }
  // };

  const handleAdditionalDataChange = (e) => {
    // const isChecked = e.target.checked;
    setShowAdditionalFields(e.target.checked);

    // if (isChecked) {
    //   setTimeout(() => { }, 1000);
    // }
  };

  const handleUsuarioSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    let filtered;
    if (searchTerm.trim() === '') {
      filtered = usuarios; // Retorna a lista completa se o campo de pesquisa estiver vazio
    } else {
      filtered = usuarios.filter(usuario =>
        usuario.cp_nome.toLowerCase().includes(searchTerm)
      );
    }
    // Aplicar filtro adicional se userType for 2 ou 3
    if (localStorage.userType === 2 || localStorage.userType === 3) {
      filtered = filtered.filter(usuario =>
        usuario.cp_escola_id === localStorage.schoolId
      );
    }

    setFilteredUsuarios(filtered);
  };

  const handleUsuarioSelect = (usuario) => {
    console.log('Valor de usuario.cp_escola_id:', usuario.cp_escola_id);

    setDadosUsuario(usuario);
    setMatriculaData(prevMatriculaData => ({
      ...prevMatriculaData,
      usuarioId: usuario.cp_id,
      nomeUsuario: usuario.cp_nome,
      cpfUsuario: usuario.cp_cpf,
      dataNascimento: usuario.cp_datanascimento,
      profissao: usuario.cp_profissao,
      estadoCivil: usuario.cp_estadocivil,
      endereco: `${usuario.cp_end_cidade_estado}, ${usuario.cp_end_rua}, ${usuario.cp_end_num}`,
      whatsapp: usuario.cp_whatsapp,
      telefone: usuario.cp_telefone,
      email: usuario.cp_email,
      escolaId: usuario.cp_escola_id,
    }));
  };

  return (
    <div className="modal-edit">
      <ToastContainer />
      <h2>Ficha do Estudante CIPEX</h2>
      <h3 className="titulos-modal-secao">Dados Pessoal</h3>
      <div>
        <form onSubmit={handleSubmit} className="form-container-cad">
          <Row>
            {isEdit ? (
              <Col md={4}>
                <label htmlFor="nomeUsuario">Usuário:</label>
                <input
                  type="text"
                  id="nomeUsuario"
                  name="nomeUsuario"
                  value={matriculaData.nomeUsuario}
                  className="input-field"
                  placeholder="Nome do usuário"
                  required
                  readOnly
                />
              </Col>
            ) : (
              <Col md={4}>
                <label htmlFor="nomeUsuario">Nome do Usuário:</label>
                <input
                  type="text"
                  id="nomeUsuario"
                  name="nomeUsuario"
                  value={matriculaData.nomeUsuario}
                  className="input-field"
                  placeholder="Nome do usuário"
                  required
                />
                <Button onClick={openUserSearchModal}>Pesquisar Usuário</Button>
              </Col>

            )}
            {/* Modal para pesquisa de usuário */}
            <div className={`modal ${showUserSearchModal ? 'open' : ''}`}>
              <div className="modal-content-matricula">
                <span className="close" onClick={closeUserSearchModal}>&times;</span>
                <label htmlFor="usuarioSearch">Pesquisar Usuário:</label>
                <input
                  type="text"
                  id="usuarioSearch"
                  name="usuarioSearch"
                  onChange={handleUsuarioSearch}
                  className="input-field"
                  placeholder="Digite para pesquisar"
                />
                <ul className="usuario-lista">
                  {filteredUsuarios.map((usuario) => (
                    <li
                      key={usuario.cp_id}
                      onClick={() => {
                        handleUsuarioSelect(usuario);
                        closeUserSearchModal();
                      }}
                    >
                      {usuario.cp_nome}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <Col md={4}>
              <label htmlFor="cpfUsuario">CPF do Usuário:</label>
              <InputMask
                type="text"
                id="cpfUsuario"
                name="cpfUsuario"
                mask="999.999.999-99"
                value={matriculaData.cpfUsuario}
                className="input-field"
                placeholder="CPF"
                required
                readOnly
              />
            </Col>

            <Col md={4}>
              <label htmlFor="dataNascimento">Data de Nascimento:</label>
              <input
                type="date"
                id="dataNascimento"
                name="dataNascimento"
                value={formatarData(dadosUsuario.cp_datanascimento) || ""}
                className="input-field"
                placeholder="Data de nascimento"
                readOnly
              />
            </Col>

            <Col md={4}>
              <label htmlFor="profissao">Profissão:</label>
              <input
                type="text"
                id="profissao"
                name="profissao"
                value={dadosUsuario.cp_profissao || ""}
                className="input-field"
                placeholder="Profissão"
                readOnly
              />
            </Col>

            <Col md={4}>
              <label htmlFor="estadoCivil">Estado Civil:</label>
              <input
                type="text"
                id="estadoCivil"
                name="estadoCivil"
                value={dadosUsuario.cp_estadocivil || ""}
                className="input-field"
                placeholder="Estado civil"
                readOnly
              />
            </Col>

            <Col md={4}>
              <label htmlFor="endereco">Endereço:</label>
              <input
                type="text"
                id="endereco"
                name="endereco"
                value={`${dadosUsuario.cp_end_rua || ""}, ${dadosUsuario.cp_end_num || ""
                  }, ${dadosUsuario.cp_end_cidade_estado || ""}`}
                className="input-field"
                placeholder="Endereço"
                readOnly
              />
            </Col>

            <Col md={4}>
              <label htmlFor="whatsapp">Whatsapp:</label>
              <InputMask
                type="text"
                id="whatsapp"
                name="whatsapp"
                mask="(99)99999-9999"
                value={dadosUsuario.cp_whatsapp || ""}
                className="input-field"
                placeholder="Whatsapp"
                readOnly
              />
            </Col>

            <Col md={4}>
              <label htmlFor="telefone">Telefone:</label>
              <InputMask
                type="text"
                id="telefone"
                name="telefone"
                mask="(99)99999-9999"
                value={dadosUsuario.cp_telefone || ""}
                className="input-field"
                placeholder="Telefone"
                readOnly
              />
            </Col>

            <Col md={4}>
              <label htmlFor="email">Email:</label>
              <input
                type="text"
                id="email"
                name="email"
                value={dadosUsuario.cp_email || ""}
                className="input-field"
                placeholder="Email"
                readOnly
              />
            </Col>

            <Col md={4}>
              <label htmlFor="escolaId">Escola:</label>
              <input
                type="text"
                id="escolaId"
                name="escolaId"
                value={
                  escolas.find(
                    (escola) => escola.cp_ec_id === dadosUsuario.cp_escola_id
                  )?.cp_ec_nome || ""
                }
                className="input-field"
                placeholder="Escola"
                readOnly
              />
            </Col>
          </Row>

          <Col md={12}>
            <input
              type="checkbox"
              id="showAdditionalFields"
              name="showAdditionalFields"
              checked={showAdditionalFields}
              onChange={handleAdditionalDataChange}
              className="input-checkbox styled-checkbox"
            />
            <span className="span-check" htmlFor="showAdditionalFields">
              Dados adicionais
            </span>
          </Col>

          <Row>
            {showAdditionalFields && (
              <>
                <Col md={4}>
                  <label htmlFor="escolaridade">Escolaridade:</label>
                  <select
                    id="escolaridade"
                    name="escolaridade"
                    value={matriculaData.escolaridade}
                    onChange={(e) =>
                      setMatriculaData({
                        ...matriculaData,
                        escolaridade: e.target.value,
                      })
                    }
                    className="input-field"
                  >
                    <option value="">Selecione...</option>
                    <option value="Ensino Fundamental Incompleto">
                      Ensino Fundamental Incompleto
                    </option>
                    <option value="Ensino Fundamental Completo">
                      Ensino Fundamental Completo
                    </option>
                    <option value="Ensino Médio Incompleto">
                      Ensino Médio Incompleto
                    </option>
                    <option value="Ensino Médio Completo">
                      Ensino Médio Completo
                    </option>
                    <option value="Técnico Incompleto">
                      Técnico Incompleto
                    </option>
                    <option value="Técnico Completo">Técnico Completo</option>
                    <option value="Superior Incompleto">
                      Superior Incompleto
                    </option>
                    <option value="Superior Completo">Superior Completo</option>
                    <option value="Pós-graduação">Pós-graduação</option>
                    <option value="Mestrado">Mestrado</option>
                    <option value="Doutorado">Doutorado</option>
                    <option value="Outra Graduação">Outra Graduação</option>
                  </select>
                </Col>

                <Col md={4}>
                  <label htmlFor="localNascimento">Local de Nascimento:</label>
                  <input
                    type="text"
                    id="localNascimento"
                    name="localNascimento"
                    value={matriculaData.localNascimento}
                    onChange={(e) =>
                      setMatriculaData({
                        ...matriculaData,
                        localNascimento: e.target.value,
                      })
                    }
                    className="input-field"
                    placeholder="Local de Nascimento"
                  />
                </Col>
                <Col md={4}>
                  <label htmlFor="redeSocial">Link de Rede Social:</label>
                  <input
                    type="text"
                    id="redeSocial"
                    name="redeSocial"
                    value={matriculaData.redeSocial}
                    onChange={(e) =>
                      setMatriculaData({
                        ...matriculaData,
                        redeSocial: e.target.value,
                      })
                    }
                    className="input-field"
                    placeholder="Link de Rede Social"
                  />
                </Col>
              </>
            )}
          </Row>

          <h3 className="titulos-modal-secao">Dados do Curso</h3>
          <Row>
            <Col md={4}>
              <label htmlFor="cursoId">Curso:</label>
              <select
                id="cursoId"
                name="cursoId"
                value={matriculaData.cursoId}
                onChange={(e) =>
                  setMatriculaData({
                    ...matriculaData,
                    cursoId: e.target.value,
                  })
                }
                className="input-field"
                required
              >
                <option value="">Selecione o curso</option>
                {cursos.map((curso) => (
                  <option key={curso.cp_curso_id} value={curso.cp_curso_id}>
                    {curso.cp_nome_curso}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={4}>
              <label htmlFor="nivelIdioma">Nível do Idioma:</label>
              {/* Select para o nível do idioma */}
              <select
                id="nivelIdioma"
                name="nivelIdioma"
                value={matriculaData.nivelIdioma}
                onChange={(e) =>
                  setMatriculaData({
                    ...matriculaData,
                    nivelIdioma: e.target.value,
                  })
                }
                className="input-field"
              >
                <option value="">Selecione o nível do idioma</option>
                {/* Opções de níveis de idioma */}
                {defaultLanguageLevels.map((level) => (
                  <option key={level.id} value={level.nome}>
                    {level.nome}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={4}>
              <label htmlFor="horarioInicio">Horário:</label>
              <Row>
                <Col xs={5}>
                  <InputMask
                    type="text"
                    id="horarioInicio"
                    name="horarioInicio"
                    mask="99:99"
                    value={matriculaData.horarioInicio}
                    onChange={(e) =>
                      setMatriculaData({
                        ...matriculaData,
                        horarioInicio: e.target.value,
                      })
                    }
                    className="input-field"
                    placeholder="Horário de início"
                  />
                </Col>
                <Col xs={2}>
                  <span className="horario-span">até</span>
                </Col>
                <Col xs={5}>
                  <InputMask
                    type="text"
                    id="horarioFim"
                    name="horarioFim"
                    mask="99:99"
                    value={matriculaData.horarioFim}
                    onChange={(e) =>
                      setMatriculaData({
                        ...matriculaData,
                        horarioFim: e.target.value,
                      })
                    }
                    className="input-field"
                    placeholder="Horário de término"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlFor="status">Status:</label>
              <select
                id="status"
                name="status"
                value={matriculaData.status}
                onChange={(e) =>
                  setMatriculaData({ ...matriculaData, status: e.target.value })
                }
                className="input-field"
                required
              >
                <option value="ativo">Ativo</option>
                <option value="cancelado">Cancelado</option>
                <option value="trancado">Trancado</option>
                <option value="concluido">Concluído</option>
              </select>
            </Col>
            <Col md={4}>
              <label htmlFor="valorCurso">Valor do Curso:</label>
              <input
                type="number"
                id="valorCurso"
                name="valorCurso"
                value={matriculaData.valorCurso}
                onChange={(e) =>
                  setMatriculaData({
                    ...matriculaData,
                    valorCurso: e.target.value,
                  })
                }
                className="input-field"
                placeholder="Valor do Curso"
                required
              />
            </Col>

            <Col md={4}>
              <label htmlFor="numeroParcelas">Número de Parcelas:</label>
              <select
                id="numeroParcelas"
                name="numeroParcelas"
                value={matriculaData.numeroParcelas}
                onChange={handleNumeroParcelasChange}
                className="input-field"
                required
                disabled={!matriculaData.valorCurso} //
              >
                <option value="">Selecione o número de parcelas</option>
                {[...Array(13).keys()].map((index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}x
                  </option>
                ))}
              </select>
            </Col>

            <Col md={4}>
              <label htmlFor="primeiraDataPagamento">Primeira Parcela:</label>
              <input
                type="date"
                id="primeiraDataPagamento"
                name="primeiraDataPagamento"
                value={formatarData(matriculaData.primeiraDataPagamento) || ""}
                onChange={(e) =>
                  setMatriculaData({
                    ...matriculaData,
                    primeiraDataPagamento: e.target.value,
                  })
                }
                className="input-field"
                placeholder="Primeira Data de Pagamento"
                required
              />
            </Col>



            <Col md={4}>
              <div>
                <label htmlFor="valorParcela">Valor da Parcela:</label>
                <input
                  type="text"
                  id="valorParcela"
                  name="valorParcela"
                  value={Number(matriculaData.valorParcela).toLocaleString(
                    "pt-BR",
                    {
                      minimumFractionDigits: 2,
                    }
                  )}
                  placeholder="Valor da Parcela"
                  className="input-field"
                  readOnly
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="form-group-2">
              <input
                type="checkbox"
                id="showParentFields"
                name="showParentFields"
                checked={showParentFields}
                onChange={handleParentDataChange}
                className="input-checkbox styled-checkbox"
              />
              <span className="span-check" htmlFor="showParentFields">
                Aluno menor de 18 anos
              </span>
            </Col>
          </Row>
          <Row>
            {showParentFields && (
              <>
                <h3 className="titulos-modal-secao">Dados do Responsável</h3>
                <Col md={4}>
                  <label htmlFor="nomePai">Nome do Pai:</label>
                  <input
                    type="text"
                    id="nomePai"
                    name="nomePai"
                    value={matriculaData.nomePai}
                    onChange={handleInputChange}
                    className="input-field"
                    placeholder="Nome do pai/responsável"
                  />
                </Col>
                <Col md={4}>
                  <label htmlFor="contatoPai">Contato do Pai:</label>
                  <InputMask
                    type="text"
                    id="contatoPai"
                    name="contatoPai"
                    mask="(99) 99999-9999"
                    value={matriculaData.contatoPai}
                    onChange={handleInputChange}
                    className="input-field"
                    placeholder="Contato do pai/responsável"
                  />
                </Col>
                <Col md={4}>
                  <label htmlFor="nomeMae">Nome da Mãe:</label>
                  <input
                    type="text"
                    id="nomeMae"
                    name="nomeMae"
                    value={matriculaData.nomeMae}
                    onChange={handleInputChange}
                    className="input-field"
                    placeholder="Nome da mãe/responsável"
                  />
                </Col>
                <Col md={4}>
                  <label htmlFor="contatoMae">Contato da Mãe:</label>
                  <InputMask
                    type="text"
                    id="contatoMae"
                    name="contatoMae"
                    mask="(99) 99999-9999"
                    value={matriculaData.contatoMae}
                    onChange={handleInputChange}
                    className="input-field"
                    placeholder="Contato da mãe/responsável"
                  />
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <button type="submit" className="submit-button btn-cadastro">
                {isEdit ? "Atualizar Matrícula" : "Cadastrar Matrícula"}
              </button>
              <button type="button" className="submit-button btn-cancelar" onClick={closeModal}>
                Cancelar
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default CadastroMatriculaModal;
