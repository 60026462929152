import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Certificado.css";
import { API_BASE_URL } from "./config";

const Certificado = () => {
  const [showModal, setShowModal] = useState(false);
  const [certificadoUsuario, setCertificadoUsuario] = useState(null);
  const [usuarios, setUsuarios] = useState([]);
  const [error, setError] = useState(null);
  const [cursos, setCursos] = useState({});

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/certificado/matricula`);
        const usuariosData = response.data;

        // Fetch cursos
        const cursoIds = [...new Set(usuariosData.map(usuario => usuario.curso))];
        const cursoPromises = cursoIds.map(id => axios.get(`${API_BASE_URL}/cursos/${id}`));
        const cursoResponses = await Promise.all(cursoPromises);
        
        // Map curso IDs to their names
        const cursoMap = cursoResponses.reduce((acc, response) => {
          acc[response.data.cp_curso_id] = response.data.cp_nome_curso;
          return acc;
        }, {});
        
        setCursos(cursoMap);
        setUsuarios(usuariosData);
      } catch (error) {
        setError("Erro ao buscar os usuários.");
        console.error("Erro ao buscar os usuários:", error);
      }
    };

    fetchUsuarios();
  }, []);

  const Usuario = React.memo(({ id, nome, curso, cargaHoraria, notaFinal }) => {
    const handleClick = () => {
      setCertificadoUsuario({
        nome,
        curso: cursos[curso] || curso, // Use the curso name if available
        cargaHoraria,
        notaFinal,
      });
      setShowModal(true);
    };

    return (
      <Card className="mb-3">
        <Card.Body>
          <Card.Title style={{ fontWeight: "bold" }}>{nome}</Card.Title>
          <Card.Text>Curso: {cursos[curso] || curso}</Card.Text>
          <Button variant="primary" onClick={handleClick}>
            Gerar Certificado
          </Button>
        </Card.Body>
      </Card>
    );
  });

  const getMonthName = (monthNumber) => {
    const months = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];
    return months[monthNumber];
  };

  return (
    <Container fluid>
      <Card>
        <Card.Body>
          <h2>Lista de Certificados</h2>
        </Card.Body>
      </Card>
      {error && <div className="alert alert-danger">{error}</div>}
      <Row className="w-100 m-2">
        {usuarios.map((usuario) => (
          <Col md={4} className="my-2" key={usuario.id}>
            <Usuario {...usuario} />
          </Col>
        ))}
      </Row>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        fullscreen
        dialogClassName="modal-90w"
        className="custom-modal-size"
        style={{
          // maxWidth: '842px',
          // width: '842px',
          // height: '595px',
          // maxHeight: '595px',
          zIndex: 1050,
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Certificado</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom certificado-container" style={{ height: 'calc(100% - 56px)' }}>
          <div className="certificado-content">
            {certificadoUsuario && (
              <div>
                <div style={{ marginTop: "10px", fontSize:"18px" }}>
                  O Diretor de CIPEX® Idiomas confere o presente certificado a
                </div>
                <h2 style={{ textTransform: "uppercase",  fontSize:"22px" , marginTop: "10px", fontWeight: "bold" }}>
                  {certificadoUsuario.nome}
                </h2>
                <p>
                  Por haver concluído com sucesso o estágio{" "}
                  {certificadoUsuario.curso} - Personal / Anual, com{" "}
                  {certificadoUsuario.cargaHoraria} horas aula, e média final de{" "}
                  {certificadoUsuario.notaFinal}
                </p>
                <p>
                  PANAMBI, {new Date().getDate()} de{" "}
                  {getMonthName(new Date().getMonth())} de{" "}
                  {new Date().getFullYear()}
                </p>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Certificado;
