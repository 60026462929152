const tweens4 = {
  titulo: "Tweens 4 (Anual)",
  aulas: [
    { numero: 1, descricao: "Páginas 01, 02 e 03 - Introduce the dialogue as usual. Do the exercises. It's important to review the verb tenses. Simple past x simple present. Question words review. Material extra na plataforma. Páginas 04 e 05 - Do the exercises. Review the simple past of irregular verbs. Suggestion: youtube games https://www.youtube.com/watch?v=9iwN3GcyEe4&list=RDCMUC9SoAIo4z8rfVnfhMpKfC4g&start_radio=1&rv=9iwN3GcyEe4&t=252 https://www.youtube.com/watch?v=9kWo_azIlS0&list=RDCMUC9SoAIo4z8rfVnfhMpKfC4g&index=2 https://www.youtube.com/watch?v=-SmWRPbXiio&list=RDCMUC9SoAIo4z8rfVnfhMpKfC4g&index=4", unidade: "", pagina: "" },
    { numero: 2, descricao: "Páginas 06, 07, 08 e 09 - Do the exercises as usual. Páginas 10 e 11 - Practice short answers. Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 3, descricao: "Páginas 12, 13, 14 e 15 - Do the exercises as usual. Review was / were + ING plus question words. Páginas 16 e 17 - Practice short answers simple past to be.", unidade: "", pagina: "" },
    { numero: 4, descricao: "Páginas 18 e 19 - Introduce the dialogue as usual. Answer the questions. Suggestion: origem dos shopping centers. Páginas 20 e 21 - Revisar o going to future + question words. Suggestion: usar material extra.", unidade: "", pagina: "" },
    { numero: 5, descricao: "Páginas 22 e 23 - Material extra com slides na plataforma para fazer o exercício. Páginas 24 - Introduce the dialogue as usual. Show pictures of Caiobá beach in Paraná. Explain the difference between will x going to the future. Answer the questions.", unidade: "", pagina: "" },
    { numero: 6, descricao: "Páginas 25, 26 e 27 - Do the exercises. Suggestion: Reuse the extra material from p. 22,23 with 'will'. Páginas 29 e 30 - Grammar box. Explain 'will'. Short form. Do the exercises.", unidade: "", pagina: "" },
    { numero: 7, descricao: "Páginas 31, 32 e 33 - Use the grammar boxes as a review if you think it's necessary. Explain the negative form of will with YES/NO QUESTIONS. Vídeo de suporte para professores: https://www.youtube.com/watch?v=tri7u632AaA Páginas 34, 35 e 36 - Do the exercises.", unidade: "", pagina: "" },
    { numero: 8, descricao: "Página 37 - Introduce reading. Suggestion: Mostrar material de intercâmbio da REDE CIPEX de janeiro de 2023 em Cambridge, London e Paris. Vancouver em 2018 e Toronto e New York em 2019. Amplo material no instagram do Cipex Brasil. Programa disponibilizado no Instagram cipexbrasil e experimentopoa. Página 38 - Do the exercises.", unidade: "", pagina: "" },
    { numero: 9, descricao: "Página 39 - Culture tip. Having fun: my bonnie. Start workbook. Extra: Statue of liberty, o presente da França aos USA. https://www.history.com/topics/landmarks/statue-of-liberty Correct the workbook and take the test.", unidade: "", pagina: "" },
    { numero: 10, descricao: "Páginas 41 e 42 - Introduce the dialogue as usual. Set the scene. Introduce going to the future and will future. Explain the difference between them. Suggestion: review adverbs of frequency because of always in the dialogue Extra: birthday traditions around the world https://blog.esl-languages.com/blog/travel/birthday-traditions-around-the-world/ Is there a Brazilian tradition? Páginas 43, 44 e 45 - Use the model to set the scene. Do exercises as usual. Página 45 - Use the grammar boxes as a tool and explain to the students about present progressive as future. It's too much information at the same time. Suggestion: esl games. Going to x will or woodward on youtube. https://www.youtube.com/watch?v=7z0cy3gkabm https://www.youtube.com/watch?v=k2voqnuiy_0&list=rdlvk2voqnuiy_0&index=1", unidade: "", pagina: "" },
    { numero: 11, descricao: "Páginas 46 e 47 - Explain again present continuous as future. Contextualize use the model to set the scene. Do the exercise as usual. Página 48 - Be careful. Now we are going to have a dialogue about the past. Review simple past using tweens 03 as support and the material used before. Explain the difference between was and were x action verbs in the past. Review regular and irregular verbs and questions with did and neg sentences with didn't.", unidade: "", pagina: "" },
    { numero: 12, descricao: "Página 48 - Introduce the dialogue as usual and do the exercises. Páginas 49 e 50 - Review there is x there are. Introduce the past of there is and there are. Contextualize and do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 13, descricao: "Página 51 - Introduce some and any. Attention: avoid the grammar box at the beginning. Watch these vídeos first. Be sure about the differences. Explain them and do the exercises. it's not easy for them. https://www.youtube.com/watch?v=dflb4oboypa https://www.youtube.com/watch?v=cazik1wgsco https://www.youtube.com/watch?v=tbfyp5ynnc0 follow this teacher rebecca on you tube. Página 52 - Do the exercises as usual. Introduce the reading. Do it as usual.", unidade: "", pagina: "" },
    { numero: 14, descricao: "Páginas 53 e 54 - Introduce the prepositions and use the grammar box to help you. Do the exercises as usual. Have fun using personal stuff around class and asking where they are. Include food and presents for the students. If they ask and answer correctly they can have them. Páginas 56 e 57 - Use the grammar box to explain the prepositions of place and do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 15, descricao: "Página 58 - Introduce the dialogue and the modal should. Review can and will. Explain they are from the same family but with different meanings. Do the exercises as usual. Página 59 - Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 16, descricao: "Páginas 60 e 61 - Keep doing the exercises. Transfer. Suggestion: use verbs and vocabulary from tweens 02 and tweens 03 as a vocabulary review. Páginas 62 e 63 - Use the grammar box for a quick review. Start practice.", unidade: "", pagina: "" },
    { numero: 17, descricao: "Páginas 64, 65, 66 e 67 - Teach short answers with should. Practice. Here we change for a new subject. Introduce why and because. Use the model to review present continuous/progressive. Be clear about the way they have to answer. Páginas 68 e 69 - Practice: review simple past, regular and irregular verbs. Use the model to clarify the exercise and how to answer the questions. Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 18, descricao: "Páginas 70 e 71 - Introduce the reading about Ouro Preto. Provide pictures on google. Do the exercises as usual. Página 72 - Culture tip: New York world fair 50 years ago, were we right? Having fun is optional.", unidade: "", pagina: "" },
    { numero: 19, descricao: "Start the workbook and correct the exercises. Test.", unidade: "", pagina: "" },
    { numero: 20, descricao: "Páginas 74, 75 e 76 - Set the scene. Introduce the dialogue as usual. Explain one and one using the grammar box. Ask the students and provide vocabulary to help them to answer the following questions. Use tweens 02 and tweens 03 to help. Https://getthefriendsyouwant.com/how-to-keep-and-maintain-friendships/?Utm_source=google&utm_medium=cpc&utm_content=static-upload-1&utm_campaign=static-upload-1&kw=friendship&gclid=cj0kcqia0oagbhdharisai-bbgcgj_iqcnicpm3gvrjxl01k_wo9g6u1wj_l9iojth-cbqrhulpoizmaah30ealw_wcb Get the friends you want by paul sanders. Páginas 77, 78, 79 e 80 - Use the models to explain the exercises and do them as usual.", unidade: "", pagina: "" },
    { numero: 21, descricao: "Página 81 - Introduce the dialogue and ask the questions as usual. Set the scene: ask questions like: What do you collect? Do you have someone in your family that does it? And a friend? If you want to collect something, what would you like to do? Can you make money collecting stuff? Https://insights.masterworks.com/alternative-investments/trending-collectibles-in-2023/ Páginas 83, 84 e 85 - Practice. Use the model to explain the exercises and do them as usual. Material extra na plataforma para explicar os comparatives of adjectives.", unidade: "", pagina: "" },
    { numero: 22, descricao: "Páginas 86, 87 e 88 - Explain comparatives with long adjectives. Explain which. Do the exercises as usual. Página 90 - Ecoturismo in Brazil and around the world. Explore the subject and show them that Brazil is one of the most beautiful countries in the world and can make a lot of money and create millions of jobs. Suggestion: ask them to search about ecotourism in the state they live. Do the exercise as usual. Https://ecobnb.com/blog/2022/02/best-ecotourism-destinations-world/", unidade: "", pagina: "" },
    { numero: 23, descricao: "Páginas 93 e 94 - Set the scene. Use the model to explain how the mini dialogues work. Clarify the answers they should give. They’re personal. Do the exercises as usual. Páginas 93, 94 e 95 - Explain less. Use the model to set the scene. Do the exercises as usual. Extra: explain them less is for long and short adjectives.", unidade: "", pagina: "" },
    { numero: 24, descricao: "Páginas 98 e 99 - Just do it. Check vocabulary and ask them to ask and answer. Páginas 100 e 101 - Introduce the superlative. Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 25, descricao: "Páginas 102 e 103 - Explore the grammar box to explain the superlatives with short adjectives. Attention to the irregular ones and the spelling. Do the exercises as usual. Material extra na plataforma. Páginas 104 e 105 - Explain the least. Contrast it with the most. Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 26, descricao: "Páginas 106, 107 e 108 Attention: they don't know the difference betweens adverbs and adjectives in portuguese. Tell them that the least can be used with long and short words. Página 109 - Do the reading and explore ecotourism in Santa Catarina. Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 27, descricao: "P. 111 - Show them “SPCA” in the United states. Having fun is optional. Https://www.aspca.org/ Start the workbook.", unidade: "", pagina: "" },
    { numero: 28, descricao: "Teste.", unidade: "", pagina: "" },
    { numero: 29, descricao: "Páginas 113 e 114 - Set the scene: biking in Brazil. Introduce the dialogue as usual. Answer the questions about the dialogue. https://www.komoot.com/guide/71788/mtb-trails-in-brazil Páginas 116, 117 e 118 - Introduce the expressions: Would like to; affirmative form, interrogative form and negative form. Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 30, descricao: "Páginas 119 e 120 - Use the grammar box to set the scene and do the exercises as usual. Practice. Review who, when, what, where and how. Use the model to set the scene. Explain the contraction. Do the exercises as usual. Páginas 121 e 122 - Use the model to set the scene. Explain the comparatives.", unidade: "", pagina: "" },
    { numero: 31, descricao: "Página 123 - Use the grammar box to explain the short adverbs. Use as many examples as necessary. Review comparatives with adjectives https://www.google.com/search?q=esl+comparatives+short+adverbs&sxsrf=AJOqlzUtb yUsZAa1Ej1IEa_K9BT8ucsQ5g:1677780961990&source=lnms&tbm=vid&sa=X&ved=2ah UKEwjG5P-R7b39AhWNpZUCHTOBCm0Q_AUoAnoECAEQBA&biw=1366&bih=657&dpr= 1#fpstate=ive&vld=cid:f98a60c4,vid:XzSUxzei9lU https://www.youtube.com/watch?v=E71eT-xDch8 Páginas 123 e 124 - Use the practice to set the scene comparatives with long adverbs. Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 32, descricao: "Páginas 125 e 126 - Explain the model very carefully. Review the use of S in the verb because of simple present tense. Exercise is not easy, students must be safe to ask and answer correctly. Explain it very clearly. Página 127 - Grammar box. Do practice as usual. Material extra na plataforma.", unidade: "", pagina: "" },
    { numero: 33, descricao: "Página 128 - Keep doing the exercises. Transfer. Provide options for asking. Answers will be personal. Páginas 129 e 130 - Do questions as answers as usual. Use www.linguee.com.br to help the students to translate the passage.", unidade: "", pagina: "" },
    { numero: 34, descricao: "Páginas 131 e 132 - Explains 'as...as'. Grammar box. Do the exercises as usual. Material extra na plataforma. Páginas 133 e 134 - Keep doing the exercises. Tell them that short or long words don't make any difference. The importante is to understand 'as...as'.", unidade: "", pagina: "" },
    { numero: 35, descricao: "Introduce the dialogue as usual. Set the scene showing the vídeo about it https://www.youtube.com/watch?v=vvowiWL7tK0 So many things we do and know about this Holiday are American. Ask them if they are surprised. Now, do it as usual. Páginas 136 e 137 - Use the model to explain the same...as. Review the same and then, show the same...as. Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 36, descricao: "Páginas 138 e 139 - Use the word box to explain the same as and different from. Do the exercises as usual. Páginas 140 e 141 - Review the use of as... as and the same...as. Suggestions: um quiz de geografia e história sempre se encaixa bem no transfer. Grammar box is very good and clear. Importante fazer a diferença entre substantivo e adjetivo.", unidade: "", pagina: "" },
    { numero: 37, descricao: "Páginas 142 e 143 - Introduce the Reading as usual. Do the exercises as usual. Culture tip. Having fun : optional. Material extra na plataforma. Start the workbook.", unidade: "", pagina: "" },
    { numero: 38, descricao: "Teste.", unidade: "", pagina: "" },
    { numero: 39, descricao: "Página 146 - Set the scene. Different breakfasts around the world. https://edition.cnn.com/travel/article/breakfast-food-around-the-world/index.html tell them the connection with food and culture. Página 147 - Introduce the dialogue as usual. Check vocabulary and the expression MADE ENOUGH BACON FOR AN ARMY. Página 148 - Do it as usual. Página 149 - Introduce 'tag questions'. Use the models to explain them. Start the exercises. Página 150 - Keep doing the exercises. Use the grammar box to explain again. Página 151 - Do it as usual and explain according to the model and order of the exercise. Página 152 - Keep doing the exercises.", unidade: "", pagina: "" },
    { numero: 40, descricao: "Páginas 153, 154, 155 e 156 - Start the class with grammar box. Review 'do and does'. Practice. Keep doing the exercises. Explain both affirmative and negative will be used. Tell them to pay attention. Do the exercises as usual. Páginas 157, 158, 159 e 160 - Introduce 'could', explain the concept of could according to each sentence in the exercise. Review 'can'. Practice: use it to explain the interrogative form with COULD. Do the exercises as usual.", unidade: "", pagina: "" },
    { numero: 41, descricao: "Página 161 - About the reading. Set the scene like this. 1) show the Philippines in the map. 2) ask them: Do you like to take pictures with your friends? If yes, be careful when you are in Philippines or with a Filipino. 3) use the site below. https://theculturetrip.com/asia/philippines/articles/superstitions-and-taboos-many-filipinos-still-believe-in/ ● if you teach CIPEX BOOK 05 use UNIT 06 P.89 and the QR CODE available on the book on p. 90. Página 161 - Set the scene. Do you like to go finishing? Is it expensive?. Introduce de reading. https://www.boatinternational.com/destinations/of-the-best-places-in-the-world-to-fish--27333", unidade: "", pagina: "" },
    { numero: 42, descricao: "Páginas 162 e 163 - Introduce the dialogue and do it as usual. Where to go fishing in Brazil. Top places in 2019 https://pescariasa.com.br/english/the-10-most-fantastic-sport-fishing-destinations-in-brazil/ Página 164 - Practice. Use the model to introduce SO + adjectives. Do the exercises. Use the grammar box to introduce SO + ADVERBS. Página 165 - Practice. Use the model to explain again. Show the difference between 'adjectives and adverbs'. Do the exercises as usual. Página 166 - Practice. Explain the use of SUCH + NOUN. Provide sentences that show nouns, adjectives and adverbs. This is difficult even in Portuguese. Do the exercises as usual. Use the GRAMMAR BOX to explain again. https://www.youtube.com/watch?v=86-QGDC78nM&t=3s https://www.youtube.com/watch?v=35TpE7S2N5s", unidade: "", pagina: "" },
    { numero: 43, descricao: "Páginas 167, 168 e 169 - Bingo time with ordinals. Review cardinals if it's necessary. Explain why they are important in English. Make them write the numbers that won the game. Explain carefully how to say dates in English. Make them speak and write, check the exercises. Páginas 170 e 171 - Introduce the dialogue. Do it as usual.", unidade: "", pagina: "" },
    { numero: 44, descricao: "Páginas 172 e 173 - Set the scene. Talk about Porto Alegre before showing the things to do there. Why is the capital of RS called Porto Alegre?. Introduce the dialogue as usual. https://prefeitura.poa.br/gp/projetos/conheca-porto-alegre Páginas 174, 175 e 176 - Practice. Explain the difference betweens prefer x would rather. Use the model to set the scene and explain the exercise. Do it as usual.", unidade: "", pagina: "" },
    { numero: 45, descricao: "Páginas 177 e 178 - Introduce the Reading about PELE. Update the information about him if it's necessary. Do the exercises as usual. Páginas 179 e 180 - Culture tip. Do it as usual. Your turn is very good to be done. Let's see which one is healthier in class. Having fun is optional. Work with this song linking it with BEATLES.", unidade: "", pagina: "" },
    { numero: 46, descricao: "Start workbook. Teste.", unidade: "", pagina: "" },
  ]
};

export { tweens4 };
