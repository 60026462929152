import React from 'react';
import { Carousel, Image, Container } from 'react-bootstrap';
import frase from '../../components/pages/arquivos/welcome PORTAL.png';

const ImagensCarousel = () => {
  return (
    <Container style={{backgroundColor:"white", borderRadius:"7px"}} fluid className='text-center '>
      <Image md={12} style={{margin:"20px", width:"90%"}} src={frase} className="" />


      {/*<Carousel>
         <Carousel.Item>
        <img
          className="d-block w-100"
          src="/src/components/pages/arquivos/"
          alt="Primeiro slide"
        />
        <Carousel.Caption>
          <h3>Primeiro slide</h3>
          <p>Descrição do primeiro slide.</p>
        </Carousel.Caption>
      </Carousel.Item> */}
        {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://via.placeholder.com/800x400"
          alt="Segundo slide"
        />
        <Carousel.Caption>
          <h3>Segundo slide</h3>
          <p>Descrição do segundo slide.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://via.placeholder.com/800x400"
          alt="Terceiro slide"
        />
        <Carousel.Caption>
          <h3>Terceiro slide</h3>
          <p>Descrição do terceiro slide.</p>
        </Carousel.Caption>
      </Carousel.Item>
      </Carousel> */}
    </Container>
  );
};

export default ImagensCarousel;
