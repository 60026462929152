const book1 = {
  titulo: "Book 1 (Anual)",
  aulas: [
    { numero: 1, descricao: "Abertura, boas vindas, apresentações. Apresentação do material e metodologia Cipex.", unidade: "Unid 1", pagina: 7 },
    { numero: 2, descricao: "Unid 1 – pág 15 (fazer transfer usando o Word Bank)", unidade: "Unid 1", pagina: 15 },
    { numero: 3, descricao: "Unid 1 – pág 22 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 1", pagina: 22 },
    { numero: 4, descricao: "Wkbk 1 – teste 1 (correção do teste)", unidade: "Wkbk 1", pagina: 0 },
    { numero: 5, descricao: "Unid 2 – pág 28", unidade: "Unid 2", pagina: 28 },
    { numero: 6, descricao: "Unid 2 – pág 33", unidade: "Unid 2", pagina: 33 },
    { numero: 7, descricao: "Unid 2 – pág 39 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 2", pagina: 39 },
    { numero: 8, descricao: "Wkbk 2 – teste 2 (correção do teste)", unidade: "Wkbk 2", pagina: 0 },
    { numero: 9, descricao: "Unid 3 – pág 45", unidade: "Unid 3", pagina: 45 },
    { numero: 10, descricao: "Unid 3 – pág 51", unidade: "Unid 3", pagina: 51 },
    { numero: 11, descricao: "Unid 3 – pág 58 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 3", pagina: 58 },
    { numero: 12, descricao: "Wkbk 3 – teste 3 (correção do teste)", unidade: "Wkbk 3", pagina: 0 },
    { numero: 13, descricao: "Unid 4 – pág 64", unidade: "Unid 4", pagina: 64 },
    { numero: 14, descricao: "Unid 4 – pág 72 (sugestão atividade para casa: aluno fazer a sua agenda e trazer para a próxima aula)", unidade: "Unid 4", pagina: 72 },
    { numero: 15, descricao: "Unid 4 – sugestão: atividade com as agendas dos alunos. Professor pergunta o que cada um faz em cada dia da semana. Pág 76 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 4", pagina: 76 },
    { numero: 16, descricao: "Wkbk 4 – teste 4 (correção do teste)", unidade: "Wkbk 4", pagina: 0 },
    { numero: 17, descricao: "Unid 5 – pág 83", unidade: "Unid 5", pagina: 83 },
    { numero: 18, descricao: "Unid 5 – pág 90", unidade: "Unid 5", pagina: 90 },
    { numero: 19, descricao: "Unid 5 – pág 96 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 5", pagina: 96 },
    { numero: 20, descricao: "Wkbk 5 – teste 5 (correção do teste)", unidade: "Wkbk 5", pagina: 0 },
    { numero: 21, descricao: "Unid 6 – pág 103", unidade: "Unid 6", pagina: 103 },
    { numero: 22, descricao: "Unid 6 – pág 110", unidade: "Unid 6", pagina: 110 },
    { numero: 23, descricao: "Unid 6 – pág 117 – sugestão atividade: fazer árvore da família.", unidade: "Unid 6", pagina: 117 },
    { numero: 24, descricao: "Wkbk 6 – teste 6 (correção do teste)", unidade: "Wkbk 6", pagina: 0 },
    { numero: 25, descricao: "Unid 7 – pág 123", unidade: "Unid 7", pagina: 123 },
    { numero: 26, descricao: "Unid 7 – pág 129", unidade: "Unid 7", pagina: 129 },
    { numero: 27, descricao: "Unid 7 – pág 134 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 7", pagina: 134 },
    { numero: 28, descricao: "Wkbk 7 – teste 7 (correção do teste)", unidade: "Wkbk 7", pagina: 0 },
    { numero: 29, descricao: "Unid 8 – pág 141", unidade: "Unid 8", pagina: 141 },
    { numero: 30, descricao: "Unid 8 – pág 146", unidade: "Unid 8", pagina: 146 },
    { numero: 31, descricao: "Unid 8 – pág 151 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 8", pagina: 151 },
    { numero: 32, descricao: "Wkbk 8 – teste 8 (correção do teste)", unidade: "Wkbk 8", pagina: 0 },
    { numero: 33, descricao: "Unid 9 – pág 157", unidade: "Unid 9", pagina: 157 },
    { numero: 34, descricao: "Unid 9 – pág 165", unidade: "Unid 9", pagina: 165 },
    { numero: 35, descricao: "Unid 9 – pág 170 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 9", pagina: 170 },
    { numero: 36, descricao: "Wkbk 9 – teste 9 (correção do teste)", unidade: "Wkbk 9", pagina: 0 },
    { numero: 37, descricao: "Unid 10 – pág 177", unidade: "Unid 10", pagina: 177 },
    { numero: 38, descricao: "Unid 10 – pág 185", unidade: "Unid 10", pagina: 185 },
    { numero: 39, descricao: "Unid 10 – pág 192 (fazer revisão de estruturas e vocabulário última pág)", unidade: "Unid 10", pagina: 192 },
    { numero: 40, descricao: "Wkbk 10 – teste 10 (correção do teste). Considerações finais", unidade: "Wkbk 10", pagina: 0 }
  ]
};

export { book1 };
