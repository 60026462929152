const book3 = {
  titulo: "Book 3 (Anual)",
  aulas: [
    { numero: 1, descricao: "Abertura, boas vindas, apresentações. (Apresentação do material e metodologia Cipex, se necessário. Ex.: alunos vindos de outras escolas, começando no Cipex direto no livro 3)", unidade: "Unid 1", pagina: 7 },
    { numero: 2, descricao: "Unid 1 – pág 15", unidade: "Unid 1", pagina: 15 },
    { numero: 3, descricao: "Unid 1 – pág 22 (guiar os alunos no que deve ser feito no workbook p/ próxima aula)", unidade: "Unid 1", pagina: 22 },
    { numero: 4, descricao: "Wkbk 1 – teste 1", unidade: "Wkbk 1", pagina: 0 },
    { numero: 5, descricao: "Unid 2 – pág 29", unidade: "Unid 2", pagina: 29 },
    { numero: 6, descricao: "Unid 2 – pág 37", unidade: "Unid 2", pagina: 37 },
    { numero: 7, descricao: "Unid 2 – pág 43 (guiar o wkbk p/ próxima aula)", unidade: "Unid 2", pagina: 43 },
    { numero: 8, descricao: "Wkbk 2 – teste 2", unidade: "Wkbk 2", pagina: 0 },
    { numero: 9, descricao: "Unid 3 – pág 51", unidade: "Unid 3", pagina: 51 },
    { numero: 10, descricao: "Unid 3 – pág 59", unidade: "Unid 3", pagina: 59 },
    { numero: 11, descricao: "Unid 3 – pág 64 (guiar o wkbk p/ próxima aula)", unidade: "Unid 3", pagina: 64 },
    { numero: 12, descricao: "Wkbk 3 – teste 3", unidade: "Wkbk 3", pagina: 0 },
    { numero: 13, descricao: "Unid 4 – pág 72", unidade: "Unid 4", pagina: 72 },
    { numero: 14, descricao: "Unid 4 – pág 82", unidade: "Unid 4", pagina: 82 },
    { numero: 15, descricao: "Unid 4 – pág 87 (guiar o wkbk p/ próxima aula)", unidade: "Unid 4", pagina: 87 },
    { numero: 16, descricao: "Wkbk 4 – teste 4", unidade: "Wkbk 4", pagina: 0 },
    { numero: 17, descricao: "Unid 5 – pág 94", unidade: "Unid 5", pagina: 94 },
    { numero: 18, descricao: "Unid 5 – pág 102", unidade: "Unid 5", pagina: 102 },
    { numero: 19, descricao: "Unid 5 – pág 106 (guiar o wkbk p/ próxima aula)", unidade: "Unid 5", pagina: 106 },
    { numero: 20, descricao: "Wkbk 5 – teste 5", unidade: "Wkbk 5", pagina: 0 },
    { numero: 21, descricao: "Unid 6 – pág 114", unidade: "Unid 6", pagina: 114 },
    { numero: 22, descricao: "Unid 6 – pág 122", unidade: "Unid 6", pagina: 122 },
    { numero: 23, descricao: "Unid 6 – pág 131 (guiar o wkbk p/ próxima aula)", unidade: "Unid 6", pagina: 131 },
    { numero: 24, descricao: "Wkbk 6 – teste 6", unidade: "Wkbk 6", pagina: 0 },
    { numero: 25, descricao: "Unid 7 – pág 138", unidade: "Unid 7", pagina: 138 },
    { numero: 26, descricao: "Unid 7 – pág 145", unidade: "Unid 7", pagina: 145 },
    { numero: 27, descricao: "Unid 7 – pág 152 (guiar o wkbk p/ próxima aula)", unidade: "Unid 7", pagina: 152 },
    { numero: 28, descricao: "Wkbk 7 – teste 7", unidade: "Wkbk 7", pagina: 0 },
    { numero: 29, descricao: "Unid 8 – pág 158", unidade: "Unid 8", pagina: 158 },
    { numero: 30, descricao: "Unid 8 – pág 164", unidade: "Unid 8", pagina: 164 },
    { numero: 31, descricao: "Unid 8 – pág 169 (guiar o wkbk p/ próxima aula)", unidade: "Unid 8", pagina: 169 },
    { numero: 32, descricao: "Wkbk 8 – teste 8", unidade: "Wkbk 8", pagina: 0 },
    { numero: 33, descricao: "Unid 9 – pág 174", unidade: "Unid 9", pagina: 174 },
    { numero: 34, descricao: "Unid 9 – pág 182", unidade: "Unid 9", pagina: 182 },
    { numero: 35, descricao: "Unid 9 – pág 187 (guiar o wkbk p/ próxima aula)", unidade: "Unid 9", pagina: 187 },
    { numero: 36, descricao: "Wkbk 9 – teste 9", unidade: "Wkbk 9", pagina: 0 },
    { numero: 37, descricao: "Unid 10 – pág 194", unidade: "Unid 10", pagina: 194 },
    { numero: 38, descricao: "Unid 10 – pág 202", unidade: "Unid 10", pagina: 202 },
    { numero: 39, descricao: "Unid 10 – pág 207 (guiar o wkbk p/ próxima aula)", unidade: "Unid 10", pagina: 207 },
    { numero: 40, descricao: "Wkbk 10 – teste 10. Considerações finais", unidade: "Wkbk 10", pagina: 0 }
  ]
};

export { book3 };
