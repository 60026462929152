const book5Semestral = {
  titulo: "Book 5 (Semestral)",
  aulas: [
    { numero: 1, descricao: "Abertura, boas vindas, apresentações. (Apresentação do material e metodologia CIPEX, se necessário. Ex.: alunos vindos de outras escolas, começando no CIPEX direto no livro 5)", unidade: "Unid 1", pagina: 10 },
    { numero: 2, descricao: "Unid 1 – final (guiar os alunos no que deve ser feito no workbook p/ próxima aula)", unidade: "Unid 1", pagina: null },
    { numero: 3, descricao: "Wkbk 1 – teste 1 – Unid 2 – pág 23", unidade: "Wkbk 1", pagina: 0 },
    { numero: 4, descricao: "Unid 2 – pág 31 (guiar o wkbk p/ próxima aula)", unidade: "Unid 2", pagina: 31 },
    { numero: 5, descricao: "Unid 2 – final – Wkbk 2 – teste 2", unidade: "Unid 2", pagina: null },
    { numero: 6, descricao: "Unid 3 – pág 44", unidade: "Unid 3", pagina: 44 },
    { numero: 7, descricao: "Unid 3 – final (guiar o wkbk p/ próxima aula)", unidade: "Unid 3", pagina: null },
    { numero: 8, descricao: "Wkbk 3 – teste 3 – Unid 4 – pág 57", unidade: "Wkbk 3", pagina: 0 },
    { numero: 9, descricao: "Unid 4 – pág 64 (guiar o wkbk p/ próxima aula)", unidade: "Unid 4", pagina: 64 },
    { numero: 10, descricao: "Unid 4 – final – Wkbk 4 – teste 4", unidade: "Unid 4", pagina: null },
    { numero: 11, descricao: "Unid 5 – pág 77", unidade: "Unid 5", pagina: 77 },
    { numero: 12, descricao: "Unid 5 – final (guiar o wkbk p/ próxima aula)", unidade: "Unid 5", pagina: null },
    { numero: 13, descricao: "Wkbk 5 – teste 5 – Unid 6 – pág 89", unidade: "Wkbk 5", pagina: 0 },
    { numero: 14, descricao: "Unid 6 – pág 98 (guiar o wkbk p/ próxima aula)", unidade: "Unid 6", pagina: 98 },
    { numero: 15, descricao: "Unid 6 – final – Wkbk 6 – teste 6", unidade: "Unid 6", pagina: null },
    { numero: 16, descricao: "Unid 7 – pág 112", unidade: "Unid 7", pagina: 112 },
    { numero: 17, descricao: "Unid 7 – final (guiar o wkbk p/ próxima aula)", unidade: "Unid 7", pagina: null },
    { numero: 18, descricao: "Wkbk 7 – teste 7 – Unid 8 – pág 124", unidade: "Wkbk 7", pagina: 0 },
    { numero: 19, descricao: "Unid 8 – pág 131 (guiar o wkbk p/ próxima aula)", unidade: "Unid 8", pagina: 131 },
    { numero: 20, descricao: "Unid 8 – final – Wkbk 8 – teste 8", unidade: "Unid 8", pagina: null },
    { numero: 21, descricao: "Unid 9 – pág 149", unidade: "Unid 9", pagina: 149 },
    { numero: 22, descricao: "Unid 9 – final (guiar o wkbk p/ próxima aula)", unidade: "Unid 9", pagina: null },
    { numero: 23, descricao: "Wkbk 9 – teste 9 – Unid 10 – pág 161", unidade: "Wkbk 9", pagina: 0 },
    { numero: 24, descricao: "Unid 10 – pág 169 (guiar o wkbk p/ próxima aula)", unidade: "Unid 10", pagina: 169 },
    { numero: 25, descricao: "Wkbk 10 – teste 10 – considerações finais", unidade: "Wkbk 10", pagina: 0 }
  ]
};

export { book5Semestral };
