import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import { BsArrowBarRight, BsFileEarmarkText } from "react-icons/bs";
import { MdPreview } from "react-icons/md";
import CadastroTurmaModal from "./CadastroTurmaModal";
import VisualizarTurmaModal from "./VisualizarTurmaModal";
import "./CadastroModal.css";
import { API_BASE_URL } from "./config";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  Pagination,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const Modal = ({ children, closeModal }) => {
  return (
    <div className="modal-background">
      <div className="modal-content">
        <div className="modal-form">{children}</div>
        <button className="close-button" onClick={closeModal}>
          X
        </button>
      </div>
    </div>
  );
};

const CadastroTurma = () => {
  const [turmas, setTurmas] = useState([]);
  const [cursoNames, setCursoNames] = useState({});
  const [userType, setUserType] = useState(0);
  const [userName, setUserName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [showModal, setShowModal] = useState(false);
  const [turmaDataToEdit, setTurmaDataToEdit] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [turmaDataToView, setTurmaDataToView] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [alunosFiltrados, setAlunosFiltrados] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [turmaIdToDelete, setTurmaIdToDelete] = useState(null);



  useEffect(() => {
    setTotalPages(Math.ceil(turmas.length / itemsPerPage));
  }, [turmas, itemsPerPage]);

  const getVisiblePages = () => {
    const pages = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + 4, totalPages);

    if (endPage - startPage < 4) {
      startPage = Math.max(endPage - 4, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const confirmarExclusao = (turmaId) => {
    setTurmaIdToDelete(turmaId);
    setShowDeleteConfirm(true);
  };

  const cancelarExclusao = () => {
    setShowDeleteConfirm(false);
    setTurmaIdToDelete(null);
  };


  useEffect(() => {
    const storedUserType = localStorage.getItem("userType");
    const storedUserName = localStorage.getItem("userName");
    const storedTurmaID = localStorage.getItem("turmaID");
    console.log("Tipo User: [2] ", storedUserType);
    if (storedUserType && storedUserName) {
      setUserType(parseInt(storedUserType));
      setUserName(storedUserName);
      fetchTurmas();
    } else {
      console.log("Deu erro! Chegou aqui");
    }
  }, []);

  useEffect(
    () => {
      fetchTurmas();
      fetchUserTypeAndName();
    },
    [searchTerm, sortDirection, currentPage],
    []
  );

  const fetchCursoNames = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/cursos`);
      const cursos = response.data;
      const cursoNamesMap = cursos.reduce((acc, curso) => {
        acc[curso.cp_curso_id] = curso.cp_nome_curso;
        return acc;
      }, {});
      setCursoNames(cursoNamesMap);
    } catch (error) {
      console.error("Erro ao buscar nomes dos cursos:", error);
    }
  };

  // const openViewModal = (turmaId) => {
  //   const turma = turmas.find((turma) => turma.cp_tr_id === turmaId);
  //   setTurmaDataToView(turma);
  //   setShowViewModal(true);
  // };

  const openViewModal = async (turmaId) => {
    const turma = turmas.find((turma) => turma.cp_tr_id === turmaId);
    setTurmaDataToView(turma);

    try {
      // Buscar o curso da turma
      const responseCurso = await axios.get(
        `${API_BASE_URL}/cursos/${turma.cp_tr_curso_id}`
      );
      const curso = responseCurso.data;
      setTurmaDataToView((prevState) => ({
        ...prevState,
        cursoNome: curso.cp_nome_curso,
      }));

      // Buscar alunos da escola
      const responseAlunos = await axios.get(
        `${API_BASE_URL}/escola/alunos/${turma.cp_tr_id_escola}`
      );
      const alunos = responseAlunos.data;

      // Filtrar alunos pela turma
      const alunosDaTurma = alunos.filter(
        (aluno) => aluno.cp_turma_id === turma.cp_tr_id
      );
      setAlunosFiltrados(alunosDaTurma);
    } catch (error) {
      console.error("Erro ao buscar curso ou alunos:", error);
    }

    setShowViewModal(true);
  };

  const closeViewModal = () => setShowViewModal(false);

  useEffect(() => {
    fetchCursoNames();
  }, []);

  const fetchUserTypeAndName = async () => {
    try {
      const storedUserType = localStorage.getItem("userType");
      const storedUserName = localStorage.getItem("userName");
      if (storedUserType && storedUserName) {
        setUserType(parseInt(storedUserType));
        setUserName(storedUserName);
      }
    } catch (error) {
      console.error("Erro ao buscar userType e userName:", error);
    }
  };

  // Função para filtrar as turmas com base no ID da turma armazenado para o aluno
  const filterTurmasByTurmaID = (turmas) => {
    const storedTurmaID = localStorage.getItem("turmaID");
    if (storedTurmaID) {
      return turmas.filter(
        (turma) => turma.cp_tr_id === parseInt(storedTurmaID)
      );
    } else {
      return [];
    }
  };

  const filterTurmasByProfessorID = (turmas) => {
    const storedUserID = localStorage.getItem("userId");
    if (storedUserID) {
      return turmas.filter(
        (turma) => turma.cp_tr_id_professor === parseInt(storedUserID)
      );
    } else {
      return [];
    }
  };

  const filterTurmasByEscolaID = (turmas) => {
    const storedEscolaID = localStorage.getItem("schoolId");
    console.log("GetItem Schoolid: ", storedEscolaID);
    if (storedEscolaID) {
      return turmas.filter(
        (turma) => turma.cp_tr_id_escola === parseInt(storedEscolaID)
      );
    } else {
      return [];
    }
  };

  const fetchTurmas = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/turmas`);
      let turmasData = response.data;

      const isMobile = window.innerWidth < 767;
      if (isMobile) {
        setItemsPerPage(5);
      } else {
        const maxItemsPerPage = Math.floor(window.innerWidth / 250);
        setItemsPerPage(Math.min(maxItemsPerPage, turmasData.length));
      }

      // if (searchTerm) {
      //   turmasData = turmasData.filter((turma) =>
      //     turma.cp_tr_nome.toLowerCase().includes(searchTerm.toLowerCase())
      //   );
      // }

      if (searchTerm) {
        const normalizedSearchTerm = searchTerm
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();

        turmasData = turmasData.filter((turma) =>
          turma.cp_tr_nome
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(normalizedSearchTerm)
        );
      }

      console.log("Tipo User: ", localStorage.getItem("userType"));
      // Verificar o tipo de usuário e filtrar turmas adequadamente
      if (
        localStorage.getItem("userType") === 5 ||
        localStorage.getItem("userType") === "5"
      ) {
        turmasData = filterTurmasByTurmaID(turmasData);
        setTurmas(turmasData);
      } else if (
        localStorage.getItem("userType") === 4 ||
        localStorage.getItem("userType") === "4"
      ) {
        turmasData = filterTurmasByProfessorID(turmasData);
        setTurmas(turmasData);
      } else if (
        localStorage.getItem("userType") === "3" ||
        localStorage.getItem("userType") === "2"
      ) {
        turmasData = filterTurmasByEscolaID(turmasData);
        setTurmas(turmasData);
      }

      //  else if (userType >= 3) {
      //     setTurmas(turmasData);
      // }

      // Ordenar turmas
      turmasData.sort((a, b) => {
        const nameA = a.cp_tr_nome.toLowerCase();
        const nameB = b.cp_tr_nome.toLowerCase();
        return sortDirection === "asc"
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
      setTurmas(turmasData);
    } catch (error) {
      console.error("Erro ao buscar as turmas:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handleDelete = async (turmaId) => {
    try {
      await axios.delete(`${API_BASE_URL}/delete-turma/${turmaId}`);
      fetchTurmas();
    } catch (error) {
      console.error("Erro ao excluir turma:", error);
    }
  };

  const formatarData = (data) => {
    return new Date(data).toLocaleDateString("pt-BR");
  };

  const openEditModal = (turmaId) => {
    const turma = turmas.find((turma) => turma.cp_tr_id === turmaId);
    setTurmaDataToEdit(turma);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    fetchTurmas();
  };

  const handleAddModal = () => {
    setTurmaDataToEdit(null);
    setShowModal(true);
  };

  // Função para calcular os índices de página
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = turmas.slice(indexOfFirstItem, indexOfLastItem);

  // Função para mudar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container fluid>
      <Row className="my-4 form-container">
        <Col md="12" className="header-content">
          <h2 className="text-center mb-4">
            {userType === 4
              ? "Suas Turmas"
              : userType === 5
                ? "Dados da Turma"
                : "Turmas"}
          </h2>
          <div className="header-controls d-flex flex-column flex-md-row align-items-md-center">
            {userType !== 5 && (
              <Form className="form-inline mr-md-2 mb-2 mb-md-0">
                {" "}
                <Form.Control
                  type="text"
                  placeholder="Pesquisar..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </Form>
            )}

            {userType < 5 && (
              <div className="buttons d-flex flex-column flex-md-row">
                {userType !== 5 && (
                  <Button
                    variant="primary"
                    className="add-button mb-2 mb-md-0 mr-md-2"
                    onClick={handleAddModal}
                  >
                    + Adicionar
                  </Button>
                )}

                <Button
                  variant="secondary"
                  className="sort-button"
                  onClick={handleSortChange}
                >
                  Ordenar por {sortDirection === "asc" ? "A-Z" : "Z-A"}
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {userType === 5 ? (
        <div className="card-container">
          {turmas.map((turma) => (
            <Row key={turma.cp_tr_id} className="mb-3">
              <Col>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>
                      <strong>Minha Turma</strong>
                    </Card.Title>
                    <Card.Text>{turma.cp_tr_nome}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>
                      <strong>Data de Início</strong>
                    </Card.Title>
                    <Card.Text> {formatarData(turma.cp_tr_data)}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>
                      <strong>Professor</strong>
                    </Card.Title>
                    <Card.Text>{turma.nomeDoProfessor}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>
                      <strong>Minha Escola</strong>
                    </Card.Title>
                    <Card.Text>{turma.nomeDaEscola}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>
                      <strong>Meu Curso</strong>
                    </Card.Title>
                    <Card.Text>
                      {cursoNames[turma.cp_tr_curso_id] || "Carregando..."}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
        </div>
      ) : (
        <>
          <div className="user-table-container">
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Data</th>

                  <th>Professor</th>
                  <th>Escola</th>
                  {userType <= 3 && (
                    <>
                      <th className="excluir-td">Excluir</th>
                      <th className="editar-td">Editar Turma</th>
                    </>
                  )}
                  <th className="excluir-td">Ver Turma</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(currentItems) &&
                  currentItems.map((turma) => (
                    <tr key={turma.cp_tr_id}>
                      <td>{turma.cp_tr_nome}</td>
                      <td>{formatarData(turma.cp_tr_data)}</td>
                      <td>{turma.nomeDoProfessor}</td>
                      <td>{turma.nomeDaEscola}</td>
                      {userType <= 3 && (
                        <>
                          <td className="excluir-td">
                            {/* <Button
                              variant="danger"
                              onClick={() => handleDelete(turma.cp_tr_id)}
                            >
                              <FaTrashAlt />
                            </Button> */}

                            <Button
                              variant="danger"
                              onClick={() => confirmarExclusao(turma.cp_tr_id)}
                            >
                              <FaTrashAlt />
                            </Button>

                          </td>
                          <td className="edit-td">
                            <Button
                              variant="warning"
                              disabled
                              onClick={() => openEditModal(turma.cp_tr_id)}
                            >
                              <FaEdit />
                            </Button>
                          </td>
                        </>
                      )}
                      <td>
                        <Button
                          variant="info"
                          onClick={() => openViewModal(turma.cp_tr_id)}
                        >
                          <MdPreview />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div className="pagination-container">
            <Row className="justify-content-center align-items-center">
              <Col className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.First onClick={() => paginate(1)} />
                  <Pagination.Prev
                    onClick={() => paginate(Math.max(1, currentPage - 1))}
                  />
                  {[
                    ...Array(
                      Math.min(Math.ceil(turmas.length / itemsPerPage), 5)
                    ).keys(),
                  ].map((_, i) => (
                    <Pagination.Item
                      key={i + 1}
                      active={currentPage === i + 1}
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  {Math.ceil(turmas.length / itemsPerPage) > 5 && (
                    <Pagination.Ellipsis disabled />
                  )}
                  <Pagination.Next
                    onClick={() =>
                      paginate(
                        Math.min(
                          currentPage + 1,
                          Math.ceil(turmas.length / itemsPerPage)
                        )
                      )
                    }
                  />
                  <Pagination.Last
                    onClick={() =>
                      paginate(Math.ceil(turmas.length / itemsPerPage))
                    }
                  />
                </Pagination>
              </Col>
              <Col xs="auto" style={{ marginTop: "-20px" }}>
                <Form.Control
                  as="select"
                  value={currentPage}
                  onChange={(e) => paginate(parseInt(e.target.value))}
                  className="mt-2"
                  style={{ width: "70px" }}
                >
                  {Array.from(
                    { length: Math.ceil(turmas.length / itemsPerPage) },
                    (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    )
                  )}
                </Form.Control>
              </Col>
            </Row>
          </div>
        </>
      )}

      {showViewModal && (
        <VisualizarTurmaModal
          turmaData={turmaDataToView}
          closeModal={closeViewModal}
          alunosFiltrados={alunosFiltrados}
        />
      )}

      {showDeleteConfirm && (
        <div
          style={{
            position: 'fixed',   
            top: '0',            
            left: '0',           
            width: '100vw',      
            height: '100vh',     
            backgroundColor: 'rgba(0, 0, 0, 0.5)',  
            display: 'flex',     
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1050',      
          }}
        >
          <div
            style={{
              backgroundColor: 'white',  
              padding: '20px',           
              borderRadius: '8px',       
              width: '400px',            
              maxHeight: '200px',        
              overflowY: 'auto',         
            }}
          >
            <h4>Tem certeza que deseja excluir esta turma?</h4>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="danger"
                // className="mr-2"
                className="me-2"
                onClick={() => {
                  handleDelete(turmaIdToDelete);
                  cancelarExclusao();
                }}
              >
                Confirmar
              </Button>
              <Button variant="secondary" onClick={cancelarExclusao}>
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      )}


      {showModal && (
        <Modal closeModal={closeModal}>
          <CadastroTurmaModal
            closeModal={closeModal}
            isEdit={!!turmaDataToEdit}
            turmaDataToEdit={turmaDataToEdit}
          />
        </Modal>
      )}
    </Container>
  );
};

export default CadastroTurma;
