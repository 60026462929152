import React, { useState, useEffect } from "react";
import "./CadastroModal.css"; // Importe o arquivo CSS
import InputMask from "react-input-mask";
import { API_BASE_URL } from "./config";

const CadastroUserEditar = ({ userData, closeModal, escolas }) => {
  const [editedUser, setEditedUser] = useState(userData || {});
  const isEditing = !!userData;
  const [isEmpresa, setIsEmpresa] = useState(false);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const userTypeFromStorage = localStorage.getItem("userType");
    setUserType(parseInt(userTypeFromStorage, 10));
  }, []);

  const getFilteredOptions = () => {
    const options = [
      { value: "1", label: "Gestor" },
      { value: "2", label: "Diretor" },
      { value: "3", label: "Secretária" },
      { value: "4", label: "Professor" },
      { value: "5", label: "Aluno" },
    ];

    return options.filter((option) => parseInt(option.value, 10) >= userType);
  };

  useEffect(() => {
    if (!userData) {
      setEditedUser({});
    }
  }, [userData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedUser({ ...editedUser, [name]: value });
  };

  const handleUserAction = () => {
    const url = isEditing
      ? `${API_BASE_URL}/edit-user/${editedUser.cp_id}`
      : `${API_BASE_URL}/register`;
    const method = isEditing ? "PUT" : "POST";

    fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedUser),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        closeModal();
      })
      .catch((error) => {
        console.error(
          `Erro ao ${isEditing ? "editar" : "adicionar"} usuário:`,
          error
        );
      });
  };

  useEffect(() => {
    if (userData && userData.cp_datanascimento) {
      setEditedUser({
        ...editedUser,
        cp_datanascimento: userData.cp_datanascimento.split("T")[0],
      });
    }
  }, [userData]);

  return (
    <div className="edit-user-form form-container-cad">
      <h2>{isEditing ? "Editar Usuário" : "Adicionar Usuário"}</h2>
      <form className="form-container-cad">
        <div className="form-group">
          <label htmlFor="cp_nome">
            Nome<span className="required">*</span>:
          </label>
          <input
            type="text"
            id="cp_nome"
            name="cp_nome"
            value={editedUser.cp_nome}
            onChange={handleInputChange}
            className="input-field"
            placeholder="Nome"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="cp_email">
            E-mail<span className="required">*</span>:
          </label>
          <input
            type="email"
            id="cp_email"
            name="cp_email"
            value={editedUser.cp_email}
            onChange={handleInputChange}
            className="input-field"
            placeholder="E-mail"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="cp_login">
            Login<span className="required">*</span>:
          </label>
          <input
            type="text"
            id="cp_login"
            name="cp_login"
            value={editedUser.cp_login}
            onChange={handleInputChange}
            className="input-field"
            placeholder="Login"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="cp_password">
            Senha<span className="required">*</span>:
          </label>
          <input
            type="password"
            id="cp_password"
            name="cp_password"
            value={editedUser.cp_password}
            onChange={handleInputChange}
            className="input-field"
            placeholder="Senha"
            required
          />
        </div>

        {/* <div className="form-group">
                    <label htmlFor="cp_tipo_user">Tipo de Usuário<span className="required">*</span>:</label>
                    <select
                        id="cp_tipo_user"
                        name="cp_tipo_user"
                        value={editedUser.cp_tipo_user}
                        onChange={handleInputChange}
                        className="input-field"
                        required
                    >
                        <option value="">Selecione o tipo de usuário</option>
                        <option value="1">Gestor</option>
                        <option value="2">Diretor</option>
                        <option value="3">Secretária</option>
                        <option value="4">Professor</option>
                        <option value="5">Aluno</option>
                    </select>
                </div> */}

        <div className="form-group">
          <label htmlFor="cp_tipo_user">
            Tipo de Usuário<span className="required">*</span>:
          </label>
          <select
            id="cp_tipo_user"
            name="cp_tipo_user"
            value={editedUser.cp_tipo_user}
            onChange={handleInputChange}
            className="input-field"
            required
          >
            <option value="">Selecione o tipo de usuário</option>
            {getFilteredOptions().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="cp_rg">
            RG<span className="required">*</span>:
          </label>
          <input
            type="text"
            id="cp_rg"
            name="cp_rg"
            value={editedUser.cp_rg}
            onChange={handleInputChange}
            className="input-field"
            placeholder="RG"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="cp_cpf">
            CPF<span className="required">*</span>:
          </label>
          <InputMask
            type="text"
            id="cp_cpf"
            name="cp_cpf"
            mask="999.999.999-99"
            value={editedUser.cp_cpf}
            onChange={handleInputChange}
            className="input-field"
            placeholder="CPF"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="cp_datanascimento">
            Data de Nascimento<span className="required">*</span>:
          </label>
          <input
            type="date"
            id="cp_datanascimento"
            name="cp_datanascimento"
            value={editedUser.cp_datanascimento}
            onChange={handleInputChange}
            className="input-field"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="cp_whatsapp">WhatsApp:</label>
          <InputMask
            type="text"
            id="cp_whatsapp"
            name="cp_whatsapp"
            // as={InputMask}
            mask="(99)99999-9999"
            value={editedUser.cp_whatsapp}
            onChange={handleInputChange}
            className="input-field"
            placeholder="WhatsApp"
          />
        </div>

        <div className="form-group">
          <label htmlFor="cp_telefone">Telefone:</label>
          <InputMask
            type="text"
            id="cp_telefone"
            name="cp_telefone"
            mask="(99)99999-9999"
            value={editedUser.cp_telefone}
            onChange={handleInputChange}
            className="input-field"
            placeholder="Telefone"
          />
        </div>
        <div className="form-group">
          <label htmlFor="cp_estadocivil">Estado Civil:</label>
          <input
            type="text"
            id="cp_estadocivil"
            name="cp_estadocivil"
            value={editedUser.cp_estadocivil}
            onChange={handleInputChange}
            className="input-field"
            placeholder="Estado Civil"
          />
        </div>

        <div className="form-group-2">
          <label>
            <input
              type="checkbox"
              checked={isEmpresa}
              onChange={(e) => setIsEmpresa(e.target.checked)}
              className="input-checkbox styled-checkbox"
            />
            <span className="span-check">Cadastro de Empresa</span>
          </label>
        </div>
        {isEmpresa && (
          <>
            <div className="form-group">
              <label htmlFor="cp_cnpj">CNPJ:</label>
              <InputMask
                type="text"
                id="cp_cnpj"
                name="cp_cnpj"
                mask="99.999.999/9999-99"
                value={editedUser.cp_cnpj}
                onChange={handleInputChange}
                className="input-field"
                placeholder="CNPJ"
              />
            </div>

            <div className="form-group">
              <label htmlFor="cp_ie">IE:</label>
              <input
                type="text"
                id="cp_ie"
                name="cp_ie"
                value={editedUser.cp_ie}
                onChange={handleInputChange}
                className="input-field"
                placeholder="IE"
              />
            </div>

            <div className="form-group">
              <label htmlFor="cp_empresaatuacao">Empresa de Atuação:</label>
              <input
                type="text"
                id="cp_empresaatuacao"
                name="cp_empresaatuacao"
                value={editedUser.cp_empresaatuacao}
                onChange={handleInputChange}
                className="input-field"
                placeholder="Empresa de Atuação"
              />
            </div>

            <div className="form-group">
              <label htmlFor="cp_profissao">Profissão:</label>
              <input
                type="text"
                id="cp_profissao"
                name="cp_profissao"
                value={editedUser.cp_profissao}
                onChange={handleInputChange}
                className="input-field"
                placeholder="Profissão"
              />
            </div>
          </>
        )}

        <div className="form-group">
          <label htmlFor="cp_end_cidade_estado">Cidade e Estado:</label>
          <input
            type="text"
            id="cp_end_cidade_estado"
            name="cp_end_cidade_estado"
            value={editedUser.cp_end_cidade_estado}
            onChange={handleInputChange}
            className="input-field"
            placeholder="Cidade e Estado"
          />
        </div>

        <div className="form-group">
          <label htmlFor="cp_end_rua">Rua:</label>
          <input
            type="text"
            id="cp_end_rua"
            name="cp_end_rua"
            value={editedUser.cp_end_rua}
            onChange={handleInputChange}
            className="input-field"
            placeholder="Rua"
          />
        </div>

        <div className="form-group">
          <label htmlFor="cp_end_num">Número:</label>
          <input
            type="text"
            id="cp_end_num"
            name="cp_end_num"
            value={editedUser.cp_end_num}
            onChange={handleInputChange}
            className="input-field"
            placeholder="Número"
          />
        </div>

        <div className="form-group">
          <label htmlFor="cp_end_cep">CEP:</label>
          <input
            type="text"
            id="cp_end_cep"
            name="cp_end_cep"
            value={editedUser.cp_end_cep}
            onChange={handleInputChange}
            className="input-field"
            placeholder="CEP"
          />
        </div>

        <div className="form-group">
          <label htmlFor="cp_descricao">Descrição:</label>
          <input
            type="text"
            id="cp_descricao"
            name="cp_descricao"
            value={editedUser.cp_descricao}
            onChange={handleInputChange}
            className="input-field"
            placeholder="Descrição"
          />
        </div>
        {/* <div className="form-group">
                    <label htmlFor="cp_foto_perfil">Foto de Perfil:</label>
                    <input
                        type="file"
                        id="cp_foto_perfil"
                        name="cp_foto_perfil"
                        onChange={handleInputChange}
                        className="input-field"
                        accept="image/*" // Permite somente arquivos de imagem
                    />
                </div> */}
        <div className="form-group">
          <label htmlFor="cp_escola_id">Escola:</label>
          <select
            id="cp_escola_id"
            name="cp_escola_id"
            value={editedUser.cp_escola_id}
            onChange={handleInputChange}
            className="input-field"
          >
            <option value="">Selecione a escola</option>
            {escolas.length > 0 ? (
              escolas.map((escola) => (
                <option key={escola.cp_ec_id} value={escola.cp_ec_id}>
                  {escola.cp_ec_nome}
                </option>
              ))
            ) : (
              <option value="" disabled>
                Carregando escolas...
              </option>
            )}
          </select>
        </div>
        <div className="form-group-2">
          <button
            type="button"
            className="submit-button"
            onClick={handleUserAction}
          >
            {isEditing ? "Salvar Alterações" : "Adicionar Usuário"}
          </button>
          <button type="button" className="submit-button" onClick={closeModal}>
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};

export default CadastroUserEditar;
