import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Index from './components/Index';
import { API_BASE_URL } from './components/pages/config'; 

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/index/*" element={<Index />} />
        {/* Outras rotas */}
      </Routes>
    </Router>
  );
};

export default App;