import React, { useState, useEffect } from 'react';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import './CadastroUsuarios.css';
import CadastroEscolaModal from './CadastroEscolaModal';
import { API_BASE_URL } from './config';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Spinner,
  Pagination,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const Modal = ({ children, closeModal }) => {
  return (
    <div className="modal-background">
      <div className="modal-content">
        <div className='modal-form'>
          {children}
        </div>
        <button className="close-button" onClick={closeModal}>X</button>
      </div>
    </div>
  );
};

const CadastroEscola = () => {
  const [escolas, setEscolas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [escolaDataToEdit, setEscolaDataToEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [escolasPerPage] = useState(6);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    fetchEscolas();
  }, []);

  const fetchEscolas = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/escolas`);
      const data = await response.json();
      setEscolas(data);
    } catch (error) {
      console.error('Erro ao buscar as escolas:', error);
    }
  };

  const handleDelete = async (escolaId) => {
    try {
      await fetch(`${API_BASE_URL}/delete-escola/${escolaId}`, {
        method: 'DELETE'
      });
      fetchEscolas(); // Atualiza a lista de escolas após a exclusão
    } catch (error) {
      console.error('Erro ao excluir escola:', error);
    }
  };

  const openEditModal = (escolaId) => {
    const escola = escolas.find((escola) => escola.cp_ec_id === escolaId);
    setEscolaDataToEdit(escola);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    fetchEscolas(); // Atualiza a lista de escolas após fechar o modal
  };

  const handleAddModal = () => {
    setEscolaDataToEdit(null); // Limpar os dados de edição antes de abrir para adicionar
    setShowModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
    const sortedEscolas = [...escolas].sort((a, b) => {
      const nomeA = a.cp_ec_nome.toLowerCase();
      const nomeB = b.cp_ec_nome.toLowerCase();
      if (newDirection === 'asc') {
        return nomeA.localeCompare(nomeB);
      } else {
        return nomeB.localeCompare(nomeA);
      }
    });
    setEscolas(sortedEscolas);
  };

  const indexOfLastEscola = currentPage * escolasPerPage;
  const indexOfFirstEscola = indexOfLastEscola - escolasPerPage;
  const filteredEscolas = escolas.filter(escola =>
    escola.cp_ec_nome.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentEscolas = filteredEscolas.slice(indexOfFirstEscola, indexOfLastEscola);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <Container fluid>
      <Row className="justify-content-center my-4">
        <Col md={12} className="header-content">
          <h1 className="user-title">Escola</h1>
          <div className="header-controls">
            <Form className="mr-2">
              <Form.Control type="text" placeholder="Pesquisar..." value={searchTerm} onChange={handleSearchChange} />
            </Form>
            <div className="buttons">
              <Button variant="primary" className="add-button" onClick={handleAddModal}>+ Adicionar</Button>
              <Button variant="secondary" className="sort-button" onClick={handleSortChange}>
                Ordenar por {sortDirection === 'asc' ? 'A-Z' : 'Z-A'}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <div className="user-table-container">
        <Table responsive striped hover bordered>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Responsável</th>
              <th>Data de Cadastro</th>
              <th>Cidade</th>
              <th>Excluir</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody >
            {currentEscolas.map((escola, index) => (
              <tr style={{ backgroundColor: "#fff" }} key={index}>
                <td>{escola.cp_ec_nome}</td>
                <td>{escola.cp_ec_responsavel}</td>
                <td>{new Date(escola.cp_ec_data_cadastro).toLocaleDateString('pt-BR')}</td>
                <td>{escola.cp_ec_endereco_cidade}</td>
                <td>
                  <Button variant="danger" onClick={() => handleDelete(escola.cp_ec_id)}>
                    <FaTrashAlt />
                  </Button>
                </td>
                <td>
                  <Button variant="warning" onClick={() => openEditModal(escola.cp_ec_id)}>
                    <FaEdit />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="pagination-container">
        <Row className="justify-content-center align-items-center">

          <Col className="d-flex justify-content-center">
            <Pagination>
              {Array.from({ length: Math.ceil(filteredEscolas.length / escolasPerPage) }, (_, i) => (
                <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
                  {i + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </Col>
        </Row>

      </div>

      {
        showModal && (
          <Modal closeModal={closeModal}>
            <CadastroEscolaModal
              closeModal={closeModal}
              isEdit={!!escolaDataToEdit}
              escolaDataToEdit={escolaDataToEdit}
            />
          </Modal>
        )
      }
    </Container >
  );
};

export default CadastroEscola;